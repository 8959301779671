import React, { useEffect, useState, useRef } from "react";
import DtxUser from "./DtxUser";
import "./css/Home.css";
import IconCalendar from "@material-ui/icons/Event";

import UserList from "./UserList";
import Prescription from "./Prescription";
import PrescriptionList from "./PrescriptionList";
import ExerciseLog from "./ExerciseLog";
import springapi from "../util/springapi";
import Notifications from "./Notifications";

const DtxHome = (props) => {
  const [info, setInfo] = useState({
    id: null,
    phoneNumber: null,
    isAdmin: false
  });
  const [patient, setPatient] = useState({
    id: null,
    bodyGrade: null,
    surgicalSite: {
      ankle: false,
      knee: false,
      waist: false,
      shoulder: false,
      wrist: false
    }
  });
  const [prescriptionListUpdate, setPrescriptionListUpdate] = useState(false);
  const [exerciseList, setExerciseList] = useState([]);

  // useInterval(() => {
  //   setTime(time + 1000);
  // }, 1000)

  useEffect(() => {
    DtxUser.getInfo().then((info) => {
      if(info === null) {
        window.location = "/";
        return;
      }
      setInfo(info);
    });
    springapi.request("GET", "/dtx/get/exercise").then((res) => {
      if(res.response) {
        setExerciseList(res.response);
      }
    });
  }, []);
  
  async function signOut(event) {
    event.preventDefault();
    await DtxUser.signOut();
    window.location = "/";
  }
  
  function handleUserChange(patient) {
    setPatient(patient);
  };

  if(info.id === null) {
    return null;
  }

  const Time = () => {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
      const clock = setInterval(() => {
        setTime(new Date());
      }, 50);
      return () => clearInterval(clock);
    }, []);
    return (
      <div style={{ position: "absolute", left: 280, display: "flex", alignItems: "center", margin: 10 }}>
        <IconCalendar style={{ marginRight: 5 }}/> <h4>{time.toLocaleString()}</h4>
      </div>
    );
  }

  async function deleteAllPatients() {
    const confirmDelete = window.confirm("모든 환자를 삭제하시겠습니까?");
    if(confirmDelete) {
      await springapi.request("delete", "/dtx/web/test/deleteAllPatients");
      alert("삭제 완료했습니다");
      window.location.reload();
    }
  }

  return (
    <div className="page">
      {info.id === 4 && <div style={{ position: "absolute", top: 0, right: 0, width: 30, height: 30, cursor: "pointer", zIndex: 1000 }} onClick={deleteAllPatients}/>}
      <div className="sidebar">
        <div className="sidebar-top">
          <span style={{ color: "white" }}>{info.phoneNumber}</span>
          <a href="" onClick={signOut} style={{ color: "white", float: "right" }}>
            로그아웃
          </a>
          <a href="/" style={{ color: "white", float: "right", marginRight: 10 }}>
            홈
          </a>
        </div>
        <hr className="sidebar-line"/>
        <UserList doctorId={info.id} onChangeUser={handleUserChange}/>
      </div>
      <Time/>
      {patient.id === null &&
        <>
          <div style={{ display: "flex", position: "absolute",  height: "100%", left: 280, right: 0, backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 50, alignItems: "center", justifyContent: "center" }}>
            <h2>환자를 선택해주세요</h2>
          </div>
        </>
      }
      <div className="dtx-content">
        <div style={{ display: "flex", height: "100%", minWidth: 1440, gap: 10 }}>
          <div style={{ display: "flex", flexDirection: "column", flex: "1 0 0", gap: 10 }}>
            <Notifications patient={patient}/>
            <Prescription doctorId={info.id} patientId={patient.id} bodyGrade={patient.bodyGrade} setUpdate={setPrescriptionListUpdate}/>
            <PrescriptionList doctorId={info.id} patientId={patient.id} update={prescriptionListUpdate} setUpdate={setPrescriptionListUpdate} exerciseList={exerciseList}/>
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: "1 0 0" }}>
            <ExerciseLog patientId={patient.id} exerciseList={exerciseList}/>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DtxHome;