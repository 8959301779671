import React, { useEffect, useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import util from "../util/util";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import mobiscroll from "@mobiscroll/react-lite";
import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";
import springapi from "../util/springapi";
import { useSettings } from "./context/settings";
import translation from "../language/translation";

mobiscroll.settings = {
  theme: "ios"
}

const UserList = (props) => {
  const [list, setList] = useState([]);
  const {language} = useSettings();
  const [sidebar, setSidebar] = useState({})
  const [user, setUser] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const fetchList = async () => {
      let data = [];
      const res = await springapi.request("GET", "/v2/user/getUserList");
      if(res) {
        data = res.body.userList;
      }

      setList(data);
    }
    
    fetchList()
      .catch(console.error);
  }, []);

  useEffect(() => {
    setSidebar(translation.translate("sidebar", language));
  }, [language])

  function changeUser(id) {
    setUser(id);
    props.onChangeUser && props.onChangeUser(id);
  };

  function clearFilter() {
    setFilter("");
  }

  return (
    <>
      <InputGroup style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
        <Input
          value={filter}
          placeholder={sidebar.search}
          onChange={(e) => setFilter(e.target.value)}
          // disabled={disableInput}
          style={{ borderColor: "white" }}
        />
        <InputGroupAddon addonType="append">
          <Button
            // color="black"
            style={{ border: "1px solid", backgroundColor: "black", height: "auto" }}
            onClick={clearFilter}
            disabled={!filter}
          >
            <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <ListGroup style={{ flexGrow: 1, overflow: "auto", margin: 10, backgroundColor: "white", borderRadius: 5, padding: 0 }}>
        {list.filter((item) => item.phone.includes(filter)).map((item) => (
          <ListGroupItem
            key={item.id}
            active={user === item.id}
            tag="button"
            data-key={item.id}
            onClick={() => changeUser(item.id)}
            action
            style={{ borderRadius: 0 }}
          >
            <Row>
              <Col xs="6">{item.phone}</Col>
              <Col xs="6">{util.convDt2SimpleDate(item.regDate)}</Col>
            </Row>
            <Row>
              <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
              <Col xs="6">{item.gender.toUpperCase()}</Col>
              {/* <Col xs="4">{item.weight && item.weight + "kg"}</Col> */}
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
}

export default UserList;

// export default class V2UserList extends React.Component {
//   state = {
//     id: null,
//     loading: false,
//     data: [],
//     group_type: [],
//     group_set: [],
//     filter: "",
//     group_filter: "",
//     parent_filter: "",
//     view: "search"
//   };

//   initFlag = false;

//   fetch = () => {
//     this.setState({ loading: true }, async () => {
//       let data, group_type, group_set;

//       try {
//         // const { user_id } = this.props.admin ? {} : await auth.getUserInfo();
//         const res = await springapi.request("GET", "/v2/user/getUserList");
//         if(res) {
//           data = res.body.userList;
//           console.log(data);
//         }
//       } catch (error) {
//         console.error(error);
//       }

//       this.setState({ loading: false, data });
//     });
//   };

//   handleClickUser = event => {
//     const user_id = event.currentTarget.dataset.key;

//     this.setState({ user_id: Number(user_id) }, () => {
//       this.props.onChangeUser && this.props.onChangeUser(this.state.user_id);
//     });
//   };

//   handleChangeFilter = event => {
//     this.setState({ filter: event.target.value });
//   };

//   handleClear = () => {
//     this.setState({ filter: "" });
//   };

//   componentDidMount = () => {
//     this.fetch();
//   };

//   changeView = (event) => {
//     this.setState({ view: event.currentTarget.value })
//   }

//   changeGroupFilter = (event) => {
//     this.setState({ group_filter: event.currentTarget.dataset.key })
//   }

//   clearGroupFilter = () => {
//     this.setState({ group_filter: "" })
//   }

//   changeParentFilter = (event) => {
//     this.setState({ parent_filter: event.currentTarget.dataset.key })
//   }

//   clearParentFilter = () => {
//     this.setState({ parent_filter: "" })
//   }

//   render = () => {
//     const { filter, group_filter, parent_filter, data, group_type, group_set, view } = this.state;
//     const search = 
//       data &&
//       data
//         .filter(
//           item =>
//             (item.phone && item.phone.includes(filter))
//             // (item.family_name && item.family_name.includes(filter)) ||
//             // (item.given_name && item.given_name.includes(filter)) ||
//             // (item.middle_name && item.middle_name.includes(filter))
//         )
//         .map(item => (
//           <ListGroupItem
//             key={item.id}
//             active={this.state.id === item.id}
//             tag="button"
//             data-key={item.id}
//             onClick={this.handleClickUser}
//             action
//           >
//             <Container>
//               <Row>
//                 <Col xs="6">{item.phone}</Col>
//                 <Col xs="6">{util.convDt2SimpleDate(item.regDate)}</Col>
//               </Row>
//               <Row>
//                 <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
//                 <Col xs="2">{item.gender.toUpperCase()}</Col>
//                 <Col xs="4">{item.weight && item.weight + "kg"}</Col>
//               </Row>
//             </Container>
//           </ListGroupItem>
//         ));

//     var content;
//     switch(view) {
//       case("search"):
//         content = search;
//         break;
//       default:
//         break;
//     }

//     const disableInput = !data || data.length === 0;

//     return (
//       <div style={{ marginLeft: 5, marginTop: 5 }}>
//         {/* { this.props.admin &&
//           <div>
//             <mobiscroll.Segmented name="view" value="search" checked={this.state.view === 'search'} onChange={this.changeView} color="primary">
//                 Search
//             </mobiscroll.Segmented>
//             <mobiscroll.Segmented name="view" value="group" checked={this.state.view === 'group'} onChange={this.changeView} color="primary">
//                 Group
//             </mobiscroll.Segmented>
//             <mobiscroll.Segmented name="view" value="parent" checked={this.state.view === 'parent'} onChange={this.changeView} color="primary">
//                 Parent
//             </mobiscroll.Segmented>
//             <div style={{ height: 18 }}></div>
//           </div>
//         } */}
        
//         { this.state.view === "search" && <CardTitle>
//           <InputGroup>
//             <Input
//               value={this.state.filter}
//               placeholder="Search"
//               onChange={this.handleChangeFilter}
//               disabled={disableInput}
//             />
//             <InputGroupAddon addonType="append">
//               <Button
//                 color="black"
//                 onClick={this.handleClear}
//                 disabled={!this.state.filter}
//               >
//                 <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
//               </Button>
//             </InputGroupAddon>
//           </InputGroup>
//         </CardTitle>}
//         <CardTitle className="userlist">
//           <ListGroup>{content}</ListGroup>
//         </CardTitle>
//         </div>
//     );
//   };
// }