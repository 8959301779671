import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Button, Input, Table } from "reactstrap";
import TopSpacer from "./TopSpacer";
import MidCard from "./MidCard";

import config from "../config.json";

export default class LicenseMan extends Component {
  state = {
    fetching: false,
    data: [],
    models: [],
    types: [],
    model: "",
    type: "",
    userValid: false
  };

  getModel = async () => {
    const req = new Request(`${config.baseUrl}/${config.stage}/license/model`, {
      method: "GET"
    });

    try {
      const response = await fetch(req);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.result) {
          this.setState({
            models: data.detail,
            model: data.detail[0].id.toString()
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getType = async () => {
    const req = new Request(`${config.baseUrl}/${config.stage}/license/type`, {
      method: "GET"
    });

    try {
      const response = await fetch(req);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.result) {
          this.setState({
            types: data.detail,
            type: data.detail[0].id.toString()
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getLicList = async () => {
    const { idToken } = await this.getToken();
    const reqHeader = new Headers({
      "Content-Type": "application/json",
      exoAuth: idToken
    });
    const req = new Request(`${config.baseUrl}/${config.stage}/license/all`, {
      method: "GET",
      headers: reqHeader
    });

    try {
      const response = await fetch(req);
      if (response.ok) {
        const data = await response.json();
        if (data.result) {
          console.log(data);
          this.setState({ fetching: false, data: data.detail });
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({ fetching: false, data: [] });
    }
  };

  getToken = async () => {
    const user = await Auth.currentSession();
    const idToken = user.getIdToken().getJwtToken();
    const accessToken = user.getAccessToken().getJwtToken();

    return { idToken, accessToken };
  };

  createLicense = async () => {
    const { idToken } = await this.getToken();
    const reqHeader = new Headers({
      "Content-Type": "application/json",
      exoAuth: idToken
    });
    const { model, type } = this.state;
    const req = new Request(`${config.baseUrl}/${config.stage}/license`, {
      method: "POST",
      headers: reqHeader,
      body: JSON.stringify({
        model_id: Number(model),
        type_id: Number(type)
      })
    });

    try {
      const response = await fetch(req);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        this.getLicList();
        if (data.result) {
        }
      }
    } catch (ex) {}
  };

  produceLicense = async licenseId => {
    const { idToken } = await this.getToken();
    const reqHeader = new Headers({
      "Content-Type": "application/json",
      exoAuth: idToken
    });
    const req = new Request(
      `${config.baseUrl}/${config.stage}/license/${licenseId}`,
      { method: "PUT", headers: reqHeader }
    );

    try {
      const response = await fetch(req);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.result) {
        }
      }
    } catch (ex) {}
  };

  terminateLicense = async licenseId => {
    const { idToken } = await this.getToken();
    const reqHeader = new Headers({
      "Content-Type": "application/json",
      exoAuth: idToken
    });
    const req = new Request(
      `${config.baseUrl}/${config.stage}/license/${licenseId}`,
      { method: "DELETE", headers: reqHeader }
    );

    try {
      const response = await fetch(req);
      if (response.ok) {
        const data = await response.json();
        if (data.result) {
        }
      }
    } catch (ex) {}
  };

  handleProduct = async event => {
    console.log(event.target.dataset);
    await this.produceLicense(event.target.dataset.id);
    this.getLicList();
  };

  handleTerminate = async event => {
    console.log(event.target.dataset);
    await this.terminateLicense(event.target.dataset.id);
    this.getLicList();
  };

  handleChange = event => {
    console.log(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  async componentDidMount() {
    const user = (await Auth.currentSession()).idToken.payload;
    const userValid =
      user["cognito:groups"] != null &&
      user["cognito:groups"].includes("exo-admin");
    console.log(userValid);

    this.setState({ userValid: userValid });
    if (userValid) {
      this.getModel();
      this.getType();
      this.getLicList();
    }
  }

  render() {
    const { fetching, data, models, types, userValid } = this.state;

    if (!userValid) return "invalid user";

    let content = (
      <tr>
        <td colSpan="6">loading...</td>
      </tr>
    );

    if (!fetching) {
      content = data.map(item => {
        return (
          <tr key={item.id}>
            <th scope="row">{item.id}</th>
            <td>{item.key}</td>
            <td>{item.email}</td>
            <td>{item.model}</td>
            <td>{item.type}</td>
            <td>{item.state}</td>
            <td>
              {item.state === "create" && (
                <Button
                  data-key={item.key}
                  data-id={item.id}
                  onClick={this.handleProduct}
                >
                  생산
                </Button>
              )}
              {item.state !== "terminate" && (
                <Button
                  data-key={item.key}
                  data-id={item.id}
                  onClick={this.handleTerminate}
                >
                  삭제
                </Button>
              )}
            </td>
          </tr>
        );
      });
    }

    const opModel = models.map(item => (
      <option
        key={item.id}
        value={item.id}
      >{`${item.value}(${item.key})`}</option>
    ));
    const opType = types.map(item => (
      <option
        key={item.id}
        value={item.id}
      >{`${item.value}(${item.key})`}</option>
    ));

    return (
      <MidCard>
        <TopSpacer />
        <Button onClick={this.createLicense}>생성</Button>
        <Input
          type="select"
          name="model"
          value={this.state.model}
          onChange={this.handleChange}
        >
          {opModel}
        </Input>
        <Input
          type="select"
          name="type"
          value={this.state.type}
          onChange={this.handleChange}
        >
          {opType}
        </Input>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>key</th>
              <th>user</th>
              <th>model</th>
              <th>type</th>
              <th>state</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
      </MidCard>
    );
  }
}
