import React from "react";
import MidCard from "./MidCard";
import {
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  CardTitle,
  Input,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import restapi from "../util/restapi";
import util from "../util/util";
import auth from "../util/auth";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import mobiscroll from "@mobiscroll/react-lite";
import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";

const MASTER_GROUP_ID = 9998;

mobiscroll.settings = {
  theme: "ios"
}

export default class Userlist3 extends React.Component {
  state = {
    loading: false,
    data: [],
    group_type: [],
    group_set: [],
    filter: "",
    group_filter: "",
    parent_filter: "",
    view: "search"
  };

  initFlag = false;

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let data, group_type, group_set;

      try {
        const { user_id } = this.props.admin ? {} : await auth.getUserInfo();
        const res = await restapi.getUserList(user_id);
        const group_type_res  = this.props.admin ? await restapi.getGroupType() : {};
        const group_set_res  = this.props.admin ? await restapi.getGroupSet() : {};
        res && res.result && (data = res.detail);
        group_type_res && group_type_res.result && (group_type = group_type_res.detail);
        group_set_res && group_set_res.result && (group_set = group_set_res.detail);
      } catch (error) {
        console.error(error);
      }

      this.setState({ loading: false, data, group_type, group_set });
    });
  };

  handleClickUser = event => {
    const user_id = event.currentTarget.dataset.key;

    this.setState({ user_id: Number(user_id) }, () => {
      this.props.onChangeUser && this.props.onChangeUser(this.state.user_id);
    });
  };

  handleChangeFilter = event => {
    this.setState({ filter: event.target.value });
  };

  handleClear = () => {
    this.setState({ filter: "" });
  };

  componentDidMount = () => {
    this.fetch();
  };

  changeView = (event) => {
    this.setState({ view: event.currentTarget.value })
  }

  changeGroupFilter = (event) => {
    this.setState({ group_filter: event.currentTarget.dataset.key })
  }

  clearGroupFilter = () => {
    this.setState({ group_filter: "" })
  }

  changeParentFilter = (event) => {
    this.setState({ parent_filter: event.currentTarget.dataset.key })
  }

  clearParentFilter = () => {
    this.setState({ parent_filter: "" })
  }

  render = () => {
    const { filter, group_filter, parent_filter, data, group_type, group_set, view } = this.state;
    const search = 
      data &&
      data
        .filter(
          item =>
            (item.email && item.email.includes(filter)) ||
            (item.family_name && item.family_name.includes(filter)) ||
            (item.given_name && item.given_name.includes(filter)) ||
            (item.middle_name && item.middle_name.includes(filter))
        )
        .map(item => (
          <ListGroupItem
            key={item.user_id}
            active={this.state.user_id === item.user_id}
            tag="button"
            data-key={item.user_id}
            onClick={this.handleClickUser}
            action
          >
            <Container>
              <Row>
                <Col xs="6">
                  {item.family_name}
                  {item.middle_name && ` ${item.middle_name} `}{" "}
                  {item.given_name}
                </Col>
                <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
              </Row>
              <Row>
                <Col>{item.email}</Col>
              </Row>
            </Container>
          </ListGroupItem>
        ));
    
    const group_list = this.props.admin ?
      group_type &&
      group_type
        .sort(item => (item.name))
        .map(item => (
          <ListGroupItem
            key={item.id}
            tag="button"
            data-key={item.id}
            onClick={this.changeGroupFilter}
            action
          >
            <Container>
              <Row>
                <Col>
                  {item.name}{" (" + group_set.filter(element => (element.group_id === item.id)).length + ")"}
                </Col>
              </Row>
            </Container>
          </ListGroupItem>
        )) : {}

    const group = this.props.admin ?
      group_set &&
      group_set
        .filter(item => (item.group_id == group_filter))
        .map(item => {
          const data_item = data.find(element => (element.user_id === item.user_id));
          return (<ListGroupItem
            key={item.user_id}
            active={this.state.user_id === item.user_id}
            tag="button"
            data-key={item.user_id}
            onClick={this.handleClickUser}
            action
          >
            <Container>
              <Row>
                <Col xs="6">
                  {data_item.family_name}
                  {data_item.middle_name && ` ${data_item.middle_name} `}{" "}
                  {data_item.given_name}
                </Col>
                <Col xs="6">{util.convDt2SimpleDate(data_item.birthdate)}</Col>
              </Row>
              <Row>
                <Col>{data_item.email}</Col>
              </Row>
            </Container>
          </ListGroupItem>);
        }) : {}

    const other = this.props.admin ?
      data &&
      data
        .filter(item => (!group_set.some(element => (item.user_id === element.user_id))))
        .map(item => (<ListGroupItem
            key={item.user_id}
            active={this.state.user_id === item.user_id}
            tag="button"
            data-key={item.user_id}
            onClick={this.handleClickUser}
            action
          >
            <Container>
              <Row>
                <Col xs="6">
                  {item.family_name}
                  {item.middle_name && ` ${item.middle_name} `}{" "}
                  {item.given_name}
                </Col>
                <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
              </Row>
              <Row>
                <Col>{item.email}</Col>
              </Row>
            </Container>
          </ListGroupItem>
        )) : {}

    const parent_list = this.props.admin ?
      group_set &&
      group_set
        .filter(item => (item.group_id === MASTER_GROUP_ID))
        .map(item => {
          const data_item = data.find(element => (element.user_id === item.user_id));
          return (<ListGroupItem
            key={item.user_id}
            tag="button"
            data-key={item.user_id}
            onClick={this.changeParentFilter}
            action
          >
            <Container>
              <Row>
                <Col xs="6">
                  {data_item.family_name}
                  {data_item.middle_name && ` ${data_item.middle_name} `}{" "}
                  {data_item.given_name}
                </Col>
                <Col xs="6">
                  {" (" + data.filter(element => (element.parent === item.user_id)).length + ")"}
                </Col>
              </Row> 
              <Row>
                <Col>{data_item.email}</Col>
              </Row>
            </Container>
          </ListGroupItem>);
        }) : {}

    const parent = this.props.admin ?
      data &&
      data
        .filter(item => (item.parent == parent_filter))
        .map(item => (
          <ListGroupItem
            key={item.user_id}
            active={this.state.user_id === item.user_id}
            tag="button"
            data-key={item.user_id}
            onClick={this.handleClickUser}
            action
          >
            <Container>
              <Row>
                <Col xs="6">
                  {item.family_name}
                  {item.middle_name && ` ${item.middle_name} `}{" "}
                  {item.given_name}
                </Col>
                <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
              </Row>
              <Row>
                <Col>{item.email}</Col>
              </Row>
            </Container>
          </ListGroupItem>
        )) : {}

    var content;
    switch(view) {
      case("search"):
        content = search;
        break;
      case("group"):
        if(!group_filter) {
          content = [
            group_list,
            <ListGroupItem
              key="other"
              tag="button"
              data-key="other"
              onClick={this.changeGroupFilter}
              action
            >
              <Container>
                <Row>
                  <Col xs="6">
                    {"Other (" + data.filter(element => (!group_set.some(item => (item.user_id === element.user_id)))).length + ")"}
                  </Col>
                </Row>
              </Container>
            </ListGroupItem>
          ];
        }
        else if(group_filter === "other") {
          content = [
            <ListGroupItem key="back" onClick={this.clearGroupFilter} action>
              Back
            </ListGroupItem>,
            other
          ];
        }
        else {
          content = [
            <ListGroupItem key="back" onClick={this.clearGroupFilter} action>
              Back
            </ListGroupItem>,
            group
          ];
        }
        break;
      case("parent"):
        if(!parent_filter) {
          content = parent_list;
        }
        else {
          content = [
            <ListGroupItem key="back" onClick={this.clearParentFilter} action>
              Back
            </ListGroupItem>,
            parent
          ];
        }
        break;
      default:
        break;
    }

    const disableInput = !data || data.length === 0;

    return (
      <MidCard
        icon={<GroupIcon fontSize="large" color="primary" />}
        title="User List"
        loading={this.state.loading}
      >
        { this.props.admin &&
          <div>
            <mobiscroll.Segmented name="view" value="search" checked={this.state.view === 'search'} onChange={this.changeView} color="primary">
                Search
            </mobiscroll.Segmented>
            <mobiscroll.Segmented name="view" value="group" checked={this.state.view === 'group'} onChange={this.changeView} color="primary">
                Group
            </mobiscroll.Segmented>
            <mobiscroll.Segmented name="view" value="parent" checked={this.state.view === 'parent'} onChange={this.changeView} color="primary">
                Parent
            </mobiscroll.Segmented>
            <div style={{ height: 18 }}></div>
          </div>
        }
        
        { this.state.view === "search" && <CardTitle>
          <InputGroup>
            <Input
              value={this.state.filter}
              placeholder="Search"
              onChange={this.handleChangeFilter}
              disabled={disableInput}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                onClick={this.handleClear}
                disabled={!this.state.filter}
              >
                <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </CardTitle>}
        <CardTitle className="userlist">
          <ListGroup>{content}</ListGroup>
        </CardTitle>
      </MidCard>
    );
  };
}