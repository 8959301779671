import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter
} from "reactstrap";
import restapi from "../util/restapi";

export default class EditTreatment extends React.Component {
  state = {
    loading: false,
    modal: false,
    nestedModal: false,
    closeAll: false,
    content: this.props.content
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  doUpdate = async () => {
    try {
      return await restapi.updateTreatment(
        this.props.id,
        this.state.content.trimRight()
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  doDelete = async () => {
    try {
      return await restapi.deleteTreatment(this.props.id);
    } catch (error) {
      console.log("error", error);
    }
  };

  toggleNested = () => {
    this.setState({ nestedModal: !this.state.nestedModal, closeAll: false });
  };

  toggleAll = () => {
    this.setState({ nestedModal: !this.state.nestedModal, closeAll: true });
  };

  handleChange = event => {
    this.setState({ content: event.target.value });
  };

  handleClick = () => {
    this.toggle();
  };

  handleUpdate = () => {
    this.setState({ loading: true }, async () => {
      await this.doUpdate();
      this.setState({ loading: false }, this.toggle);
    });
  };

  handleDelete = () => {
    this.setState({ loading: true }, async () => {
      await this.doDelete();
      this.setState({ loading: false }, this.toggle);
    });
  };

  render = () => {
    return (
      <>
        <Button
          data-id={this.props.id}
          onClick={this.handleClick}
          size={this.props.size}
        >
          {this.props.children}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={this.props.onFinished}
        >
          <ModalHeader toggle={this.toggle}>Edit Treatment</ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              placeholder="Write something"
              rows={5}
              value={this.state.content}
              onChange={this.handleChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleUpdate}>
              Update
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="secondary" onClick={this.toggleNested}>
              Delete
            </Button>
            <Modal
              isOpen={this.state.nestedModal}
              toggle={this.state.toggleNested}
              onClosed={this.state.closeAll ? this.toggle : undefined}
            >
              <ModalHeader>Delete</ModalHeader>
              <ModalBody>Confirm delete</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.handleDelete}>
                  Delete
                </Button>{" "}
                <Button color="secondary" onClick={this.toggleAll}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </ModalFooter>
        </Modal>
      </>
    );
  };
}
