import React from "react";
import {
  CardText,
  Progress,
  Alert,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { map, sum } from "d3";
import Plot from "react-plotly.js";

const mapRehabTypesub = new Map();
mapRehabTypesub.set("rehab_q_set", "Q-set");
mapRehabTypesub.set("rehab_q_set_fes", "Q-set (with Electrical Stimulation)");
mapRehabTypesub.set("rehab_terminal_ext", "Short Arc Quad");
mapRehabTypesub.set("rehab_slr_open", "SLR (With Straight Knee)");
mapRehabTypesub.set("rehab_slr_close", "SLR (With Bent Knee)");
mapRehabTypesub.set("rehab_knee_squat", "Partial Squat with Chair");
mapRehabTypesub.set("rehab_heel_lift", "Heel Raise");
mapRehabTypesub.set("rehab_ankle_pump", "Ankle Pump");
mapRehabTypesub.set("other", "Other");
// mapRehabTypesub.set("none", "Other");

const OTHER_COLOR = "lightpink";
const OTHER_TEXT = "Other";
const PREV_VERSION = 3;

export default class V2MqList extends React.Component {
  _colors = new Map();

  state = {
    loading: false,
    behavior_id: null,
    data: [],
    strengthList: [],
    enduranceList: []
  };

  constructor(props) {
    super(props);
    this._colors.set("rehab_q_set", "indianred");
    this._colors.set("rehab_q_set_fes", "orange");
    // this._colors.set("rehab_weight_shift", "secondary");
    this._colors.set("rehab_terminal_ext", "yellow");
    this._colors.set("rehab_slr_open", "lightgreen");
    this._colors.set("rehab_slr_close", "skyblue");
    this._colors.set("rehab_knee_squat", "royalblue");
    this._colors.set("rehab_heel_lift", "blueviolet");
    this._colors.set("rehab_ankle_pump", "violet");
    this._colors.set("other", "lightpink");
    // this._colors.set("none", "lightpink");
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.date !== this.props.date) &&
      this.fetch();
  };

  componentDidMount = () => {
    this.props.user_id && this.fetch();
    this.setState({ behavior_id: this.props.behavior_id });
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const dt = this.props.date;
      let data = this.props.data;
      const strengthList = [], enduranceList = [];
      // let mq = await springapi.request("GET", `/v2/log/get/dataByMonth/mq?id=${this.props.user_id}&year=${dt.getFullYear()}&month=${dt.getMonth() + 1}`);
      // if(mq.response) {
      //   console.log(mq.response);
      //   const dateList = [];
      //   mq.response.forEach((d) => {
      //       if(d.strength && d.updateVersion === UPDATE_VERSION) {
      //           if(dateList.includes(d.date)) {
      //               strengthList.find((e) => e.date == d.date).values.push(d.strength);
      //               enduranceList.find((e) => e.date == d.date).values.push(d.endurance);
      //           }
      //           else {
      //               dateList.push(d.date);
      //               strengthList.push({ date: d.date, values: [d.strength] });
      //               enduranceList.push({ date: d.date, values: [d.endurance] });
      //           }
      //       }
      //   });
      // }
      this.setState({ loading: false, data, strengthList, enduranceList });
    });
  };

  handleClickRehab = event => {
    let behavior_id = event.currentTarget.dataset.key;
    behavior_id && (behavior_id = Number(behavior_id));
    if (this.props.currentType === "mq" && this.state.behavior_id === behavior_id) behavior_id = null;
    this.setState({ behavior_id }, () => {
      this.props.onChangeBehavior && this.props.onChangeBehavior(behavior_id);
    });
  };

  render = () => {
    let body = null;
    const data = this.props.data;
    if(this.props.isAdmin && this.props.user_id == null) {
      body = <Alert color="warning">select user</Alert>;
    }
    else {
      const strengthDate = [], strengthData = [];
      // console.log(this.state.strengthList)
      if(this.state.strengthList) {
        this.state.strengthList.forEach((d) => {
            strengthDate.push(d.date);
            strengthData.push(sum(d.values) / d.values.length);
        });
      }
      const monthGraph = strengthDate.length ? (
        <Plot
            data={[
                {
                    x: strengthDate,
                    y: strengthData,
                    
                }
            ]}
            layout={{
                autosize: true,
                xaxis: {
                    // range: [range.start, range.end],
                    fixedrange: true,
                    automargin: true
                },
                yaxis: {
                  automargin: true
                },
                margin: {
                    t: 0,
                    b: 0,
                    l: 0,
                    r: 0
                },
                dragmode: false
            }}
            config={{ responsive: true, displayModeBar: false }}
            style={{ height: 200, width: "auto", padding: 0, margin: 0 }}
        />
      ) : <></>;
      if(data == null || data.length === 0) {
        body = (
          <>
          </>
        );
      } else {
        body = (
          this.props.data.map((d) => {
            const date = d.dateTime.substring(0, 4) + "-" + d.dateTime.substring(4, 6) + "-" + d.dateTime.substring(6, 8);
            const time = d.dateTime.substring(8, 10) + ":" + d.dateTime.substring(10, 12) + ":" + d.dateTime.substring(12, 14);
            let area;
            if(d.side === "") {
              area = d.area.charAt(0).toUpperCase() + d.area.slice(1);
            }
            else {
              area = d.side.charAt(0).toUpperCase() + d.side.slice(1) + " " + d.area.charAt(0).toUpperCase() + d.area.slice(1);
            }
            // const totalTime = parseInt(d.totalTime / 60) + ":" + (d.totalTime % 60 >= 10 ? d.totalTime % 60 : ("0" + d.totalTime % 60));
            if(d.updateVersion >= PREV_VERSION) {
              return <ListGroupItem key={"mq_" + d.id} active={this.props.currentType === "mq" && this.state.behavior_id === d.id} tag="button" data-key={d.id} onClick={this.handleClickRehab} action
                disabled={d.isInvalid} style={d.isInvalid ? { backgroundColor: "#CCCCCC" } : {}}>
              {/* <Row>
                <Col xs="12">{d.data.exerciseName}</Col>
                <Col xs="4">{totalTime}</Col>
              </Row> */}
              <Row>
                <Col xs="6">{area}</Col>
                <Col xs="6">{date + " " + time}</Col>
              </Row>
            </ListGroupItem>;
            }
          })
        );
      }
    }
    // const body = this.props.data.map((d) => {return <div key={d.data.id}>{d.data.id}</div>;})
    return (
      <>
        <ListGroupItem>
          <Row>
            <Col xs="6">Type</Col>
            <Col xs="6">Date Time</Col>
          </Row>
        </ListGroupItem>
        <ListGroup className="mqlist">
          {body}
        </ListGroup>
      </>
    );
  };
}
