import React from "react";
import MidCard from "../component/MidCard";
import { CardText, CardSubtitle, Progress, Alert } from "reactstrap";
import restapi from "../util/restapi";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";

export default class TrendLastDayRehabSingle extends React.Component {
  state = {
    loading: false,
    trend: null
  };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let trend = null;
      const body = {
        user_id: this.props.user_id,
        behavior: "rehab",
        term: "sd"
      };

      try {
        const res = await restapi.patchTrend(body);

        if (res && res.result) trend = res.detail;
      } catch (error) {
        console.log(error);
      }

      this.setState({ trend, loading: false });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    this.props.user_id !== null &&
      prevProps.user_id !== this.props.user_id &&
      this.fetch();
  }

  componentDidMount() {
    this.props.user_id && this.fetch();
  }

  render() {
    let body = null;
    let lastDt = null;

    if (this.props.user_id == null) body = <CardText>select user</CardText>;
    else {
      if (!this.state.loading) {
        const { trend } = this.state;
        if (trend == null) {
          body = <Alert color="warning">no data</Alert>;
        } else {
          const { date, data } = trend;
          const avg =
            data.reduce((accum, item) => accum + item.results, 0) / data.length;

          const dt = new Date(...date.split("-"));
          dt.setUTCMonth(dt.getUTCMonth() - 1);
          lastDt = dt.toLocaleDateString();
          body = (
            <React.Fragment>
              <CardSubtitle>일 평균 달성률</CardSubtitle>
              <Progress color="success" value={avg}></Progress>
              <CardText>{avg}%</CardText>
              {data.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <CardSubtitle>{item.type_sub}</CardSubtitle>
                    <Progress color="info" value={item.results}></Progress>
                    <CardText>{item.results}%</CardText>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        }
      }
    }
    return (
      <MidCard
        icon={<AccessibilityNewIcon fontSize="large" color="primary" />}
        title={`Rehab result last day${lastDt ? ` - ${lastDt}` : ""}`}
        loading={this.state.loading}
      >
        {body}
      </MidCard>
    );
  }
}
