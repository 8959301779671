const SAMPLE_SIZE = 850;
const PI = 3.141592653589793;

class FFTUtil {
    #first;
    #phsTBL;
    #iSign;
    #array;
    #x;
    #xABS;
    #numStage;
    #size;
    #sizeByTwo;
    #convertHz;

    constructor() {
        this.#first = true;
        this.#iSign = 0;
        this.#array = [];
        this.#numStage = 0;
        this.#size = 0;
        this.#sizeByTwo = 0;
        this.#convertHz = 0.;
    }

    initialize(numStage, size) {
        this.#numStage = numStage;
        this.#size = size;
        this.#sizeByTwo = size / 2;
        this.#convertHz = SAMPLE_SIZE / size;

        this.#phsTBL = new Array(size);
        this.#x = new Array(size);
        this.#xABS = new Array(size / 2);
    }

    calculate(inputArray, inputISign) {
        this.rFFT(inputArray, inputISign);

        const size = this.#size;
        const x = this.#x;
        const array = this.#array;
        const xABS = this.#xABS;
        const convertHz = this.#convertHz;

        var i = 0;
        while (i < size) {
            x[i] = array[i] * (size / 2);
            i++;
        }

        xABS[0] = Math.abs(x[0]);
        i = 1;
        while (i < (size / 2)) {
            xABS[i] = Math.sqrt((x[2 * i] * x[2 * i]) + (x[2 * i + 1] * x[2 * i + 1]));
            i++;
        }

        return this.cumulativeDistribution() * convertHz;
    }

    cumulativeDistribution() {
        const size = this.#size;
        const xABS = this.#xABS;
        // console.log(xABS);
        var i;
        var j;
        var indMin;
        var indMax;

        var y;
        let cd = new Array(this.#size / 2);
        let cdMax;
        let line;
        let cdTmp = new Array(this.#size / 2);
        var valMin;
        var valMax;

        y = 0.;
        i = 0;
        while(i < size / 2) {
            y += xABS[i];

            cd[i] = y;
            i++;
        }

        indMax = 0;
        valMax = cd[0];
        i = 1;
        while (i < size / 2) {
            if (valMax < cd[i]) {
                valMax = cd[i];
                indMax = i;
            }
            i++;
        }
        cdMax = valMax;

        i = 0;
        while (i < size / 2) {
            cd[i] = cd[i] / cdMax;
            i++;
        }

        j = 0;
        while (j < size / 2) {
            cdTmp[j] = cd[j];
            j++;
        }

        line = 0.5;
        j = 0;
        while (j < size / 2) {
            cdTmp[j] = Math.abs(cdTmp[j] - line);
            j++;
        }

        indMin = 0;
        valMin = cdTmp[0];
        j = 1;
        while (j < size / 2) {
            if (valMin > cdTmp[j]) {
                valMin = cdTmp[j];
                indMin = j;
            }
            j++;
        }

        return indMin;
    }

    rFFT(inputArray, inputISign) {
        const array = this.#array = inputArray;
        const iSign = this.#iSign = inputISign;
        const first = this.#first;
        const size = this.#size;
        const sizeByTwo = this.#sizeByTwo;
        const phsTBL = this.#phsTBL;

        var fTmp1Real;
        var fTmp1Imag;
        var fTmp2Real;
        var fTmp2Imag;
        var i;
        var j;

        if (first) {
            this.fillTBL();
        }

        if (iSign === 1) {
            this.cFFT();

            fTmp1Real = array[0];
            fTmp2Real = array[1];
            array[0] = fTmp1Real + fTmp2Real;
            array[1] = fTmp1Real - fTmp2Real;

            i = 2;
            j = size - i;
            while (i <= sizeByTwo) {
                fTmp1Real = array[i] + array[j];
                fTmp1Imag = array[i + 1] - array[j + 1];
                fTmp2Real = array[i + 1] + array[j + 1];
                fTmp2Imag = array[j] - array[i];

                array[i] = (fTmp1Real + phsTBL[i] * fTmp2Real - phsTBL[i + 1] * fTmp2Imag) / 2.0;
                array[i + 1] = (fTmp1Imag + phsTBL[i] * fTmp2Imag + phsTBL[i + 1] * fTmp2Real) / 2.0;
                array[j] = (fTmp1Real + phsTBL[j] * fTmp2Real + phsTBL[j + 1] * fTmp2Imag) / 2.0;
                array[j + 1] = (-fTmp1Imag - phsTBL[j] * fTmp2Imag + phsTBL[j + 1] * fTmp2Real) / 2.0;

                i += 2;
                j = size - i;
            }
        }
        else {
            fTmp1Real = array[0];
            fTmp2Real = array[1];
            array[0] = (fTmp1Real + fTmp2Real) / 2.0;
            array[1] = (fTmp1Real - fTmp2Real) / 2.0;

            i = 2;
            j = size - i;
            while (i <= sizeByTwo) {
                fTmp1Real = array[i] + array[j];
                fTmp1Imag = array[i + 1] - array[j + 1];
                fTmp2Real = -(array[i + 1] + array[j + 1]);
                fTmp2Imag = -(array[j] - array[i]);

                array[i] = (fTmp1Real + phsTBL[i] * fTmp2Real + phsTBL[i + 1] * fTmp2Imag) / 2.0;
                array[i + 1] = (fTmp1Imag + phsTBL[i] * fTmp2Imag - phsTBL[i + 1] * fTmp2Real) / 2.0;
                array[j] = (fTmp1Real + phsTBL[j] * fTmp2Real - phsTBL[j + 1] * fTmp2Imag) / 2.0;
                array[j + 1] = (-fTmp1Imag - phsTBL[j] * fTmp2Imag - phsTBL[j + 1] * fTmp2Real) / 2.0;

                i += 2;
                j = size - i;
            }

            this.cFFT();
        }
    }

    cFFT() {
        const size = this.#size;
        const array =  this.#array;
        const sizeByTwo = this.#sizeByTwo;
        const iSign = this.#iSign;
        const numStage = this.#numStage;
        const phsTBL = this.#phsTBL;

        var i = 0;
        var j = 0;
        var k;
        var ii;
        var jj;
        var kk;
        var ji;
        var kj;
        var fTmp;
        var fTmpReal;
        var fTmpImag;

        while (i < size - 2) {
            if (j > i) {
                fTmp = array[i];
                array[i] = array[j];
                array[j] = fTmp;

                fTmp = array[i + 1];
                array[i + 1] = array[j + 1];
                array[j + 1] = fTmp;
            }

            k = sizeByTwo;

            while (j >= k) {
                j -= k;
                k = k >> 1;
            }
            j += k;

            i += 2;
        }

        if (iSign === 1) {
            i = 0;
            while (i < numStage) {
                jj = 2 << i;
                kk = jj << 1;
                ii = size / jj;

                j = 0;
                while (j < jj) {
                    ji = j * ii;

                    k = j;
                    while (k < size) {
                        kj = k + jj;

                        fTmpReal = array[kj] * phsTBL[ji] - array[kj + 1] * phsTBL[ji + 1];
                        fTmpImag = array[kj + 1] * phsTBL[ji] + array[kj] * phsTBL[ji + 1];

                        array[kj] = (array[k] - fTmpReal) / 2.0;
                        array[kj + 1] = (array[k + 1] - fTmpImag) / 2.0;
                        array[k] = (array[k] + fTmpReal) / 2.0;
                        array[k + 1] = (array[k + 1] + fTmpImag) / 2.0;

                        k += kk;
                    }
                    j += 2;
                }
                i++;
            }
        } else {
            i = 0;
            while (i < numStage) {
                jj = 2 << i;
                kk = jj << 1;
                ii = size / jj;

                j = 0;
                while (j < jj) {
                    ji = j * ii;

                    k = j;
                    while (k < size) {
                        kj = k + jj;

                        fTmpReal = array[kj] * phsTBL[ji] - array[kj + 1] * phsTBL[ji + 1];
                        fTmpImag = array[kj + 1] * phsTBL[ji] + array[kj] * phsTBL[ji + 1];

                        array[kj] = array[k] - fTmpReal;
                        array[kj + 1] = array[k + 1] - fTmpImag;
                        array[k] = array[k] + fTmpReal;
                        array[k + 1] = array[k + 1] + fTmpImag;

                        k += kk;
                    }
                    j += 2;
                }
                i++;
            }
        }
    }

    fillTBL() {
        const sizeByTwo = this.#sizeByTwo;
        const phsTBL = this.#phsTBL;

        const deltaF = -PI / sizeByTwo;
        var theta;

        for(let i = 0; i < sizeByTwo; i++) {
            theta = deltaF * i;
            phsTBL[2 * i] = Math.cos(theta);
            phsTBL[2 * i + 1] = Math.sin(theta);
        }

        this.#first = false;
    }
}

export default FFTUtil;