import Decimal from "decimal.js";

export const ProcessingList = [
  { name: "Average Burst RMS",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "RMS Input",
        type: "checkbox",
        initial: true
      },
      {
        name: "RMS Window Length",
        type: "number",
        initial: 0.01,
        precision: -3,
        min: 0.001,
        unit: "s",
        visible: [0, true]
      },
      {
        name: "RMS Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s",
        visible: [0, true]
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true,
        visible: [0, true]
      },
      {
        name: "Baseline Search Window",
        type: "number",
        initial: 0.5,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Number of SDs",
        type: "number",
        initial: 10,
        min: 0
      },
      {
        name: "Suppress Spikes and Gaps",
        type: "checkbox",
        initial: true
      },
      {
        name: "Suppression Time",
        type: "number",
        initial: 0.2,
        precision: -3,
        min: 0.001,
        unit: "s",
        visible: [6, true]
      }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] && parameters[1] <= parameters[2]);
    },
    processFunction: "AverageBurstRMS"
  },
  { name: "Cross Correlation",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Other Signal",
        type: "data",
        initial: null
      },
      {
        name: "Correlation Coefficient",
        type: "checkbox",
        initial: true
      }
    ],
    checkDisabled: (data, parameters) => {
      return parameters[0] === null;
    },
    processFunction: "CrossCorrelation"
  },
  { name: "Curve Fit",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Order",
        type: "number",
        initial: 1,
        precision: 0,
        min: 1
      },
    ],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "CurveFit"
  },
  { name: "Differentiate",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "Differentiate"
  },
  { name: "EMG Threshold",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "RMS Input",
        type: "checkbox",
        initial: true
      },
      {
        name: "RMS Window Length",
        type: "number",
        initial: 0.01,
        precision: -3,
        min: 0.001,
        unit: "s",
        visible: [0, true]
      },
      {
        name: "RMS Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s",
        visible: [0, true]
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true,
        visible: [0, true]
      },
      {
        name: "Baseline Search Window",
        type: "number",
        initial: 0.5,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Number of SDs",
        type: "number",
        initial: 10,
        min: 0
      },
      {
        name: "Suppress Spikes and Gaps",
        type: "checkbox",
        initial: true
      },
      {
        name: "Suppression Time",
        type: "number",
        initial: 0.2,
        precision: -3,
        min: 0.001,
        unit: "s",
        visible: [6, true]
      }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] && parameters[1] <= parameters[2]);
    },
    processFunction: "EMGThreshold"
  },
  { name: "Filter IIR",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Filter Class",
        type: "select",
        initial: "butterworth",
        options: [
          {
            text: "Butterworth",
            value: "butterworth"
          },
          {
            text: "Bessel",
            value: "bessel"
          }
        ]
      },
      {
        name: "Order",
        type: "number",
        initial: 2,
        precision: 0,
        min: 1,
        max: 12
      },
      {
        name: "Filter Type",
        type: "select",
        initial: "lowpass",
        options: [
          {
            text: "Low Pass",
            value: "lowpass"
          },
          {
            text: "High Pass",
            value: "highpass"
          },
          {
            text: "Band Pass",
            value: "bandpass"
          },
          {
            text: "Band Stop",
            value: "bandstop"
          }
        ]
      },
      {
        name: "Cutoff Frequency",
        type: "number",
        initial: 100,
        min: 0,
        unit: "Hz",
        visible: [2, ["lowpass", "highpass"]]
      },
      {
        name: "Cutoff Frequency 1",
        type: "number",
        initial: 100,
        min: 0,
        unit: "Hz",
        visible: [2, ["bandpass", "bandstop"]]
      },
      {
        name: "Cutoff Frequency 2",
        type: "number",
        initial: 400,
        min: 0,
        unit: "Hz",
        visible: [2, ["bandpass", "bandstop"]]
      },
    ],
    checkDisabled: (data, parameters) => {
      return ["bandpass", "bandstop"].includes(parameters[2]) && parameters[4] >= parameters[5];
    },
    processFunction: "FilterIIR"
  },
  { name: "Histogram",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Number of bins",
        type: "number",
        initial: 20,
        precision: 0,
        min: 1
      },
    ],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "Histogram"
  },
  { name: "Integrate",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Length",
        type: "number",
        initial: 0.1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      },
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] <= parameters[1]);
    },
    processFunction: "Integrate"
  },
  { name: "Mean",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "Mean"
  },
  { name: "Mean Absolute Value",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Length",
        type: "number",
        initial: 1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      },
      // {
      //   name: "Remove Offset",
      //   type: "checkbox",
      //   initial: false
      // }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] <= parameters[1]);
    },
    processFunction: "MeanAbsoluteValue"
  },
  { name: "Mean Frequency",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Length",
        type: "number",
        initial: 1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] <= parameters[1]);
    },
    processFunction: "MeanFrequency"
  },
  { name: "Median Frequency",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Length",
        type: "number",
        initial: 1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] <= parameters[1]);
    },
    processFunction: "MedianFrequency"
  },
  { name: "Moving Average",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Length",
        type: "number",
        initial: 1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      },
      // {
      //   name: "Remove Offset",
      //   type: "checkbox",
      //   initial: false
      // }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] <= parameters[1]);
    },
    processFunction: "MovingAverage"
  },
  { name: "Normalize",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Other Signal",
        type: "data",
        initial: null
      }
    ],
    checkDisabled: (data, parameters) => {
      return parameters[0] === null;
    },
    processFunction: "Normalize"
  },
  { name: "Power Spectral Density",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Type",
        type: "select",
        initial: "hann",
        options: [
          {
            text: "Hann",
            value: "hann"
          },
          {
            text: "Bartlett",
            value: "bartlett"
          },
          {
            text: "Blackman",
            value: "blackman"
          },
          {
            text: "Hamming",
            value: "hamming"
          },
          {
            text: "Boxcar",
            value: "hann"
          },
          {
            text: "Triangular",
            value: "triangular"
          },
          {
            text: "Rectangular",
            value: "rectangular"
          }
        ]
      },
      {
        name: "FFT Length",
        type: "number",
        initial: 1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Length",
        type: "number",
        initial: 0.1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[1] < parameters[2] || parameters[2] <= parameters[3]);
    },
    processFunction: "PowerSpectralDensity"
  },
  { name: "Remove Mean",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "RemoveMean"
  },
  { name: "Resample",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Resample Frequency",
        type: "number",
        initial: 1000,
        min: 1,
        max: 10000,
        precision: 0,
        unit: "Hz"
      },
    ],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "Resample"
  },
  { name: "Root Mean Square",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Length",
        type: "number",
        initial: 1,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
      {
        name: "Window Overlap",
        type: "number",
        initial: 0,
        precision: -3,
        min: 0,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: true
      },
      // {
      //   name: "Remove Offset",
      //   type: "checkbox",
      //   initial: false
      // }
    ],
    checkDisabled: (data, parameters) => {
      return (parameters[0] <= parameters[1]);
    },
    processFunction: "RootMeanSquare"
    // process: (data, [windowLength, windowOverlap, zeroPad]) => ProcessingFunctions.RootMeanSquare(data, [new Decimal(windowLength), new Decimal(windowOverlap), zeroPad])
  },
  { name: "Scale and Offset",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Scale",
        type: "number",
        initial: 1
      },
      {
        name: "Offset",
        type: "number",
        initial: 0,
        unit: "V"
      },
    ],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "ScaleAndOffset"
  },
  { name: "Subset",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Start",
        type: "number",
        initial: 0,
        precision: -3,
        unit: "s"
      },
      {
        name: "Length",
        type: "number",
        initial: 10,
        precision: -3,
        min: 0.001,
        unit: "s"
      },
    ],
    checkDisabled: (data, parameters) => {
      return parameters[0] < data.x[0] || parameters[0] > data.x[data.x.length - 1];
    },
    processFunction: "Subset"
  },
  { name: "Time Shift",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Offset",
        type: "number",
        initial: 0,
        precision: -3,
        unit: "s"
      },
      {
        name: "Zero Pad Data",
        type: "checkbox",
        initial: false
      }
    ],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "TimeShift"
  },
  { name: "Test",
    inputUnit: [{ x: "s", y: "V" }],
    parameters: [
      {
        name: "Window Type",
        type: "select",
        initial: "hann",
        options: [
          {
            text: "Hann",
            value: "hann"
          },
          {
            text: "Bartlett",
            value: "bartlett"
          },
          {
            text: "Blackman",
            value: "blackman"
          },
          {
            text: "Hamming",
            value: "hamming"
          },
          {
            text: "Boxcar",
            value: "hann"
          },
          {
            text: "Triangular",
            value: "triangular"
          },
          {
            text: "Rectangular",
            value: "rectangular"
          }
        ]
      }
    ],
    checkDisabled: (data, parameters) => {
      return false;
    },
    processFunction: "Test"
  }
];