import React from "react";
import { AuthPiece, SignIn } from "aws-amplify-react";
import { CardTitle, CardImg, Button } from "reactstrap";
import {
  Form,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from "reactstrap";

import MidCard from "../component/MidCard";
import Spinner from "../uicomponent/Spinner";
import springapi from "../util/springapi";
import DtxUser from "./DtxUser";

export default class DtxSignIn extends React.Component {

  state = {
    loading: false,
    error: null,
    expand: ""
  };

  dtxSignIn = async() => {
    // if(isNaN(document.getElementById("dtxPhone").value.charAt(0))){
      let body = {
        phoneNumber: document.getElementById("dtxPhone").value,
        password: document.getElementById("dtxPassword").value
      }
      this.setState({ loading: true, error: null });
      const res = await springapi.request("POST", "/dtx/web/doctorLogin", body );
      console.log(res);
      if(res && res.response && res.response.exists && res.response.verified) {
        const id = res.response.id;
        DtxUser.signIn(id, res.response.sessionId);
        this.props.setState({ dtxUser: id });
        window.location = "/dtx";
      }
      else {
        this.setState({ loading: false });
      }
  };

  static getDerivedStateFromProps(props, state) {
    if(state.expand !== props.expand){
      return {
        expand: props.expand
      }
    }
    return null;
  }

  handleEnter = event => {
    event.key === "Enter" && this.state.expand === "dtx" && this.dtxSignIn();
  };

  handleError = error => {
    this.setState({ error });
  };

  changeState = state => {
    this.setState({ error: null }, () => {
      super.changeState(state);
    });
  };

  checkUser = async () => {
    if(this.props.user) {
      window.location = "/dtx";
    }
    else {
      try {
        await DtxUser.getInfo().then((dtxUser) => {
            if(dtxUser) {
              window.location = "/dtx";
            }
          }
        );
      }
      catch(e) {
        console.log(e);
      }
    }
  }

  render = () => {
    const { loading } = this.state;
    const size = { size: 4, offset: 4 };
    return (
        <div className="dtx" style={this.props.style}>
          <img width="100%" src="/resource/dtx_logo.png" onClick={() => {this.checkUser(); this.setState({ expand: "dtx" }); this.props.setState({ expand: "dtx" }); this.props.slide && this.props.slide();}}
            className={["img", this.state.expand !== "dtx" && "border"].join(" ")} style={{ paddingLeft: "70px", paddingRight: "70px" }}/>
          <div style={{ height: 10 }} />
          <Form className={["form", this.state.expand !== "dtx" && "form-hidden"].join(" ")}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Phone</InputGroupText>
              </InputGroupAddon>
              <Input
                id="dtxPhone"
                disabled={loading}
                name="phoneNum"
                onKeyPress={this.handleEnter}
                type="text"
                placeholder="01000000000"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Password</InputGroupText>
              </InputGroupAddon>
              <Input
                id="dtxPassword"
                disabled={loading}
                name="password"
                onKeyPress={this.handleEnter}
                type="password"
                placeholder="your password"
              />
            </InputGroup>
            <div style={{ textAlign: "right" }}>
              <Button disabled={loading} onClick={this.dtxSignIn}>
                Login
                <Spinner visible={this.state.loading} />
              </Button>
            </div>
            {/* <img src="resource/kakao_login_medium_narrow.png" className="login" style={{ height: "55px" }} onClick={() => {window.location.href=`https://kauth.kakao.com/oauth/authorize?client_id=f64511a30075dffecc91a4b8effdd632&redirect_uri=${window.location.origin}/kakao&response_type=code`;}}/>
            <img src="resource/naver_login.png" className="login"/> */}
          </Form>
        </div>
    );
  }
}
