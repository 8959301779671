import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { useGlobal } from "./context/global";
import DeckGL, { MapView } from "deck.gl";
import { IconLayer } from "@deck.gl/layers";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import iconMapping from "../util/location-icon-mapping.json";
import IconClusterLayer from "../util/icon-cluster-layer";
import Calendar from "react-calendar";
import TabSelector from "../uicomponent/TabSelector";
import springapi from "../util/springapi";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import Plot from "react-plotly.js";
/* eslint import/no-webpack-loader-syntax: off */
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import _ from "lodash";
mapboxgl.workerClass = MapboxWorker;

const MapModal = () => {
  const { mapOpened, setMapOpened } = useGlobal();
  // const mapRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState("daily");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [monthData, setMonthData] = useState({});
  const [activeMonthData, setActiveMonthData] = useState({});
  const [activeDate, setActiveDate] = useState(new Date());
  const [viewState, setViewState] = useState({
    latitude: 37.409424,
    longitude: 157.094977,
    zoom: 0.8
  });
  const [hoverInfo, setHoverInfo] = useState(null);
  const [user, setUser] = useState(null);
  // const [userInfo, setUserInfo] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [monthlyStatistics, setMonthlyStatistics] = useState({});

  async function getUserLocationList(year, month) {
    const res = await springapi.request("GET", `/v2/log/get/userLocationList?year=${year}&month=${month}`);
    return res.response;
  }
  useEffect(() => {
    async function setData() {
      setIsLoading(true);
      const data = await getUserLocationList(year, month);
      setMonthData(data);
      setActiveMonthData(data);
      // const from = new Date(activeDate);
      // from.setMonth(from.getMonth() - 11);
      // const res = await springapi.request("GET", `/v2/log/get/monthlyUserStatistics?from=${from.getFullYear() + "-" + (from.getMonth() + 1)}&to=${activeDate.getFullYear() + "-" + (activeDate.getMonth() + 1)}`);
      // console.log(res);
      setIsLoading(false);
    }
    if(mapOpened) {
      setData();
    }
  }, [mapOpened, year, month]);

  useEffect(() => {
    async function setStatistics() {
      const from = new Date(activeDate);
      from.setMonth(from.getMonth() - 11);
      const res = await springapi.request("GET", `/v2/log/get/monthlyUserStatistics?from=${from.getFullYear() + "-" + (from.getMonth() + 1)}&to=${activeDate.getFullYear() + "-" + (activeDate.getMonth() + 1)}`);
      setMonthlyStatistics(res.response);
    }
    if(mapOpened) {
      setStatistics();
    }
  }, [mapOpened, activeDate]);

  useEffect(() => {
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth() + 1);
    setActiveDate(currentDate);
  }, [currentDate]);

  // const onMapLoad = useCallback(() => {
  //   const map = mapRef.current.getMap();
  //   map.getStyle().layers.forEach((layer) => {
  //     if(layer.id.endsWith("-label")) {
  //       // console.log(layer.id);
  //       // if(layer.id !== "poi-label") {
  //         map.setLayoutProperty(layer.id, "text-field", ["coalesce", ["get", "name_ko"], ["get", "name_en"]]);
  //       // }
  //     }
  //   });
  // }, []);
  
  if(!mapOpened) {
    return <div/>;
  }

  const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiMTIxMDI1IiwiYSI6ImNsOG1scGE1ZzBha2gzb28zcGprbzR4ZGQifQ.K69eFoI944EsmxCjR0iAZQ";

  const day = currentDate.getDate();
  const dataList = monthData[day] === undefined || isLoading
    ? []
    : Object.entries(monthData[day]).map(([phone, data]) => {
      return {
        info: {
          phone,
          coordinatesList: data.map((v) => ({ ...v, date: year + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0") }))
        },
        coordinates: [data[0].longitude, data[0].latitude]
      }
    });

  const monthDataList = [];
  const monthDataMap = {};
  if(!isLoading) {
    Object.entries(monthData).forEach(([day, info]) => {
      Object.entries(info).forEach(([phone, data]) => {
        if(monthDataMap[phone] === undefined) {
          monthDataMap[phone] = [];
        }
        monthDataMap[phone].push(...data.map((v) => ({ ...v, date: year + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0") })));
      });
    });
    Object.entries(monthDataMap).forEach(([phone, data]) => {
      monthDataList.push({
        info: {
          phone,
          coordinatesList: data
        },
        coordinates: [data[0].longitude, data[0].latitude]
      });
    });
  }

  const layerProps = {
    pickable: true,
    wrapLongitude: true,
    getInfo: d => d.info,
    iconMapping,
    iconAtlas: "resource/location-icon-atlas.png"
  }

  let iconLayerData;
  if(user) {
    let userInfo;
    if(mode === "daily") {
      userInfo = dataList.find((v) => v.info.phone === user);
      iconLayerData = userInfo ? userInfo.info.coordinatesList : [];
    }
    else {
      userInfo = monthDataList.find((v) => v.info.phone === user);
      iconLayerData = userInfo ? userInfo.info.coordinatesList : [];
    }
  }

  const layer = user 
    ? new IconLayer({
      ...layerProps,
      id: "icon",
      data: iconLayerData,
      getPosition: d => [d.longitude, d.latitude],
      getIcon: d => {
        if(selectedLocation === 0) {
          return "marker";
        }
        return selectedLocation === d.id ? "marker" : "marker-masked";
      },
      getColor: d => {
        if(selectedLocation === 0) {
          return [255, 140, 0, 255];
        }
        return selectedLocation === d.id ? [255, 140, 0, 255] : [255, 140, 0, 25];
      },
      sizeUnits: "meters",
      sizeMinPixels: 30,
      sizeMaxPixels: 100,
      updateTriggers: {
        getIcon: d => {
          if(selectedLocation === 0) {
            return "marker";
          }
          return selectedLocation === d.id ? "marker" : "marker-masked";
        },
        getColor: d => {
          if(selectedLocation === 0) {
            return [255, 140, 0, 255];
          }
          return selectedLocation === d.id ? [255, 140, 0, 255] : [255, 140, 0, 25];
        }
      }
    })
    : new IconClusterLayer({
      ...layerProps,
      data: mode === "daily" ? dataList : monthDataList,
      getPosition: d => d.coordinates,
      id: "icon-cluster",
      sizeScale: 20
    });

  function handleTabChange(tab) {
    if(tab === 0) {
      setMode("daily");
    }
    else {
      setMode("monthly");
    }
  }

  function handleClickDay(date) {
    setCurrentDate(date);
  }
  
  function handleClickMonth(monthDate) {
    if(currentDate.getMonth() !== monthDate.getMonth() || currentDate.getFullYear() !== monthDate.getFullYear()) {
      setIsLoading(true);
      setCurrentDate(monthDate);
    }
  }

  function tileContent({ date, view }) {
    if(view !== "month") {
      return null;
    }
    const day = date.getDate();
    if(date.getMonth() !== activeDate.getMonth() || activeMonthData[day] === undefined || isLoading) {
      return (
        <div style={{ opacity: 0 }}>
          0
        </div>
      );
    }
    if(user) {
      if(activeMonthData[day][user] === undefined) {
        return (
          <div style={{ opacity: 0 }}>
            0
          </div>
        );
      }
      return (
        <div style={{ color: date.toDateString() === currentDate.toDateString() ? "white" : "blue" }}>
          {activeMonthData[day][user].length > 999 ? "999+" : activeMonthData[day][user].length}
        </div>
      );
    }
    return (
      <div style={{ color: date.toDateString() === currentDate.toDateString() ? "white" : "blue" }}>
        {Object.keys(activeMonthData[day]).length > 999 ? "999+" : Object.keys(activeMonthData[day]).length}
      </div>
    );
  }

  function handleClickMap(info) {
    if(user) {
      if(info.picked) {
        if(selectedLocation === info.object.id) {
          setSelectedLocation(0);
        }
        else {
          setViewState({
            latitude: info.object.latitude,
            longitude: info.object.longitude,
            zoom: Math.max(15, info.viewport.zoom)
          });
          setSelectedLocation(info.object.id);
        }
      }
    }
    else {
      if(info.picked) {
        if(info.object.properties.cluster) {
          setViewState({
            latitude: info.object.geometry.coordinates[1],
            longitude: info.object.geometry.coordinates[0],
            zoom: info.viewport.zoom + 3
          });
        }
        else {
          setUser(info.object.properties.phone);
          // setUserInfo(info.object.properties);
        }
      }
    }
  }

  function handleHoverMap(info) {
    if(user) {
      if(info.picked) {
        setHoverInfo(info);
      }
      else {
        setHoverInfo(null);
      }
    }
    else {
      if(info.picked && !info.object.properties.cluster) {
        setHoverInfo(info);
      }
      else {
        setHoverInfo(null);
      }
    }
  }

  function renderHoverInfo(info) {
    if(info && info.picked) {
      if(user) {
        return (
          <div style={{ position: "absolute", left: info.x, top: info.y, padding: 15, backgroundColor: "black", opacity: 0.8, color: "white" }}>
            <div>날짜: {info.object.date}</div>
            <div>시간: {info.object.time}</div>
          </div>
        );
      }
      else {
        return (
          <div style={{ position: "absolute", left: info.x, top: info.y, padding: 15, backgroundColor: "black", opacity: 0.8, color: "white" }}>
            <div>{info.object.properties.phone}</div>
            <div>사용 횟수: {info.object.properties.coordinatesList.length}</div>
          </div>
        );
      }
    }
  }

  const infoListComponent = user && (
    <div style={{ width: "100%", height: 249, borderTop: "2px solid black", marginTop: 20 }}>
      <div style={{ display: "flex", width: "100%", padding: 5, alignItems: "center" }}>
        <b>{user} ({iconLayerData.length})</b>
        {/* <div></div> */}
        <div style={{ flexGrow: 1 }}/>
        <CloseIcon style={{ cursor: "pointer", width: 30, color: "black" }} onClick={() => {
          setUser(null);
          setSelectedLocation(0);
        }}/>
      </div>
      <div style={{ borderTop: "1px solid gray" }}>
      <ListGroup style={{ height: 215, overflow: "auto", backgroundColor: "white", borderRadius: 5, padding: 0 }}>
        {iconLayerData.map(({ id, date, time, latitude, longitude }) => (
          <ListGroupItem
            key={id}
            active={selectedLocation === id}
            tag="button"
            // data-key={id}
            onClick={() => {
              if(selectedLocation === id) {
                setSelectedLocation(0);
              }
              else {
                setViewState({
                  latitude,
                  longitude,
                  zoom: 15
                });
                setSelectedLocation(id);
              }
            }}
            action
            style={{ borderRadius: 0 }}
          >
            <Row>
              <Col xs="6">{date}</Col>
              <Col xs="6">{time}</Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
      </div>
    </div>
  );

  async function handleActiveStartDateChange({ activeStartDate, view }) {
    if(view === "month") {
      setActiveDate(activeStartDate);
      const data = await getUserLocationList(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1);
      setActiveMonthData(data);
    }
  }

  return (
    <>
      <div style={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0, zIndex: 1202, backgroundColor: "rgba(0, 0, 0, 0.25)" }} onClick={() => setMapOpened(false)}/>
      <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(570px, -330px)", zIndex: 1203 }}>
        <CloseIcon style={{ cursor: "pointer", width: 30, color: "white" }} onClick={() => setMapOpened(false)}/>
      </div>
      <div style={{ display: "flex", flexDirection: "column", position: "fixed", width: 1200, height: 800, top: "50%", left: "50%", transform: "translate(-600px, -400px)", backgroundColor: "white", zIndex: 1203, border: "2px solid black" }}>
        <div style={{ display: "flex",  }}>
          <TabSelector preLoad fitWidth style={{ width: 400, height: 596, marginRight: -1 }} onTabChange={handleTabChange} tabs={[
            {
              name: "일별",
              component: <div className="calendar">
                <Calendar
                  key={new Date().getTime()}
                  tileClassName="tile"
                  value={currentDate}
                  tileContent={tileContent}
                  tileDisabled={() => isLoading}
                  formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
                  onClickDay={handleClickDay}
                  onActiveStartDateChange={handleActiveStartDateChange}
                  activeStartDate={activeDate}
                  calendarType="US"
                  // onViewChange={this.onViewChange}
                  // view={this.state.view}
                />
                {infoListComponent}
              </div>
            },
            {
              name: "월별",
              component: <div className="calendar">
                <Calendar
                  key={new Date().getTime()}
                  tileClassName="tile"
                  value={currentDate}
                  // tileContent={this.dayTile}
                  tileDisabled={() => isLoading}
                  formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
                  // onClickMonth={(date) => {this.setState({ monthDate: date }); this.props.onMonthChange && this.props.onMonthChange(date);}}
                  // onActiveStartDateChange={handleActiveStartDateChange}
                  onClickMonth={handleClickMonth}
                  calendarType="US"
                  maxDetail="year"
                />
                {infoListComponent}
              </div>
            }
          ]}/>
          <div onContextMenu={(e) => e.preventDefault()} style={{ width: "calc(100% - 400px)", position: "relative", marginLeft: 1, border: "1px solid gray" }}>
            <DeckGL
              views={new MapView({
                repeat: true
              })}
              initialViewState={{ ...viewState, maxZoom: 24 }}
              controller={{ dragRotate: false }}
              layers={[layer]}
              onClick={handleClickMap}
              onHover={handleHoverMap}
              getCursor={({ isDragging, isHovering }) => {
                if(isDragging) {
                  return "grabbing";
                }
                if(isHovering) {
                  return "pointer";
                }
                return "grab";
              }}
            >
              <Map mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle="mapbox://styles/121025/cl8twyo5q000914mmoon0xd4e"  >
              </Map>
              {renderHoverInfo(hoverInfo)}
            </DeckGL>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", height: 200 }}>
          <Plot
            data={[
              {
                x: Object.keys(monthlyStatistics).map((v) => v.substring(0, 4) + "-" + v.substring(4, 6)),
                y: Object.values(monthlyStatistics).map((v) => v.user),
                hoverinfo: "y"
              }
            ]}
            layout={{
              title: "User Statistics",
              xaxis: {
                fixedrange: true,
              },
              yaxis: {
                fixedrange: true,
                range: [0, _.max(Object.values(monthlyStatistics).map((v) => v.user)) * 1.1]
              },
              hovermode: "x",
              margin: {
                t: 50,
                r: 40,
                b: 30,
                l: 40
              }
            }}
            useResizeHandler={true}
            onError={(err) => console.log(err)}
            config={{ responsive: true,
              doubleClick: false,
              displayModeBar: false
            }}
            style={{ width: "50%", height: "100%" }}
          />
          <Plot
            data={[
              {
                x: Object.keys(monthlyStatistics).map((v) => v.substring(0, 4) + "-" + v.substring(4, 6)),
                y: Object.values(monthlyStatistics).map((v) => v.exercise),
                hoverinfo: "y+name",
                name: "Exercise"
              },
              {
                x: Object.keys(monthlyStatistics).map((v) => v.substring(0, 4) + "-" + v.substring(4, 6)),
                y: Object.values(monthlyStatistics).map((v) => v.mq),
                hoverinfo: "y+name",
                name: "MFI"
              },
              {
                x: Object.keys(monthlyStatistics).map((v) => v.substring(0, 4) + "-" + v.substring(4, 6)),
                y: Object.values(monthlyStatistics).map((v) => v.es),
                hoverinfo: "y+name",
                name: "Bioelectric"
              }
            ]}
            layout={{
              title: "Data Statistics",
              xaxis: {
                fixedrange: true,
              },
              yaxis: {
                fixedrange: true,
                range: [0, Math.max(_.max(Object.values(monthlyStatistics).map((v) => v.exercise)),
                                    _.max(Object.values(monthlyStatistics).map((v) => v.mq)),
                                    _.max(Object.values(monthlyStatistics).map((v) => v.es))) * 1.1]
              },
              hovermode: "x",
              margin: {
                t: 50,
                r: 40,
                b: 30,
                l: 40
              }
            }}
            useResizeHandler={true}
            onError={(err) => console.log(err)}
            config={{ responsive: true,
              doubleClick: false,
              displayModeBar: false
            }}
            style={{ width: "50%", height: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default MapModal;