import React, { Component } from "react";
import {
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from "reactstrap";
import { Alert } from "reactstrap";

import MidCard from "../component/MidCard";
import restapi from "../util/restapi";

export default class License extends Component {
  _isMount = false;

  state = {
    loading: false,
    license: "",
    code: "",
    message: "",
    error: false
  };

  componentDidMount() {
    this._isMount = true;
    this.getLicense();
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  getLicense = () => {
    if (!this._isMount) return;

    this.setState({ loading: true }, async () => {
      let license = "unknown";
      try {
        const res = await restapi.getLicense();
        if (!this._isMount) return;

        if (res && res.result) {
          license = `${res.detail.model} ${res.detail.type}`;
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, license });
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRegister = async e => {
    if (!this._isMount) return;

    this.setState({ loading: true }, async () => {
      let license = "unknown";
      try {
        const res = await restapi.postLicense(
          this.state.code,
          this.props.user_id
        );

        if (res && res.result) {
          license = `${res.detail.model} ${res.detail.type}`;
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false, license, code: "" });
    });
  };

  render() {
    const { license, code, message, error } = this.state;
    let msg;
    message &&
      (msg = <Alert color={error ? "danger" : "success"}>{message}</Alert>);
    const register = (
      <>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Code</InputGroupText>
          </InputGroupAddon>
          <Input
            name="code"
            value={code}
            onChange={this.handleChange}
            type="text"
          />
        </InputGroup>
        <br />
        <Button onClick={this.handleRegister}>Register</Button>
        {msg && msg}
      </>
    );
    return (
      <MidCard title="License" loading={this.state.loading}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Code</InputGroupText>
          </InputGroupAddon>
          <Input disabled name="license" value={license} type="text" />
        </InputGroup>
        {this.props.register && register}
      </MidCard>
    );
  }
}
