const GraphColors = {
    line: {
        1: "#f15bb5",
        2: "#00bbf9",
        3: "#00f5d4",
        4: "#9b5de5"
    },
    bar: {
        1: "#ff99c8",
        2: "#a9def9",
        3: "#d0f4de",
        4: "#e4c1f9"
    },
    pie: [
        // "#9acae2",
        // "#a9def9",
        // "#bddeca",
        // "#d0f4de",
        // "#e5e0ac",
        // "#fcf6bd",
        // "#e88bb6",
        // "#ff99c8",
        // "#cfafe2",
        // "#e4c1f9"
        "#078150",
        "#fcbc20",
        "#49d4c6",
        "#e71d36",
        "#613dc1",
        "#7ae582",
        "#fb8500",
        "#04235f",
        "#800e13",
        "#808184"
    ],
    section: [
        "#ffff00",
        "#44ff44"
    ]
}

export default GraphColors;