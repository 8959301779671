import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";

import "./css/Home.css";

import User from "./User";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Overview from "./Overview";
import UserList from "./UserList";
import translation from "../language/translation";
import { SettingsContext, useSettings } from "./context/settings";


const V2Home = (props) => {
  const initialState = {
    id: null,
    isAdmin: false,
    menuFold: false
  };
  const [state, setState] = useState(initialState);
  const [language, setLanguage] = useState(translation.getLanguage());
  const [l10n, setL10n] = useState({});

  useEffect(() => {
    fetch();
    return () => {
      console.log("unmounted");
    };
  }, []);

  useEffect(() => {
    translation.setLanguage(language);
    setL10n(translation.translate("sidebar", language));
  }, [language]);

  const fetch = async() => {
    const id = await User.getId();
    const isAdmin = await User.isAdmin(id);
    if(isAdmin) {
      setState({ ...state, id: null, isAdmin });
    }
    else {
      setState({ ...state, id, isAdmin });
    }
  }

  const signOut = () => {
    User.signOut();
    window.location.reload();
  }

  const handleUserChange = event => {
    const id = Number(event);
    !Number.isNaN(id) &&
      setState({ ...state, id });
  };

  return (
    <SettingsContext.Provider value={{ language, setLanguage }}>
      <Router hashType="noslash">
        <div className="page">
          <div className="sidebar" hidden={state.menuFold}>
            <div className="sidebar-top">
              <a href="/" style={{ backgroundColor: "#00000000", color: "white" }}>
                {l10n.home}
              </a>
              <a href="" onClick={() => signOut()} style={{ backgroundColor: "#00000000", color: "white", marginLeft: 10 }}>
                {l10n.signout}
              </a>
              <CloseIcon style={{ color: "white", height: "100%", float: "right", cursor: "pointer" }} onClick={() => setState({ ...state, menuFold: !state.menuFold })}/>
              <SettingsIcon style={{ color: "white", height: "100%", float: "right", cursor: "pointer" }} onClick={() => setLanguage(language === "en" ? "kr" : "en")}/>
            </div>
            <hr className="sidebar-line"/>
            <div className="menu-list">
              <button className="menu-item" onClick={() => window.location.href="#overview"}>{l10n.overview}</button>
              <button className="menu-item" onClick={() => window.location.href="#exercise"}>{l10n.exercise}</button>
              <button className="menu-item" onClick={() => window.location.href="#mq"}>{l10n.mq}</button>
              <button className="menu-item" onClick={() => window.location.href="#bioelectric"}>{l10n.bioelectric}</button>
            </div>
            {state.isAdmin && (
              <>
                <hr className="sidebar-line"/>
                <div style={{ backgroundColor: "#00000000", color: "white", paddingLeft: 10, marginTop: 10 }}>{l10n.userlist}</div>
                <UserList id={state.id} onChangeUser={handleUserChange}/>
              </>
            )}
          </div>
          <div className="sidebar-fold" hidden={!state.menuFold} onClick={() => setState({ ...state, menuFold: !state.menuFold })}>
            <MenuIcon style={{ color: "white", width: "100%", marginTop: "8px" }}/>
          </div>
          <div className="v2-content">
            <Switch>
              <Route exact path="/overview"><Overview userId={state.id}/></Route>
              <Route exact path="/exercise"></Route>
              <Route exact path="/mq"></Route>
              <Route exact path="/bioelectric"></Route>
              <Route><Redirect to="/overview"/></Route>
            </Switch>
          </div>
        </div>
      </Router>
    </SettingsContext.Provider>
  );
};

export default V2Home;