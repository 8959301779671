import _ from "lodash";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const ScatterPlot = (props) => {
    const [coords, setCoords] = useState({ x: [], y: [] });
    const [area, setArea] = useState(0);
    const [order, setOrder] = useState([]);

    const category = {
        근지구력: {
            unit: ""
        },
        근력: {
            unit: ""
        },
        MQ: {
            unit: ""
        }
    }
    // const [rank, setRank] = useState(0);
    useEffect(() => {
        // const x = props.data.scatteredDots.map((d) => d[0]);
        // const y = props.data.scatteredDots.map((d) => d[1]);
        setCoords({ x: props.data.x, y: props.data.y });
        let temp;
        for(let i = 2; i >= 0; i--) {
            if(props.data.userX <= props.data.lines[i]) {
                temp = i;
            }
        }
        if(props.data.userX > props.data.lines[2]) {
            temp = 3;
        }
        setArea(temp);
        if(props.title !== "근지구력") {
            setOrder([100, 75, 50, 25]);
        }
        else {
            setOrder([25, 50, 75, 100]);
        }
        // console.log((props.data.y.filter(d => d < props.data.userX).length + 1) / (props.data.y.length + 1));
    }, [props.data]);console.log((props.range.min + props.range.max) / 2);

    return (
        <div style={{ display: "inline-block", textAlign: "center" }}>
        <Plot
            data={[
                {
                    yaxis: "y2"
                },
                {
                    x: [props.range.min * 0.8, props.range.max * 1.2],
                    y: [props.data.lines[0], props.data.lines[0]],
                    mode: "lines",
                    opacity: 0.5,
                    line: {
                        color: "blue",
                        width: 1
                    },
                    showlegend: false,
                    hoverinfo: "none",
                    fill: `${area === 0 ? "tonexty" : "none"}`,
                    fillcolor: "#ffff0088"
                },
                {
                    x: [props.range.min * 0.8, props.range.max * 1.2],
                    y: [props.data.lines[1], props.data.lines[1]],
                    mode: "lines",
                    opacity: 0.5,
                    line: {
                        color: "blue",
                        width: 1
                    },
                    showlegend: false,
                    hoverinfo: "none",
                    fill: `${area === 1 ? "tonexty" : "none"}`,
                    fillcolor: "#ffff0088"
                },
                {
                    x: [props.range.min * 0.8, props.range.max * 1.2],
                    y: [props.data.lines[2], props.data.lines[2]],
                    mode: "lines",
                    opacity: 0.5,
                    line: {
                        color: "blue",
                        width: 1
                    },
                    showlegend: false,
                    hoverinfo: "none",
                    fill: `${area === 2 ? "tonexty" : "none"}`,
                    fillcolor: "#ffff0088"
                },
                {
                    x: [props.range.min * 0.8, props.range.max * 1.2],
                    y: [props.range.max * 1.2, props.range.max * 1.2],
                    mode: "lines",
                    opacity: 0.5,
                    line: {
                        color: "blue",
                        width: 1
                    },
                    showlegend: false,
                    hoverinfo: "none",
                    fill: `${area === 3 ? "tonexty" : "none"}`,
                    fillcolor: "#ffff0088"
                },
                {
                    x: [(props.range.min + props.range.max) / 2, (props.range.min + props.range.max) / 2],
                    y: [props.range.min * 0.8, props.range.max * 1.2],
                    mode: "lines",
                    line: {
                        color: "black",
                        width: 1,
                        dash: "dash"
                    },
                    hoverinfo: "none",
                    showlegend: false
                },
                // {
                //     x: coords.x,
                //     y: coords.y,
                //     mode: "markers",
                //     marker: {color: "black", symbol: "circle-open"},
                //     name: "AI 결과"
                // },
                {
                    x: [(props.range.min + props.range.max) / 2],
                    y: [props.data.userX],
                    mode: "markers",
                    marker: {color: "blue", opacity: 0.3, size: 600 / (props.range.max - props.range.min) * props.offset},
                    hoverinfo: "none",
                    name: `AI 오차범위<br>(&plusmn;${_.round(props.offset, 3)}${category[props.title].unit})`
                },
                {
                    x: [(props.range.min + props.range.max) / 2],
                    y: [props.data.userX],
                    mode: "markers",
                    marker: {color: "red", size: 10},
                    name: "사용자"
                }
            ]}
            layout={{
                // title: props.title,
                width: 500, height: 400,
                // showlegend: false,
                margin: {
                    t: 50,
                    b: 30
                },
                legend: {
                    x: 1,
                    y: 0,
                    xanchor: "right",
                    yanchor: "bottom",
                    // bgcolor: "rgba(0, 0, 0, 0)",
                    traceorder: "normal",
                    borderwidth: 1
                },
                xaxis: {
                    // title: category[props.title].unit === "" ? "예측치" : `예측치(${category[props.title].unit})`,
                    range: props.title === "근지구력" ? [props.range.max, props.range.min] : [props.range.min, props.range.max],
                    fixedrange: true,
                    dtick: props.range.tick,
                    automargin: true,
                    visible: false
                    // autorange: `${props.title === "근지구력" ? "reversed" : ""}`
                },
                yaxis: {
                    // title: category[props.title].unit === "" ? "실측치" : `실측치(${category[props.title].unit})`,
                    title: "예측치",
                    range: props.title === "근지구력" ? [props.range.max, props.range.min] : [props.range.min, props.range.max],
                    fixedrange: true,
                    scaleanchor: "x",
                    scaleratio: 1,
                    dtick: props.range.tick,
                    automargin: true,
                    // autorange: `${props.title === "근지구력" ? "reversed" : ""}`
                },
                yaxis2: {
                    title: "상위 %",
                    range: props.title === "근지구력" ? [props.range.max, props.range.min] : [props.range.min, props.range.max],
                    tickvals: props.data.lines,
                    ticktext: props.title !== "근지구력" ? Array(3).fill(0).map((e, i) => `~${(3 - i) * 25}%`) : Array(3).fill(0).map((e, i) => `~${(i + 1) * 25}%`),
                    side: "right",
                    scaleanchor:"y",
                    overlaying: "y",
                    showgrid: false
                    // automargin: true
                },
                shapes: [
                    // {
                    //     type: "line",
                    //     x0: props.range.min, x1: props.range.max, y0: props.range.min, y1: props.range.max,
                    //     line: {
                    //         color: "black",
                    //         width: 1,
                    //         dash: "dash"
                    //     },
                    //     layer: "below"
                    // },
                    // {
                    //     type: "circle",
                    //     xref: "x",
                    //     yref: "y",
                    //     x0: props.data.userX - props.offset,
                    //     x1: props.data.userX + props.offset,
                    //     y0: props.data.userX - props.offset,
                    //     y1: props.data.userX + props.offset,
                    //     opacity: 0.2,
                    //     fillcolor: "blue",
                    //     line: {
                    //         width: 0
                    //     }
                    // }
                ],
                annotations: [
                    // {
                    //     x: 0,
                    //     y: (props.range.min + props.data.lines[0]) / 2,
                    //     xref: "paper",
                    //     xanchor: "left",
                    //     xshift: 0,
                    //     showarrow: false,
                    //     align: "left",
                    //     text: `${props.title === "근지구력" ? "상위 " : ""}~${order[0]}%`
                    // },
                    // {
                    //     x: 0,
                    //     y: (props.data.lines[0] + props.data.lines[1]) / 2,
                    //     xref: "paper",
                    //     xanchor: "left",
                    //     xshift: 0,
                    //     showarrow: false,
                    //     align: "left",
                    //     text: `~${order[1]}%`
                    // },
                    // {
                    //     x: 0,
                    //     y: (props.data.lines[1] + props.data.lines[2]) / 2,
                    //     xref: "paper",
                    //     xanchor: "left",
                    //     xshift: 0,
                    //     showarrow: false,
                    //     align: "left",
                    //     text: `~${order[2]}%`
                    // },
                    // {
                    //     x: 0,
                    //     y: (props.data.lines[2] + props.range.max) / 2,
                    //     xref: "paper",
                    //     xanchor: "left",
                    //     xshift: 0,
                    //     showarrow: false,
                    //     align: "left",
                    //     text: `${props.title === "근력" ? "상위 " : ""}~${order[3]}%`
                    // },
                    {
                        x: 0,
                        y: 0,
                        xref: "paper",
                        yref: "paper",
                        xanchor: "left",
                        xshift: -50,
                        showarrow: false,
                        align: "left",
                        text: "&#8592; 약",
                        textangle: -90
                    },
                    {
                        x: 0,
                        y: 1,
                        xref: "paper",
                        yref: "paper",
                        xanchor: "left",
                        xshift: -50,
                        showarrow: false,
                        align: "right",
                        text: "강 &#8594;",
                        textangle: -90
                    }
                ]
            }}
            config={{ responsive: true }}
        />
        {/* {props.title === "근력" && <div style={{ fontSize: 20 }}>근력은 클수록 좋습니다</div>}
        {props.title === "근지구력" && <div style={{ fontSize: 20 }}>근지구력은 작을수록 좋습니다</div>} */}
        {/* <div style={{ fontSize: 20 }}><b>사용자의 {props.title}{props.title === "MQ" ? "는" : "은"} {props.title === "근지구력" ? parseFloat(props.data.userX.toFixed(3)) : parseFloat(props.data.userX.toFixed(1))}{category[props.title].unit}
            {props.title !== "MQ" ? "" : `로 상위 ${parseFloat(props.data.userRank.toFixed(1))}%`}입니다</b></div> */}
            <div style={{ fontSize: 20 }}>Value: {_.round(props.data.userX, 3)}</div>
        </div>
    );
};

export default ScatterPlot;