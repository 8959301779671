import Tabs from "./Tabs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PlotComponent from "./PlotComponent";
import composeRefs from '@seznam/compose-react-refs'
import { useHorizontalScroll } from "../util/useHorizontalScroll";
import { useContextMenu } from "./ContextMenuContext";

export default function GraphComponent({ type, tabList, data, setData, openProcessingComponent }) {
  const scrollRef = useHorizontalScroll();
  const { setPosition, setContent } = useContextMenu();
  // const [tabList, setTabList] = states[type].list;
  // const [data, setData] = states[type].data;

  return (
    <Droppable droppableId={type} direction="horizontal">
      {(dropProvided, dropSnapshot) => {
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <div id={type} className="tab-scroll" ref={composeRefs(dropProvided.innerRef, scrollRef)} {...dropProvided.droppableProps}
              style={{ width: "calc(100% + 1px)", height: 31, display: "flex", overflowX: "auto", overflowY: "hidden", backgroundColor: "#eeeeee" }}>
              <Tabs type={type} tabList={tabList} data={data} setData={setData}/>
              {dropProvided.placeholder}
            </div>
            <div style={{ height: "calc(100% - 31px)", marginTop: -1, borderTop: "1px solid black" }}
              onContextMenu={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if(data) {
                  const left = e.clientX > window.innerWidth - 200 ? e.clientX - 200 : e.clientX;
                  const top = e.clientY > window.innerHeight - 200 ? e.clientY - 200 : e.clientY;
                  setPosition({ left, top: e.clientY });
                  setContent(
                    <>
                      {/* <div className="context-menu-item" >Download CSV</div>
                      <div className="context-menu-item" >Delete Data</div> */}
                      <div className="context-menu-item" onClick={(e) => openProcessingComponent(data)}>Signal Processing</div>
                    </>
                  );
                }
              }}>
              {data && 
                <PlotComponent data={data}/>
              }
            </div>
          </div>
        );
      }}
    </Droppable>
  );
}