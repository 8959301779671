import React, { Component } from "react";
import MidCard from "./MidCard";
import restapi from "../util/restapi";
import { CardText } from "reactstrap";
import GraphCore from "./GraphCore";
import BarGraph from "./BarGraph";
import BalanceGraph from "./BalanceGraph";
import TimelineIcon from "@material-ui/icons/Timeline";
import BarIcon from "@material-ui/icons/BarChart";
import springapi from "../util/springapi";
import * as d3 from "d3";
// import dsp from "../util/dsp";
const fft = require("fft-js").fft;
const FFTUtil = require("../util/FFTUtil").default;


export default class EmgGraph extends Component {
  svgId = new Date().getTime();
  state = {
    loading: false,
    data: [],
    slices: [],
    fatigue: [],
    rms: [],
    balance: [],
    balanceX: []
  };

  componentDidUpdate(prevProps, prevState) {
    this.props.behavior_id !== null &&
      prevProps.behavior_id !== this.props.behavior_id &&
      this.fetch();
  }

  componentDidMount() {
    this.props.behavior_id && this.fetch();
  }

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const newState = { loading: false, data: [], slices: [], fatigue: [], rms: [], balance: [], balanceX: [] };

      try {
        console.log(this.props.behavior_id);
        const res = await restapi.getRawData(this.props.behavior_id);

        if (res && res.result) {
          // remove FES signal(data=-1)
          const filteredData = filter(res.detail.filter(data => data >= 0));
          newState.data = filteredData[0].map((element) => element - filteredData[2]);
          newState.slices = filteredData[1];
          newState.fatigue = await springapi.fatigue(filteredData[1].map((element) => {return filteredData[0].slice(element[0], element[1]);}));
          newState.rms = await springapi.rms(filteredData[0]);
          const otherBehavior = (await springapi.getOtherBehavior(this.props.behavior_id)).body.otherBehavior;
          console.log(otherBehavior);
          if(otherBehavior) {
            const otherRes = await restapi.getRawData(otherBehavior.id);
            if(otherRes && otherRes.result) {
              const otherFilteredData = filter(otherRes.detail.filter(data => data >= 0));
              const otherRms = await springapi.rms(otherFilteredData[0]);
              console.log(newState.rms);
              if(newState.rms.length >= 1 && otherRms.length >= 1) {
                var thisRmsArray = [];
                var otherRmsArray = [];
                var temp = 0;
                var flag = false;
                for(let i = 0; i < newState.rms.length; i++) {
                  if(newState.rms[i] >= temp) {
                    flag = true;
                    temp = newState.rms[i];
                  }
                  else {
                    if(flag) {
                      thisRmsArray.push(temp);
                    }
                    flag = false;
                    temp = newState.rms[i];
                  }
                }
                if(flag) {
                  thisRmsArray.push(temp);
                }

                temp = 0;
                flag = false;
                for(let i = 0; i < otherRms.length; i++) {
                  if(otherRms[i] >= temp) {
                    flag = true;
                    temp = otherRms[i];
                  }
                  else {
                    if(flag) {
                      otherRmsArray.push(temp);
                    }
                    flag = false;
                    temp = otherRms[i];
                  }
                }
                if(flag) {
                  otherRmsArray.push(temp);
                }

                // for(let i = 1; i < newState.rms.length - 1; i++) {
                //   if(newState.rms[i] >= newState.rms[i - 1] && newState.rms[i] >= newState.rms[i + 1]) {
                //     thisBalance.push(newState.rms[i]);
                //   }
                // }
                // if(newState.rms[newState.rms.length - 1] >= newState.rms[newState.rms.length - 2]) {
                //   thisBalance.push(newState.rms[newState.rms.length - 1]);
                // }
                // for(let i = 1; i < otherRms.length - 1; i++) {
                //   if(otherRms[i] >= otherRms[i - 1] && otherRms[i] >= otherRms[i + 1]) {
                //     otherBalance.push(otherRms[i]);
                //   }
                // }
                // if(otherRms[otherRms.length - 1] >= otherRms[newState.rms.length - 2]) {
                //   thisBalance.push(otherRms[otherRms.length - 1]);
                // }
                const thisAvg = thisRmsArray.reduce((a, b,) => a + b) / thisRmsArray.length;
                const otherAvg = otherRmsArray.reduce((a, b,) => a + b) / otherRmsArray.length;
                if(otherBehavior.part === "knee_right") {
                  newState.balance = [thisAvg, otherAvg];
                  newState.balanceX = ["Left(this)", "Right"];
                }
                else {
                  newState.balance = [otherAvg, thisAvg];
                  newState.balanceX = ["Left", "Right(this)"];
                }
              }
            }
          }
          // newState.norm = await springapi.norm(newState.rms);
          // const avg = newState.rms.reduce((a, b) => a + b) / newState.rms.length;
          // const dev = d3.deviation(newState.rms);
          // console.log(dev);
          // newState.test = newState.rms.map((element) => (element - avg) / dev);
        }
      } catch (error) {
        console.log(error);
      }

      this.setState(newState);
    });
  };


  render() {
    return (
      <>
        <MidCard
          icon={<TimelineIcon fontSize="large" color="primary" />}
          title="Raw"
          loading={this.state.loading}
        >
          <CardText>
            <GraphCore
              data={this.state.data}
              graphId={`raw${this.svgId}`}
            ></GraphCore>
          </CardText>
        </MidCard>
        <MidCard
          icon={<BarIcon fontSize="large" color="primary" />}
          title="Fatigue"
          loading={this.state.loading}
        >
          <CardText>
            <BarGraph
              data={this.state.fatigue.filter(data => data !== -1)}
              graphId={`fatigue${this.svgId}`}
            ></BarGraph>
          </CardText>
        </MidCard>
        <MidCard
          icon={<TimelineIcon fontSize="large" color="primary" />}
          title="RMS"
          loading={this.state.loading}
        >
          <CardText>
            <GraphCore
              data={this.state.rms}
              graphId={`rms${this.svgId}`}
            ></GraphCore>
          </CardText>
        </MidCard>
        {this.state.balance.length &&
        <MidCard
          icon={<BarIcon fontSize="large" color="primary" />}
          title="Balance"
          loading={this.state.loading}
        >
          <CardText>
            <BalanceGraph
              data={this.state.balance}
              graphId={`balance${this.svgId}`}
              x={this.state.balanceX}
            ></BalanceGraph>
          </CardText>
        </MidCard>
        }
        {/* <MidCard
          icon={<TimelineIcon fontSize="large" color="primary" />}
          title="Normalize"
          loading={this.state.loading}
        >
          <CardText>
            <GraphCore
              data={this.state.norm}
              graphId={`norm${this.svgId}`}
            ></GraphCore>
          </CardText>
        </MidCard> */}
      </>
    );
  }
}

function filter(emgData) {
  const count = emgData.length;
  const frameSize = 64;
  const kPoint = parseInt(frameSize / 2);

  var I = [];
  const arrLength = parseInt(count / parseInt(frameSize / 2));
  var G = [];
  var psi = [];
  var tau = new Array(arrLength).fill(0);
  var dec = [];
  
  // GET I
  for(let i = 0; i < parseInt(count - frameSize); i += parseInt(frameSize / 2)) {
      const T1 = getFFT(emgData, i, frameSize);
      // T1.forEach((element, index) => {
      //     T1[index] = Math.pow(element, 2);
      // });
      I.push(T1.map((element) => {return Math.pow(element, 2)}));
  }
  for(let i = I.length; i < arrLength; i++) {
    I.push(new Array(parseInt(frameSize / 2)).fill(0));
  }

  // GET G
  for(let i = 0; i < arrLength; i++) {
      var tempGProduct = 1;
      for(let j = 0; j < kPoint; j++) {
          tempGProduct *= I[i][j] + 1;
      }
      G.push(Math.pow(tempGProduct, 1 / kPoint));
  }
  console.log(G);

  // GET psi
  for(let i = 0; i < arrLength; i++) {
      psi.push(Math.log(G[i]));
  }

  // GET tau
  const iMovingWindow = 2;
  for(let i = iMovingWindow; i < arrLength - 1; i++) {
      tau[i + 1] = -Math.log(Math.exp(-tau[i] + (Math.exp(-psi[i]) - Math.exp(-psi[i - iMovingWindow])) / iMovingWindow));
      if(tau[i + 1] < 0) {
          tau[i + 1] = 0;
      }
  }

  const dePsi = psi.slice().sort();
  const LdePsireSize = parseInt(dePsi.length / 100) < 1 ? 1 : parseInt(dePsi.length / 100);

  var tempMax = 0;
  var tempMin = 0;
  for(let i = 0; i < LdePsireSize; i++) {
      tempMin += dePsi[i];
      tempMax += dePsi[dePsi.length - i - 1];
  }
  tempMax /= LdePsireSize;
  tempMin /= LdePsireSize;

  for(let i = 0; i < arrLength; i++) {
      tau[i] = (tempMax + tempMin) / 2 - tau[i];
  }
  
  // GET dec
  for(let i = 0; i < parseInt(arrLength * frameSize / 2); i++) {
      const temp = parseInt(i / (frameSize / 2));
      dec.push(psi[temp] > tau[temp] ? 1 : 0);
  }
  
  var min = 0;
  var sum = 0;
  var avg = 0;
  for(let i = 0; i < dec.length; i++) {
    if(dec[i] === 0) {
      min = i;
      break;
    }
  }
  for(let i = min; i < dec.length; i++) {
    sum += emgData[i];
    if(dec[i] === 1) {
      avg = sum / (i - min);
      break;
    }
  }

  var start = 0;
  if(dec[0] === 1) {
    for(let i = 1; i < dec.length; i++) {
      if(dec[i] === 0) {
        if(d3.deviation(emgData.slice(0, i)) >= 20) {
          for(let j = i; j < dec.length; j++) {
            if(dec[j] === 1) {
              start = Math.max(i, j - frameSize);
              break;
            }
          }
          emgData = emgData.slice(start);
        }
        break;
      }
    }
  }

  var emgSlice = [];
  var flag = false;
  var index = 0;
  for(let i = start; i < dec.length; i++) {
      if(dec[i] === 1 && !flag) {
          flag = true;
          index = i;
      }
      else if(dec[i] === 0 && flag) {
          flag = false;
          // console.log(index, i);
          emgSlice.push([index - start, i - start]);
      }
  }
  if(flag) {
      emgSlice.push([index - start, dec.length - start]);
  }

  for(let i = 0; i < emgSlice.length - 1; i++) {
    if(emgSlice[i + 1][0] - emgSlice[i][1] < frameSize) {
      emgSlice[i][1] = emgSlice[i + 1][1];
      emgSlice.splice(i + 1, 1);
    }
  }

  return [emgData, emgSlice, avg];
}

function getFFT(array, start, FFTSize) {
  const cutArray = array.slice(start, start + FFTSize);
  const cutLength = cutArray.length;
  var yfft = fft(cutArray);
  yfft = yfft.slice(0, parseInt(cutLength / 2));
  yfft[0] = 0
  for(let i = 1; i < yfft.length; i++) {
      yfft[i] = Math.sqrt(Math.pow(yfft[i][0] / cutLength, 2) + Math.pow(yfft[i][1] / cutLength, 2)) * 2;
  }
  return yfft;
}

