import { Auth } from "aws-amplify";

const getUserInfo = async () => {
  const session = await Auth.currentSession();
  const _idToken = session.getIdToken();
  const idToken = session.getIdToken().getJwtToken();
  const accessToken = session.getAccessToken().getJwtToken();
  const user_id = _idToken.payload["custom:uid"];
  const master =
    _idToken.payload["cognito:groups"] != null &&
    _idToken.payload["cognito:groups"].includes("exo-master");

  return {
    master,
    user_id,
    idToken,
    accessToken
  };
};

const isAdmin = async () => {
  const session = await Auth.currentSession();
  const _idToken = session.getIdToken();

  return (
    _idToken.payload["cognito:groups"] != null &&
    _idToken.payload["cognito:groups"].includes("exo-admin")
  );
};

export default { getUserInfo, isAdmin };
