import React, { useEffect } from "react";
import _ from "lodash";
import Plot from "react-plotly.js";
import SignalProcessing from "./SignalProcessing";
import { fft } from "fft-js";
import GraphColors from "../colors/graph";
import ModuleText from "../uicomponent/ModuleText";
// import nj from "numjs";
// import nj2 from "./numjs2";
// import pyodide from "pyodide";
// import { useGlobal } from "../v2/context/global";
// import test from "./test.py";

const Analysis = ({ type, dataList, params, data }) => {
  const Header = () => {
    const date = data.dateTime.substring(0, 4) + "-" + data.dateTime.substring(4, 6) + "-" + data.dateTime.substring(6, 8);
    const time = data.dateTime.substring(8, 10) + ":" + data.dateTime.substring(10, 12) + ":" + data.dateTime.substring(12, 14);
    const progressTime = parseInt(data.progressTime / 60) + ":" + (data.progressTime % 60 >= 10 ? data.progressTime % 60 : ("0" + data.progressTime % 60));
    return (
      <>
        <table style={{ width: "100%", marginBottom: 20 }}>
          <tbody>
            <tr>
              <th style={{ textAlign: "center", width: 150 }}>Date Time</th>
              <td style={{ textAlign: "center", width: "calc(50% - 150px)" }}>{date + " " + time}</td>
              <th style={{ textAlign: "center", width: 150, left: "50%" }}>Duration</th>
              <td style={{ textAlign: "center" }}>{progressTime}</td>
            </tr>
            <tr>
              <th style={{ textAlign: "center", width: 150 }}>Memo</th>
              <td colSpan={3} style={{ textAlign: "left", paddingLeft: 10, paddingRight: 10, whiteSpace: "pre" }}>{data.memo}</td>
            </tr>
          </tbody>
        </table>
        {/* <table style={{ width: "100%", marginBottom: 20 }}>
          <thead style={{ border: "1px solid" }}>
            <tr>
              <th style={{ textAlign: "center", width: 150 }}>Module No.</th>
              <th style={{ textAlign: "center", width: 150 }}>Side</th>
              <th style={{ textAlign: "center" }}>Area</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map(({ moduleNum, side, area }, i) => {
              return (
                <tr key={i}>
                  <td style={{ textAlign: "center" }}>{moduleNum}</td>
                  <td style={{ textAlign: "center" }}>{side.charAt(0).toUpperCase() + side.slice(1)}</td>
                  <td style={{ textAlign: "left", paddingLeft: 10 }}>{area}</td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
      </>
    );
  };

  const Content = () => {
    switch (type) {
      case "Amplitude Probability":
        return (
          <AmplitudeProbability dataList={dataList}/>
        );
      case "Average Activation":
        return (
          <>
            {dataList.map(({ moduleNum, emg }, i) => {
              return (
                <div key={i}>
                  <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
                  <AverageActivation moduleNum={moduleNum} data={emg} params={params[moduleNum]}/>
                </div>
              );
            })}
          </>
        );
      case "Frequency Fatigue":
        return (
          <FrequencyFatigue dataList={dataList}/>
        );
      case "Isometric Contraction Analysis":
        const rmsMvic = SignalProcessing.Smoothing.RMS(params["mvicData"], 50);
        const points = 100;
        const mvicMeanList = [];
        for(let i = 0; i < points + 1; i++) {
          const point = params["mvic"].map(([start, end]) => {
            const length = (end - start) * 1000;
            return _.mean(rmsMvic.slice(start * 1000 + _.floor((length * i / (points + 1))), start * 1000 + _.ceil((length * (i + 1) / (points + 1)))));
          });
          const mean = _.mean(point);
          mvicMeanList.push(mean);
        }
        return (
          <IsometricContractionAnalysis dataList={dataList} params={params} mvicMeanList={mvicMeanList}/>
        );
      case "Power Spectrum":
        return (
          <>
            {dataList.map(({ moduleNum, emg }, i) => {
              return (
                <div key={i}>
                  <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
                  <PowerSpectrum moduleNum={moduleNum} data={emg}/>
                </div>
              );
            })}
          </>
        );
      case "Standard EMG Analysis":
        return (
          <StandardEMGAnalysis dataList={dataList} params={params}/>
        );
      case "Standard Timing Analysis":
        if(params.start === null) {
          params.start = 0;
        }
        return (
          <StandardTimingAnalysis dataList={dataList} params={params}/>
        );
      default:
        return <div/>;
    }
  }
  return (
    <div>
      <Header/>
      <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
        <h1>{type}</h1>
      </div>
      <Content/>
    </div>
  );
};

const DataTable = ({ head, style, data }) => {
  const headKeys = Object.keys(head);
  const dataKeys = Object.keys(data);

  return (
    <table style={style}>
      <thead style={{ border: "1px solid" }}>
        <tr>
          <th style={{ textAlign: "center", width: 150 }}>Module No.</th>
          {headKeys.map((v, i) => <th key={i} style={{ textAlign: "center" }}>{head[v].name}</th>)}
        </tr>
      </thead>
      <tbody>
        {dataKeys.map((v, i) => {
          return (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{v}</td>
              {headKeys.map((key, j) => {
                let value = data[v][key];
                if(isNaN(value)) {
                  value = "N/A";
                }
                else if(head[key].round !== undefined) {
                  value = _.round(value, head[key].round);
                }
                return (
                  <td key={j} style={{ textAlign: "right", paddingRight: 3 }}>{value}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const AmplitudeProbability = ({ dataList }) => {
  const dataMap = {};

  return (
    <div>
      {dataList.map(({ moduleNum, emg }, i) => {
        const temp = {}, barMap = {};
        const rmsData = SignalProcessing.Smoothing.RMS(emg, 50);
        
        rmsData.map((v) => {
          const floor = _.floor(v, -1);
          if(temp[floor] !== undefined) {
            temp[floor] += 1;
          }
          else {
            temp[floor] = 1;
          }
        });
      
        let sum = 0;
        for(let i = 0; i < 12; i++) {
          const first = temp[i * 20] ? temp[i * 20] : 0;
          const second = temp[i * 20 + 10] ? temp[i * 20 + 10] : 0;
          const val = (first + second) / rmsData.length * 100;
          barMap[i * 20] = val;
          sum += val;
        }
        barMap[240] = 100 - sum;

        const mean = _.mean(rmsData);

        dataMap[moduleNum] = {
          mean: mean,
          peak: _.max(rmsData),
          area: mean * rmsData.length / 1000
        };

        return (
          <div key={i}>
            <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
            <Plot
              data={[
                {
                  x: Object.keys(barMap),
                  y: Object.values(barMap),
                  showlegend: false,
                  hoverinfo: "none",
                  type: "bar",
                  marker: {
                    color: GraphColors.bar[moduleNum]
                  }
                }
              ]}
              layout={{
                xaxis: {
                  fixedrange: true,
                  tickvals: _.range(0, 260, 20),
                  ticktext: _.range(0, 260, 20).map((v) => {
                    if(barMap[v] === undefined) {
                      return v;
                    }
                    if(v === 240) {
                      return `${_.round(barMap[240], 2)}<br>240+`;
                    }
                    return `${_.round(barMap[v], 2)}<br>${v}`;
                  }),
                  title: "Amplitude [uV]"
                },
                yaxis: {
                  fixedrange: true,
                  title: "Probability [%]"
                },
                margin: {
                  t: 20,
                  l: 50,
                  r: 20,
                  b: 60
                }
              }}
              useResizeHandler={true}
              onError={(err) => console.log(err)}
              config={{ responsive: true, displayModeBar: false }}
              style={{ width: "100%", height: 250 }}
            />
          </div>
        );
      })}
      <div style={{ marginTop: 20 }}>
        <h6>Amplitude Statistics</h6>
        <DataTable head={{ mean: { name: "Mean [uV]", round: 2 }, peak: { name: "Peak [uV]", round: 0 }, area: { name: "Area [uV*s]", round: 2 } }}
          data={dataMap} style={{ width: "100%", marginTop: 10 }}/>
      </div>
    
    </div>
  );
};

const AverageActivation = ({ moduleNum, data, params }) => {
  const rmsData = SignalProcessing.Smoothing.RMS(data, 50);
  const points = 100;
  const turnPoint = _.round(points * _.mean(params.map(([start, turn, end]) => (turn - start) / (end - start))));
  const meanList1 = [], meanList2 = [], sdList = [];
  for(let i = 0; i < turnPoint; i++) {
    const point = params.map(([start, turn, end]) => {
      const length = (turn - start) * 1000;
      return _.mean(rmsData.slice(start * 1000 + _.floor((length * i / turnPoint)), start * 1000 + _.ceil((length * (i + 1) / turnPoint))));
    });
    const mean = _.mean(point);
    meanList1.push(mean);
    sdList.push(Math.sqrt(_.mean(point.map((v) => Math.pow(v - mean, 2)))));
  }
  for(let i = 0; i < points + 1 - turnPoint; i++) {
    const point = params.map(([start, turn, end]) => {
      const length = (end - turn) * 1000;
      return _.mean(rmsData.slice(turn * 1000 + _.floor((length * i / (points + 1 - turnPoint))), turn * 1000 + _.ceil((length * (i + 1) / (points + 1 - turnPoint)))));
    });
    const mean = _.mean(point);
    meanList2.push(mean);
    sdList.push(Math.sqrt(_.mean(point.map((v) => Math.pow(v - mean, 2)))));
  }
  const mean1 = _.mean(meanList1), mean2 = _.mean(meanList2)
  let peak1 = _.max(meanList1), peak2 = _.max(meanList2);
  if(peak1 === undefined) {
    peak1 = NaN;
  }
  if(peak2 === undefined) {
    peak2 = NaN;
  }
  const max = _.max(meanList1.concat(meanList2).map((v, i) => v + sdList[i]));
  const isInvalid = meanList1.length === 0;

  return (
    <div style={{ display: "flex" }}>
      <Plot
        data={[
          {
            x: _.range(points + 1).map((v) => v / points * 100),
            y: meanList1.concat(meanList2).map((v, i) => v - sdList[i]),
            showlegend: false,
            hoverinfo: "none",
            line: {
              color: GraphColors.line[moduleNum],
              width: 0
            }
          },
          {
            x: _.range(points + 1).map((v) => v / points * 100),
            y: meanList1.concat(meanList2).map((v, i) => v + sdList[i]),
            showlegend: false,
            hoverinfo: "none",
            line: {
              color: GraphColors.line[moduleNum],
              width: 0
            },
            fill: "tonexty"
          },
          {
            x: _.range(points + 1).map((v) => v / points * 100),
            y: meanList1.concat(meanList2),
            showlegend: false,
            hoverinfo: "none",
            line: {
              color: GraphColors.line[moduleNum]
            },
          },
        ]}
        layout={{
          xaxis: {
            fixedrange: true,
            range: [0, 100],
            title: "Action Progress [%]"
          },
          yaxis: {
            fixedrange: true,
            range: isInvalid ? [0, 1] : [0, max * 1.05],
            tickvals: isInvalid ? [0] : undefined,
            title: "RMS [uV]"
          },
          margin: {
            t: 20,
            l: 50,
            r: 20,
            b: 40
          },
          shapes: isInvalid ? [] : [
            {
              type: "line",
              x0: turnPoint / points * 100, x1: turnPoint / points * 100, y0: max * 0.1, y1: max * 1.05,
              line: {
                color: "black",
                width: 1,
                dash: "solid"
              },
              layer: "above",
            },
            {
              type: "rect",
              x0: 0, x1: turnPoint / points * 100, y0: 0, y1: max * 0.1,
              line: {
                width: 0
              },
              fillcolor: GraphColors.section[0],
              opacity: 0.5,
              layer: "below"
            },
            {
              type: "rect",
              x0: 100, x1: turnPoint / points * 100, y0: 0, y1: max * 0.1,
              line: {
                width: 0
              },
              fillcolor: GraphColors.section[1],
              opacity: 0.5,
              layer: "below"
            }
          ],
          annotations: isInvalid ? [] : [
            {
              text: _.round(turnPoint / points * 100, 2),
              x: turnPoint / points * 100,
              y: max * 0.1,
              yshift: -8,
              showarrow: false
            }
          ]
        }}
        useResizeHandler={true}
        onError={(err) => console.log(err)}
        config={{ responsive: true, displayModeBar: false }}
        style={{ width: "100%", height: 250 }}
      />
      <Plot
        data={[
          {
            y: [mean1, mean2],
            hoverinfo: "none",
            type: "bar",
            marker: {
              color: GraphColors.section
            }
          }
        ]}
        layout={{
          xaxis: {
            fixedrange: true,
            tickvals: [0, 1],
            ticktext: [mean1.toFixed(2), mean2.toFixed(2)]
          },
          yaxis: {
            fixedrange: true,
            range: isInvalid ? [0, 1] : undefined,
            tickvals: isInvalid ? [0] : undefined,
            title: "RMS [uV]"
          },
          margin: {
            t: 40,
            l: 50,
            r: 20,
            b: 20
          },
          title: "Mean"
        }}
        useResizeHandler={true}
        onError={(err) => console.log(err)}
        config={{ responsive: true, displayModeBar: false }}
        style={{ width: 350, height: 250 }}
      />
      <Plot
        data={[
          {
            y: [peak1, peak2],
            hoverinfo: "none",
            type: "bar",
            marker: {
              color: GraphColors.section
            }
          }
        ]}
        layout={{
          xaxis: {
            fixedrange: true,
            tickvals: [0, 1],
            ticktext: [peak1.toFixed(2), peak2.toFixed(2)]
          },
          yaxis: {
            fixedrange: true,
            range: isInvalid ? [0, 1] : undefined,
            tickvals: isInvalid ? [0] : undefined,
            title: "RMS [uV]"
          },
          margin: {
            t: 40,
            l: 50,
            r: 20,
            b: 20
          },
          title: "Peak"
        }}
        useResizeHandler={true}
        onError={(err) => console.log(err)}
        config={{ responsive: true, displayModeBar: false }}
        style={{ width: 350, height: 250 }}
      />
    </div>
  );
};

const FrequencyFatigue = ({ dataList }) => {
  const windowSize = 1024;
  const intervalSize = 1000;
  const mnfMap = {}, mdfMap = {};

  dataList.forEach(({ moduleNum, emg: data }) => {
    const fullWindows = parseInt(data.length / intervalSize);
    const mnf = [], mdf = [];
    let fftList;

    for(let i = 0; i < fullWindows; i++) {
      fftList = fft(data.slice(i * intervalSize, (i + 1) * intervalSize).concat(new Array(windowSize - intervalSize).fill(0)));
      const temp = fftList.map((val, i) => {
        if(i === 0) {
          return 0;
        }
        return Math.pow(val[0], 2) + Math.pow(val[1], 2);
      }).filter((v, i) => i < windowSize / 2);
      let productSum = 0, sum = 0;
      const cd = [];
      temp.forEach((v, i) => {
        const frequency = i / windowSize * 1000;
        productSum += v * frequency;
        sum += v;
        cd.push(sum);
      })
      for(let i = 0; i < cd.length; i++) {
        if(cd[i] >= sum / 2) {
          mdf.push(i / windowSize * 1000);
          break;
        }
      }
      mnf.push(productSum / sum);
    }
    // if(data.length > fullWindows * intervalSize) {
    //   fftList = fft(data.slice(fullWindows * intervalSize, data.length).concat(new Array(fullWindows * intervalSize + windowSize - data.length).fill(0)));
    //   const temp = fftList.map((val, i) => {
    //     if(i === 0) {
    //       return 0;
    //     }
    //     return Math.pow(val[0], 2) + Math.pow(val[1], 2);
    //   }).filter((v, i) => i < windowSize / 2);
    //   let productSum = 0, sum = 0;
    //   const cd = [];
    //   temp.forEach((v, i) => {
    //     const frequency = i / windowSize * 1000;
    //     productSum += v * frequency;
    //     sum += v;
    //     cd.push(sum);
    //   })
    //   for(let i = 0; i < cd.length; i++) {
    //     if(cd[i] >= sum / 2) {
    //       mdf.push(i / windowSize * 1000);
    //       break;
    //     }
    //   }
    //   mnf.push(productSum / sum);
    // }

    mnfMap[moduleNum] = {
      data: mnf,
      ...linearRegression(mnf)
    }
    mdfMap[moduleNum] = {
      data: mdf,
      ...linearRegression(mdf)
    }
  });

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "50%", paddingRight: 20 }}>
        <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
          <h3>Mean Frequency</h3>
        </div>
        {dataList.map(({ moduleNum }, i) => {
          const { data, slope, intercept } = mnfMap[moduleNum];
          return (
            <div key={i}>
              <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
              <Plot
                data={[
                  {
                    y: data,
                    showlegend: false,
                    hoverinfo: "none",
                    mode: "lines+markers",
                    line: {
                      color: GraphColors.line[moduleNum]
                    }
                  }
                ]}
                layout={{
                  xaxis: {
                    fixedrange: true,
                    range: [0, data.length],
                    title: "Time [s]"
                  },
                  yaxis: {
                    fixedrange: true,
                    title: "Frequency [Hz]"
                  },
                  margin: {
                    t: 20,
                    l: 50,
                    r: 20,
                    b: 40
                  },
                  shapes: [
                    {
                      type: "line",
                      x0: 0, x1: data.length, y0: intercept, y1: data.length * slope + intercept,
                      line: {
                        color: "black",
                        width: 1,
                        dash: "solid"
                      },
                      layer: "above"
                    }
                  ],
                  
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
            </div>
          );
        })}
        <div style={{ marginTop: 20 }}>
          <h6>Statistics for Mean Frequency</h6>
          <DataTable head={{ slope: { name: "Slope [Hz/s]", round: 4 }, intercept: { name: "Intercept [Hz]", round: 2 } }}
            data={mnfMap} style={{ width: "100%", marginTop: 10 }}/>
        </div>
      </div>
      <div style={{ width: "50%", paddingLeft: 20 }}>
        <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
          <h3>Median Frequency</h3>
        </div>
        {dataList.map(({ moduleNum }, i) => {
          const { data, slope, intercept } = mdfMap[moduleNum];
          return (
            <div key={i}>
              <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
              <Plot
                data={[
                  {
                    y: data,
                    showlegend: false,
                    hoverinfo: "none",
                    mode: "lines+markers",
                    line: {
                      color: GraphColors.line[moduleNum]
                    }
                  }
                ]}
                layout={{
                  xaxis: {
                    fixedrange: true,
                    range: [0, data.length],
                    title: "Time [s]"
                  },
                  yaxis: {
                    fixedrange: true,
                    title: "Frequency [Hz]"
                  },
                  margin: {
                    t: 20,
                    l: 50,
                    r: 20,
                    b: 40
                  },
                  shapes: [
                    {
                      type: "line",
                      x0: 0, x1: data.length, y0: intercept, y1: data.length * slope + intercept,
                      line: {
                        color: "black",
                        width: 1,
                        dash: "solid"
                      },
                      layer: "above"
                    }
                  ]
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
            </div>
          );
        })}
        <div style={{ marginTop: 20 }}>
          <h6>Statistics for Median Frequency</h6>
          <DataTable head={{ slope: { name: "Slope [Hz/s]", round: 4 }, intercept: { name: "Intercept [Hz]", round: 2 } }}
            data={mdfMap} style={{ width: "100%", marginTop: 10 }}/>
        </div>
      </div>
    </div>
  );
};

const IsometricContractionAnalysis = ({ dataList, params, mvicMeanList }) => {
  const mvicPercent = {};
  const mvicMax = _.max(mvicMeanList);
  const mvicMean = _.mean(mvicMeanList);
  return (
    <>
      {dataList.map(({ moduleNum, emg }, index) => {
        const moduleParams = params[moduleNum];
        const rmsData = SignalProcessing.Smoothing.RMS(emg, 50);
        const points = 100;
        const meanList = [], sdList = [];
        for(let i = 0; i < points + 1; i++) {
          const point = moduleParams.map(([start, end]) => {
            const length = (end - start) * 1000;
            return _.mean(rmsData.slice(start * 1000 + _.floor((length * i / (points + 1))), start * 1000 + _.ceil((length * (i + 1) / (points + 1)))));
          });
          const mean = _.mean(point);
          meanList.push(mean);
          sdList.push(Math.sqrt(_.mean(point.map((v) => Math.pow(v - mean, 2)))));
        }
        const max = _.max(meanList);
        const mean = _.mean(meanList);
        mvicPercent[moduleNum] = {
          mean,
          mvic: mean / mvicMean * 100
        };
        const isInvalid = meanList.length === 0;

        return (
          <div key={index}>
            <div style={{ display: "flex" }}>
              <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
              <div style={{ display: "flex", height: 20, marginLeft: 20 }}>
                <div style={{ marginRight: 5, fontSize: 20, marginTop: -7, color: "#078150" }}>&#x25cf;</div>
                {`MVIC`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <Plot
                data={[
                  {
                    x: _.range(points + 1).map((v) => v / points * 100),
                    y: meanList.map((v, i) => v - sdList[i]),
                    showlegend: false,
                    hoverinfo: "none",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 0
                    }
                  },
                  {
                    x: _.range(points + 1).map((v) => v / points * 100),
                    y: meanList.map((v, i) => v + sdList[i]),
                    showlegend: false,
                    hoverinfo: "none",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 0
                    },
                    fill: "tonexty"
                  },
                  {
                    x: _.range(points + 1).map((v) => v / points * 100),
                    y: meanList,
                    showlegend: false,
                    hoverinfo: "none",
                    line: {
                      color: GraphColors.line[moduleNum]
                    },
                  },
                  {
                    x: _.range(points + 1).map((v) => v / points * 100),
                    y: mvicMeanList,
                    showlegend: false,
                    hoverinfo: "none",
                    line: {
                      color: "#078150"
                    },
                  },
                ]}
                layout={{
                  xaxis: {
                    fixedrange: true,
                    range: [0, 100],
                    title: "Action Progress [%]"
                  },
                  yaxis: {
                    fixedrange: true,
                    range: isInvalid ? [0, 1] : [0, _.max([max, mvicMax]) * 1.05],
                    tickvals: isInvalid ? [0] : undefined,
                    title: "RMS [uV]"
                  },
                  margin: {
                    t: 20,
                    l: 50,
                    r: 20,
                    b: 40
                  },
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
              <Plot
                data={[
                  {
                    y: [mean, mvicMean],
                    hoverinfo: "none",
                    type: "bar",
                    marker: {
                      color: GraphColors.section
                    }
                  }
                ]}
                layout={{
                  xaxis: {
                    fixedrange: true,
                    tickvals: [0, 1],
                    ticktext: [`${mean.toFixed(2)}<br>Module ${moduleNum}`, `${mvicMean.toFixed(2)}<br>MVIC`]
                  },
                  yaxis: {
                    fixedrange: true,
                    range: isInvalid ? [0, 1] : undefined,
                    tickvals: isInvalid ? [0] : undefined,
                    title: "RMS [uV]"
                  },
                  margin: {
                    t: 40,
                    l: 50,
                    r: 20,
                    b: 40
                  },
                  // title: "Mean"
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: 350, height: 250 }}
              />
            </div>
          </div>
        );
      })}
      <div style={{ marginTop: 20 }}>
        <h6>Statistics for Isometric Contraction</h6>
        <DataTable head={{ mean: { name: "Mean Isometric Contraction [uV]", round: 4 }, mvic: { name: "%MVIC [%]", round: 4 } }} data={mvicPercent} style={{ width: "100%", marginTop: 10 }}/>
      </div>
    </>
  );
};

const PowerSpectrum = ({ moduleNum, data }) => {
  const windowSize = 4096;
  const fullWindows = parseInt(data.length / windowSize);
  let psd = new Array(windowSize / 2).fill(0);
  let fftList, dataSlice, mean;
  
  for(let i = 0; i < fullWindows; i++) {
    dataSlice = data.slice(i * windowSize, (i + 1) * windowSize);
    mean = _.mean(dataSlice);
    dataSlice = dataSlice.map((v) => v - mean);
    fftList = fft(dataSlice);
    const temp = fftList.map((val, i) => {
      if(i === 0) {
        return 0;
      }
      return Math.sqrt(Math.pow(val[0], 2) + Math.pow(val[1], 2)) / 1000;
    }).filter((v, i) => i < windowSize / 2);
    psd.map((v, i) => psd[i] += temp[i]);
  }
  if(data.length > fullWindows * windowSize) {
    dataSlice = data.slice(fullWindows * windowSize, data.length).concat(new Array((fullWindows + 1) * windowSize - data.length).fill(0));
    // mean = _.mean(dataSlice);
    // dataSlice = dataSlice.map((v) => v - mean);
    fftList = fft(dataSlice);
    const temp = fftList.map((val, i) => {
      if(i === 0) {
        return 0;
      }
      return Math.sqrt(Math.pow(val[0], 2) + Math.pow(val[1], 2)) / 1000;
    }).filter((v, i) => i < windowSize / 2);
    psd.map((v, i) => psd[i] = (v + temp[i]) / (fullWindows + 1));
  }
  else {
    psd.map((v, i) => psd[i] /= fullWindows);
  }

  return (
    <Plot
      data={[
        {
          x: _.range(0, windowSize / 2).map((val) => val / windowSize * 1000),
          y: psd,
          showlegend: false,
          hoverinfo: "none",
          line: {
            color: GraphColors.line[moduleNum],
            width: 1
          }
        }
      ]}
      layout={{
        xaxis: {
          fixedrange: true,
          title: "Frequency [Hz]"
        },
        yaxis: {
          fixedrange: true,
          title: "[uV*s]"
        },
        margin: {
          t: 20,
          l: 50,
          r: 20,
          b: 40
        }
      }}
      useResizeHandler={true}
      onError={(err) => console.log(err)}
      config={{ responsive: true, displayModeBar: false }}
      style={{ width: "100%", height: 250 }}
    />
  );
};

const StandardEMGAnalysis = ({ dataList, params: { slices, referenceModuleNum } }) => {
  const newList = [];
  const meanMap = {}, peakMap = {};
  console.log(referenceModuleNum);
  
  dataList.forEach(({ moduleNum, emg }) => {
    const rms = SignalProcessing.Smoothing.RMS(emg, 50);
    const sliceList = slices[moduleNum];

    // for(let i = 0; i < parseInt(params[moduleNum].length / 2); i++) {
    //   sliceList.push([params[moduleNum][i * 2], params[moduleNum][i * 2 + 1]]);
    // }
    newList.push({ moduleNum, rms, sliceList });

    let list = sliceList.map((v) => _.mean(rms.slice(v[0] * 1000, v[1] * 1000 + 1)));
    let mean = _.mean(list);
    meanMap[moduleNum] = {
      list,
      mean,
      sd: Math.sqrt(_.mean(list.map((v) => Math.pow(v - mean, 2))))
    }
    list = sliceList.map((v) => _.max(rms.slice(v[0] * 1000, v[1] * 1000 + 1)));
    mean = _.mean(list);
    peakMap[moduleNum] = {
      list,
      mean,
      sd: Math.sqrt(_.mean(list.map((v) => Math.pow(v - mean, 2))))
    }
  });

  dataList.forEach(({ moduleNum }) => {
    meanMap[moduleNum].ratio = meanMap[moduleNum].mean / meanMap[referenceModuleNum].mean;
    peakMap[moduleNum].ratio = peakMap[moduleNum].mean / peakMap[referenceModuleNum].mean;
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
        <h3>RMS</h3>
      </div>
      {newList.map(({ moduleNum, rms, sliceList }, i) => {
        const max = _.max(rms);
        const shapes = [];
        sliceList.forEach((v) => {
          shapes.push({
            type: "rect",
            x0: v[0], x1: v[1], y0: 0, y1: max * 1.05,
            line: {
              width: 0
            },
            fillcolor: "#ffff0088",
            layer: "below"
          },
          {
            type: "line",
            x0: v[0], x1: v[0], y0: 0, y1: max * 1.05,
            line: {
              width: 1
            },
            layer: "above"
          },
          {
            type: "line",
            x0: v[1], x1: v[1], y0: 0, y1: max * 1.05,
            line: {
              width: 1
            },
            layer: "above"
          });
        })

        return (
          <div key={i}>
            <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
            <Plot
              data={[
                {
                  x: _.range(rms.length).map((v) => v / 1000),
                  y: rms,
                  showlegend: false,
                  hoverinfo: "none",
                  line: {
                    color: GraphColors.line[moduleNum],
                    width: 1
                  }
                }
              ]}
              layout={{
                xaxis: {
                  fixedrange: true,
                  range: [0, rms.length / 1000],
                  title: "Time [s]"
                },
                yaxis: {
                  fixedrange: true,
                  range: [0, max * 1.05],
                  title: "RMS [uV]"
                },
                margin: {
                  t: 20,
                  l: 50,
                  r: 40,
                  b: 40
                },
                shapes
              }}
              useResizeHandler={true}
              onError={(err) => console.log(err)}
              config={{ responsive: true, displayModeBar: false }}
              style={{ width: "100%", height: 250 }}
            />
          </div>
        );
      })}
      <div style={{ display: "flex", marginTop: 30 }}>
        <div style={{ width: "50%", paddingRight: 20 }}>
          <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
            <h3>Mean of Each Section</h3>
          </div>
          {newList.map(({ moduleNum }, i) => {
            const meanList = meanMap[moduleNum].list;
            return (
              <div key={i}>
                <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
                <Plot
                  data={[
                    {
                      y: meanList,
                      showlegend: false,
                      hoverinfo: "none",
                      type: "bar",
                      marker: {
                        color: GraphColors.bar[moduleNum]
                      }
                    }
                  ]}
                  layout={{
                    xaxis: {
                      fixedrange: true,
                      tickvals: _.range(meanList.length),
                      ticktext: _.range(meanList.length).map((v) => `${meanList[v].toFixed(2)}<br>${v + 1}`)
                    },
                    yaxis: {
                      fixedrange: true,
                      range: meanList.length === 0 ? [0, 1] : undefined,
                      tickvals: meanList.length === 0 ? [0] : undefined,
                      title: "Mean [uV]"
                    },
                    margin: {
                      t: 20,
                      l: 50,
                      r: 40,
                      b: 40
                    },
                    annotations: [
                      {
                        x: 0,
                        y: 0,
                        xref: "paper",
                        yref: "paper",
                        xanchor: "right",
                        yshift: -33,
                        showarrow: false,
                        align: "left",
                        text: "No."
                      }
                    ]
                  }}
                  useResizeHandler={true}
                  onError={(err) => console.log(err)}
                  config={{ responsive: true, displayModeBar: false }}
                  style={{ width: "100%", height: 250 }}
                />
              </div>
            );
          })}
          <div style={{ marginTop: 20 }}>
            <h6>Averaged Mean of All Sections</h6>
            <DataTable head={{ mean: { name: "Mean [uV]", round: 2 }, sd: { name: "Standard Deviation [uV]", round: 2 }, ratio: { name: "Ratio", round: 2 } }}
              data={meanMap} style={{ width: "100%", marginTop: 10 }}/>
          </div>
        </div>
        <div style={{ width: "50%", paddingLeft: 20 }}>
          <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
            <h3>Peak of Each Section</h3>
          </div>
          {newList.map(({ moduleNum }, i) => {
            const peakList = peakMap[moduleNum].list;
            return (
              <div key={i}>
                <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
                <Plot
                  data={[
                    {
                      y: peakList,
                      showlegend: false,
                      hoverinfo: "none",
                      type: "bar",
                      marker: {
                        color: GraphColors.bar[moduleNum]
                      }
                    }
                  ]}
                  layout={{
                    xaxis: {
                      fixedrange: true,
                      tickvals: _.range(peakList.length),
                      ticktext: _.range(peakList.length).map((v) => `${peakList[v].toFixed(2)}<br>${v + 1}`)
                    },
                    yaxis: {
                      fixedrange: true,
                      range: peakList.length === 0 ? [0, 1] : undefined,
                      tickvals: peakList.length === 0 ? [0] : undefined,
                      title: "Peak [uV]"
                    },
                    margin: {
                      t: 20,
                      l: 50,
                      r: 40,
                      b: 40
                    },
                    annotations: [
                      {
                        x: 0,
                        y: 0,
                        xref: "paper",
                        yref: "paper",
                        xanchor: "right",
                        yshift: -33,
                        showarrow: false,
                        align: "left",
                        text: "No."
                      }
                    ]
                  }}
                  useResizeHandler={true}
                  onError={(err) => console.log(err)}
                  config={{ responsive: true, displayModeBar: false }}
                  style={{ width: "100%", height: 250 }}
                />
              </div>
            );
          })}
          <div style={{ marginTop: 20 }}>
            <h6>Averaged Peak of All Sections</h6>
            <DataTable head={{ mean: { name: "Mean [uV]", round: 2 }, sd: { name: "Standard Deviation [uV]", round: 2 }, ratio: { name: "Ratio", round: 2 } }}
              data={peakMap} style={{ width: "100%", marginTop: 10 }}/>
          </div>
        </div>
      </div>
    </div>
  );
};

const StandardTimingAnalysis = ({ dataList, params: { start, referenceModuleNum } }) => {
  const onsetMap = {};
  return (
    <>
      {dataList.map(({ moduleNum, emg }, key) => {
        const rmsData = SignalProcessing.Smoothing.RMS(emg, 50);
        const searchWindowSize = 1000;
        const sdNum = 3;
        let mean = _.mean(rmsData.slice(0, searchWindowSize));
        let minIndex = 0;
        let minMean = mean;
        for(let i = 1; i < rmsData.length - searchWindowSize + 1; i++) {
          mean = mean + (rmsData[i + searchWindowSize - 1] - rmsData[i - 1]) / searchWindowSize;
          if(mean < minMean) {
            minIndex = i;
            minMean = mean;
          }
        }
        const sd = Math.sqrt(_.sum(rmsData.slice(minIndex, minIndex + searchWindowSize).map((v) => Math.pow(v - minMean, 2))) / searchWindowSize);
        const threshold = minMean + sd * sdNum;
        const yResult = rmsData.map((v) => v >= threshold);
        const sliceList = [];
        const suppressionSize = 200;
        let index = 0;
        let bool = yResult[index];
        for(let i = 1; i < yResult.length; i++) {
          if(yResult[i] !== bool) {
            if(i - index <= suppressionSize) {
              for(let j = index; j < i; j++) {
                yResult[j] = !yResult[j];
              }
            }
            else {
              index = i;
            }
            bool = yResult[i];
          }
        }
        if(yResult.length - index <= suppressionSize) {
          for(let j = index; j < yResult.length; j++) {
            yResult[j] = !yResult[j];
          }
        }

        let temp = yResult.findIndex((v) => v);
        if(temp !== -1) {
          for(let i = 1; i < yResult.length - 1; i++) {
            if(yResult[i] !== yResult[i - 1]) {
              if(yResult[i]) {
                temp = i;
              }
              else {
                sliceList.push([temp / 1000, (i - 1) / 1000]);
              }
            }
          }
          if(yResult[yResult.length - 1]) {
            sliceList.push([temp / 1000, (yResult.length - 1) / 1000]);
          }
        }

        const firstSlice = sliceList.find((v) => v[0] >= start);
        const onset = firstSlice ? firstSlice[0] : NaN;
        
        onsetMap[moduleNum] = {
          onset
        };

        const max = _.max(rmsData);
        const shapes = [
          {
            type: "line",
            x0: start, x1: start, y0: 0, y1: max * 1.05,
            line: {
              width: 3,
              color: "red"
            },
            layer: "above"
          }
        ];
        if(firstSlice) {
          shapes.push({
            type: "rect",
            x0: firstSlice[0], x1: firstSlice[1], y0: 0, y1: max * 1.05,
            line: {
              width: 0
            },
            fillcolor: "#ffff0088",
            layer: "below"
          },
          {
            type: "line",
            x0: firstSlice[0], x1: firstSlice[0], y0: 0, y1: max * 1.05,
            line: {
              width: 1
            },
            layer: "above"
          },
          {
            type: "line",
            x0: firstSlice[1], x1: firstSlice[1], y0: 0, y1: max * 1.05,
            line: {
              width: 1
            },
            layer: "above"
          });
        }

        return (
          <div key={key}>
            <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
            <div style={{ display: "flex" }}>
              <Plot
                data={[
                  {
                    x: _.range(rmsData.length).map((v) => v / 1000),
                    y: rmsData,
                    showlegend: false,
                    hoverinfo: "none",
                    line: {
                      color: GraphColors.line[moduleNum],
                      width: 1
                    }
                  },
                  
                ]}
                layout={{
                  xaxis: {
                    fixedrange: true,
                    range: [0, rmsData.length / 1000],
                    title: "Time [s]"
                  },
                  yaxis: {
                    fixedrange: true,
                    title: "RMS [uV]"
                  },
                  margin: {
                    t: 20,
                    l: 50,
                    r: 20,
                    b: 40
                  },
                  shapes
                }}
                useResizeHandler={true}
                onError={(err) => console.log(err)}
                config={{ responsive: true, displayModeBar: false }}
                style={{ width: "100%", height: 250 }}
              />
            </div>
          </div>
        );
      })}
      <div style={{ marginTop: 20 }}>
        <h6>Onset Time</h6>
        <DataTable head={{ onset: { name: "Onset Time [s]" }, difference: { name: "Time Difference [s]" } }}
          data={(() => {
            dataList.forEach(({ moduleNum }) =>{
              const difference = onsetMap[moduleNum].onset - onsetMap[referenceModuleNum].onset;
              onsetMap[moduleNum].difference = (difference >= 0 ? "+" : "") + difference.toFixed(3);
            });
            return onsetMap;
          })()} style={{ width: "100%", marginTop: 10 }}/>
      </div>
    </>
  );
  // const py = useGlobal().pyodide;
  // useEffect(() => {
  //   async function loadPython() {
  //     py.globals.set("x", py.toPy([1, 2, 3]));
  //     const scriptText = await(await fetch(test)).text();
  //     await py.runPythonAsync(scriptText);
  //     const a = await py.runPythonAsync(`
  //       import numpy as np
  //       print(np.ones((3, 3)))
  //       data = np.array(x)
  //       data
  //     `);
  //     await py.runPythonAsync(`
  //       print(x)
  //     `);
  //     console.log(a.toJs());
  //   }
  //   loadPython();
  // }, [dataList]);
  // const newList = [];
  
  // const fs = 1000;
  // const highcut = 70;
  // const nyq = 0.5 * fs;
  // const order = 2;
  // const segment = 30 * fs;
  // const frameL = 160;
  // const shift = parseInt(frameL * 0.20);
  // dataList.forEach(({ moduleNum, emg }) => {
  //   let sig = nj.array(emg);
  //   sig = sig.subtract(nj.mean(sig));

  //   const buf = nj.zeros(frameL);
  //   const bufPre = nj.zeros(frameL);
    
  //   let thre = 0;
  //   let data = [];
  //   let sign = [];
  //   for(let seg = 0; seg < parseInt(Math.ceil(sig.size / segment)); seg++) {
  //     const bufSeg = sig.slice(seg * segment, (seg + 1) * segment);
  //     const frameT = parseInt(bufSeg.size / shift);

  //     const energy = nj.zeros(frameT);
  //     for(let frm = 0; frm < frameT; frm++) {
  //       buf.slice(-shift).assign(bufSeg.slice([frm * shift, (frm + 1) * shift]), false);
  //       energy.slice([frm, frm + 1]).assign(nj.abs(buf).sum(), false);
  //       buf.slice([-shift]).assign(buf.slice(shift), false);
  //     }
  //     const nBins = 15;
  //     const [n, bins] = nj2.histogram(energy, nBins);
  //     const indMax = nj2.argmax(n);
  //     const indMin = nj2.argmin(nj.abs(n.multiply(-1).add(n.get(indMax) * 0.5)));
      
  //     if(seg > 0) {
  //       thre = (thre + bins.get(indMin)) / 2
  //     }
  //     else {
  //       thre = bins.get(indMin);
  //     }

  //     let onDetect = energy.tolist().map((v) => v > thre ? 1 : 0);
  //     let indOn = [];
  //     for(let i = 0; i < onDetect.length; i++) {
  //       if(onDetect[i] === 1) {
  //         indOn.push(i);
  //       }
  //     }
  //     indOn = nj.array(indOn);
  //     const diff = indOn.slice(1).subtract(indOn.slice([-1])).tolist();
  //     onDetect = nj.array(onDetect);
  //     indOn = indOn.tolist();
  //     const indDiff = [];
  //     for(let i = 0; i < diff.length; i++) {
  //       if(diff[i] > 1 && diff[i] <= 16) {
  //         onDetect.slice([indOn[i] + 1, indOn[i] + 1 + diff[i]]).assign(1, false);
  //       }
  //     }
  //     onDetect = onDetect.tolist();
  //     let stp, edp;
  //     for(let i = 0; i < onDetect.length - 1; i++) {
  //       if(onDetect[i] === 0 && onDetect[i + 1] === 1) {
  //         for(let j = i; j > -1; j--) {
  //           stp = j;
  //           edp = j - 20;
  //           if(edp < 0) {
  //             edp = 0;
  //           }
  //           if((energy.get(stp) - energy.get(edp)) / energy.get(stp) > 0.20) {
  //             onDetect[j] = 1;
  //           }
  //           else {
  //             break;
  //           }
  //         }
  //       }
  //       if(onDetect[i] === 1 && onDetect[i + 1] === 0) {
  //         for(let j = i + 1; j > -1; j--) {
  //           stp = j;
  //           edp = j + 100;
  //           if(edp > onDetect.length - 1) {
  //             edp = onDetect.length - 1;
  //           }
  //           if((energy.get(stp) - energy.get(edp)) / energy.get(stp) > 0.20) {
  //             onDetect[j] = 1;
  //           }
  //           else {
  //             break;
  //           }
  //         }
  //       }
  //     }
  //     data.push(...onDetect);
  //   }
  //   newList.push({ moduleNum, emg, data })
  // });

  // return (
  //   <div>
  //     <img id="fig"/>
  //     {/* {newList.map(({ moduleNum, emg, section, sliceList }, i) => {
  //       const max = _.max(emg.map((v) => Math.abs(v)));
  //       const shapes = [];
  //       if(sliceList.length > 0) {
  //         timeMap[moduleNum] = {
  //           onset: sliceList[0][0] / 1000,
  //           offset: sliceList[0][1] / 1000
  //         };
  //       }
  //       else {
  //         timeMap[moduleNum] = {
  //           onset: NaN,
  //           offset: NaN
  //         };
  //       }
  //       sliceList.forEach((v) => {
  //         shapes.push({
  //           type: "rect",
  //           x0: v[0] / 1000, x1: v[1] / 1000, y0: -max * 1.05, y1: max * 1.05,
  //           line: {
  //             width: 0
  //           },
  //           fillcolor: "#ffff0088",
  //           layer: "below"
  //         },
  //         {
  //           type: "line",
  //           x0: v[0] / 1000, x1: v[0] / 1000, y0: -max * 1.05, y1: max * 1.05,
  //           line: {
  //             width: 1
  //           },
  //           layer: "above"
  //         },
  //         {
  //           type: "line",
  //           x0: v[1] / 1000, x1: v[1] / 1000, y0: -max * 1.05, y1: max * 1.05,
  //           line: {
  //             width: 1
  //           },
  //           layer: "above"
  //         });
  //       });

  //       return (
  //         <div key={i}>
  //           <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
  //           <Plot
  //             data={[
  //               {
  //                 x: _.range(emg.length).map((v) => v / 1000 + section[0]),
  //                 y: emg,
  //                 showlegend: false,
  //                 hoverinfo: "none",
  //                 line: {
  //                   color: GraphColors.line[moduleNum],
  //                   width: 1
  //                 }
  //               },
  //             ]}
  //             layout={{
  //               xaxis: {
  //                 fixedrange: true,
  //                 range: section,
  //                 title: "Time [s]"
  //               },
  //               yaxis: {
  //                 fixedrange: true,
  //                 title: "EMG [uV]"
  //               },
  //               margin: {
  //                 t: 20,
  //                 l: 50,
  //                 r: 40,
  //                 b: 40
  //               },
  //               shapes
  //             }}
  //             useResizeHandler={true}
  //             onError={(err) => console.log(err)}
  //             config={{ responsive: true, displayModeBar: false }}
  //             style={{ width: "100%", height: 250 }}
  //           />
  //         </div>
  //       );
  //     })}
  //     <div style={{ marginTop: 20 }}>
  //       <h6>Onset, Offset Time</h6>
  //       <DataTable head={{ onset: { name: "Onset Time [s]" }, offset: { name: "Offset Time [s]" } }}
  //         data={timeMap} style={{ width: "100%", marginTop: 10 }}/>
  //     </div> */}
  //   </div>
  // );
};

// const StandardTimingAnalysis = ({ dataList, params }) => {
//   const newList = [];
//   const windowSize = 50;
//   const offMin = 500;
//   const onMin = 300;
  
//   function getSd(emg) {
//     let sdList = [];
//     let mean;
//     // mean = _.mean(emg);
//     // console.log(Math.sqrt(_.mean(emg.map((v) => Math.pow(v - mean, 2)))));
//     if(emg.length <= windowSize) {
//       mean = _.mean(emg);
//       sdList.push(Math.sqrt(_.mean(emg.map((v) => Math.pow(v - mean, 2)))));
//     }
//     else {
//       let sum = _.sum(emg.slice(0, windowSize));
//       let mean = sum / windowSize;
//       let varianceSum = _.sum(emg.slice(0, windowSize).map((v) => Math.pow(v - mean, 2)));
//       sdList.push(Math.sqrt(varianceSum / windowSize));
//       let a, b;
//       for(let i = windowSize; i < emg.length; i++) {
//         a = emg[i];
//         b = emg[i - windowSize];
//         sum += a - b;
//         mean = sum / windowSize;
//         varianceSum += (a + b - mean * 2 + (a - b) / windowSize) * (a - b);
//         sdList.push(Math.sqrt(varianceSum / windowSize));
//       }
//     }
//     sdList = sdList.map((v) => isNaN(v) ? 0 : v);
//     return sdList.concat(new Array(windowSize - 1).fill(sdList[sdList.length - 1]));
//   }
  
//   dataList.forEach(({ moduleNum, emg }) => {
//     const test = SignalProcessing.Smoothing.RMS(SignalProcessing.Derivative(SignalProcessing.Smoothing.RMS(emg, 50)), 50);
//     let sdList = getSd(SignalProcessing.Filtering.Median(emg, 3));
//     // let sdList = getSd(emg);
//     const tempList = getSd(sdList);
//     // const threshold = _.mean(sdList.filter((v, i) => tempList[i] < 2)) * 3;
//     const threshold = 2.5;
//     let sliceList = [];
//     let temp = [0, 0];
//     let flag = false;
//     tempList.forEach((sd, i) => {
//       if(sd >= threshold) {
//         if(flag) {
//           temp[1] = i;
//         }
//         else {
//           temp[0] = i;
//           temp[1] = i;
//           flag = true;
//         }
//       }
//       else if(flag) {
//         if(temp[0] === 0) {
//           temp[1] += emg.length <= windowSize ? emg.length - 1 : (windowSize - 1);
//           sliceList.push(temp.slice());
//         }
//         else {
//           sliceList.push(temp.map((v) => v + windowSize - 1));
//         }
//         flag = false;
//       }
//     });
//     if(flag) {
//       if(temp[0] === 0) {
//         temp[1] += emg.length <= windowSize ? emg.length - 1 : (windowSize - 1);
//         sliceList.push(temp.slice());
//       }
//       else {
//         sliceList.push(temp.map((v) => v + windowSize - 1));
//       }
//       flag = false;
//     }
//     // sliceList = sliceList.filter((v) => v[1] - v[0] >= offMin / preCoeff);
//     // sliceList = sliceList.filter((v) => v[1] - v[0] >= 50);
//     sliceList = sliceList.filter((v) => v[1] - v[0] >= 50);
//     let i = 1;
//     while(i < sliceList.length) {
//       if(sliceList[i][0] - sliceList[i - 1][1] < 200) {
//         sliceList[i - 1][1] = sliceList[i][1];
//         sliceList.splice(i, 1);
//       }
//       else {
//         i++;
//       }
//     }
//     sliceList = sliceList.filter((v) => v[1] - v[0] >= 300);
//     // sliceList = sliceList.filter((v) => {
//     //   let v0 = sdList[v[0]], v1 = sdList[v[1] - 1];
//     //   if(v1 === undefined) {
//     //     v1 = sdList[sdList.length - 1];
//     //   }
//     //   let min, max;
//     //   if(v0 > v1) {
//     //     min = v1;
//     //     max = v0;
//     //   }
//     //   else {
//     //     min = v0;
//     //     max = v1;
//     //   }
//     //   console.log(v[0], v[1], min, max)
//     //   return max - min <= 30; 
//     // });

//     const testList = sliceList.map((v, i) => {
//       if(i < sliceList.length - 1) {
//         return [[v[0], sliceList[i + 1][0]], _.max(sdList.slice(v[0], v[1]))];
//       }
//       return [[v[0], sdList.length], _.max(sdList.slice(v[0], v[1]))];
//     });
//     let list = [];
//     testList.forEach((v) => {
//       temp = [v[0][0], v[0][0]];
//       flag = false;
//       for(let i = v[0][0]; i < v[0][1]; i++) {
//         if(sdList[i] > v[1]) {
//           if(flag) {
//             temp[1] = i;
//           }
//           else {
//             temp[0] = i;
//             temp[1] = i;
//             flag = true;
//           }
//         }
//         else if(flag) {
//           list.push(temp.slice());
//           flag = false;
//         }
//       }
//       if(flag) {
//         list.push(temp.slice());
//         flag = false;
//       }
//     });
//     list = list.filter((v) => v[1] - v[0] >= 100);

//     // i = 1;
//     // while(i < sliceList.length) {
//     //   if(sliceList[i][0] - sliceList[i - 1][1] < onMin) {
//     //     sliceList[i - 1][1] = sliceList[i][1];
//     //     sliceList.splice(i, 1);
//     //   }
//     //   else {
//     //     i++;
//     //   }
//     // }
//     // sdList = sdList.map((v) => isNaN(v) ? 0 : v);
//     newList.push({ moduleNum, emg, section: params[moduleNum], sliceList, sdList, threshold, test, tempList, list });
//   });
//   const timeMap = {};

//   return (
//     <div>
//       {/* <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
//         <h3>RMS</h3>
//       </div> */}
//       {newList.map(({ moduleNum, emg, section, sliceList, sdList, tempList, list }, i) => {
//         const max = _.max(emg.map((v) => Math.abs(v)));
//         const shapes = [];
//         if(sliceList.length > 0) {
//           timeMap[moduleNum] = {
//             onset: sliceList[0][0] / 1000,
//             offset: sliceList[0][1] / 1000
//           };
//         }
//         else {
//           timeMap[moduleNum] = {
//             onset: NaN,
//             offset: NaN
//           };
//         }
//         // shapes.push({
//         //   type: "line",
//         //   x0: 0, x1: 20000, y0: sd, y1: sd,
//         //   line: {
//         //     width: 1
//         //   },
//         //   layer: "above"
//         // });
//         // if(sliceList.length === 0) {
//         //   shapes.push({
//         //     type: "rect",
//         //     x0: 0, x1: (emg.length - 1) / 1000, y0: -max * 1.05, y1: max * 1.05,
//         //     line: {
//         //       width: 0
//         //     },
//         //     fillcolor: "#ffff0088",
//         //     layer: "below"
//         //   },
//         //   {
//         //     type: "line",
//         //     x0: 0, x1: 0, y0: -max * 1.05, y1: max * 1.05,
//         //     line: {
//         //       width: 1
//         //     },
//         //     layer: "above"
//         //   },
//         //   {
//         //     type: "line",
//         //     x0: (emg.length - 1) / 1000, x1: (emg.length - 1) / 1000, y0: -max * 1.05, y1: max * 1.05,
//         //     line: {
//         //       width: 1
//         //     },
//         //     layer: "above"
//         //   });
//         // }
//         sliceList.forEach((v) => {
//           shapes.push({
//             type: "rect",
//             x0: v[0] / 1000, x1: v[1] / 1000, y0: -max * 1.05, y1: max * 1.05,
//             line: {
//               width: 0
//             },
//             fillcolor: "#ffff0088",
//             layer: "below"
//           },
//           {
//             type: "line",
//             x0: v[0] / 1000, x1: v[0] / 1000, y0: -max * 1.05, y1: max * 1.05,
//             line: {
//               width: 1
//             },
//             layer: "above"
//           },
//           {
//             type: "line",
//             x0: v[1] / 1000, x1: v[1] / 1000, y0: -max * 1.05, y1: max * 1.05,
//             line: {
//               width: 1
//             },
//             layer: "above"
//           });
//         });

//         return (
//           <div key={i}>
//             <ModuleText moduleNum={moduleNum} style={{ marginLeft: 50 }}/>
//             <Plot
//               data={[
//                 {
//                   x: _.range(emg.length).map((v) => v / 1000 + section[0]),
//                   y: emg,
//                   showlegend: false,
//                   hoverinfo: "none",
//                   line: {
//                     color: GraphColors.line[moduleNum],
//                     width: 1
//                   }
//                 },
//                 // {
//                 //   x: _.range(sdList.length).map((v) => v / 1000 + section[0]),
//                 //   y: sdList.map((v) => v * 2),
//                 //   showlegend: false,
//                 //   hoverinfo: "y",
//                 //   line: {
//                 //     color: "blue",
//                 //     width: 1
//                 //   }
//                 // },
//                 // {
//                 //   x: _.range(tempList.length).map((v) => v / 1000 + section[0]),
//                 //   y: tempList.map((v) => v >= 3 ? 500 : 0),
//                 //   showlegend: false,
//                 //   hoverinfo: "y",
//                 //   line: {
//                 //     color: "black",
//                 //     width: 1
//                 //   }
//                 // }
//               ]}
//               layout={{
//                 xaxis: {
//                   fixedrange: true,
//                   range: section,
//                   title: "Time [s]"
//                 },
//                 yaxis: {
//                   fixedrange: true,
//                   // range: [0, max * 1.05],
//                   title: "EMG [uV]"
//                 },
//                 margin: {
//                   t: 20,
//                   l: 50,
//                   r: 40,
//                   b: 40
//                 },
//                 shapes
//               }}
//               useResizeHandler={true}
//               onError={(err) => console.log(err)}
//               config={{ responsive: true, displayModeBar: false }}
//               style={{ width: "100%", height: 250 }}
//             />
//           </div>
//         );
//       })}
//       <div style={{ marginTop: 20 }}>
//         <h6>Onset, Offset Time</h6>
//         <DataTable head={{ onset: { name: "Onset Time [s]" }, offset: { name: "Offset Time [s]" } }}
//           data={timeMap} style={{ width: "100%", marginTop: 10 }}/>
//       </div>
//     </div>
//   );
// };

function linearRegression(y) {
  const x = _.range(y.length);
  const meanX = _.mean(x), meanY = _.mean(y);
  const XX = _.sum(x.map((v) => Math.pow(v - meanX, 2))), XY = _.sum(y.map((v, i) => (i - meanX) * (v - meanY)));
  const slope = XY / XX;
  const intercept = meanY - slope * meanX;
  return { slope, intercept };
}

function getOnsetSlice(emgData) {
  const count = emgData.length;
  const frameSize = 64;
  const kPoint = parseInt(frameSize / 2);

  var I = [];
  const arrLength = parseInt(count / parseInt(frameSize / 2));
  var G = [];
  var psi = [];
  var tau = new Array(arrLength).fill(0);
  var dec = [];
  
  // GET I
  for(let i = 0; i < parseInt(count - frameSize); i += parseInt(frameSize / 2)) {
      const T1 = getFFT(emgData, i, frameSize);
      // T1.forEach((element, index) => {
      //     T1[index] = Math.pow(element, 2);
      // });
      I.push(T1.map((element) => {return Math.pow(element, 2)}));
  }
  for(let i = I.length; i < arrLength; i++) {
    I.push(new Array(parseInt(frameSize / 2)).fill(0));
  }

  // GET G
  for(let i = 0; i < arrLength; i++) {
      var tempGProduct = 1;
      for(let j = 0; j < kPoint; j++) {
          tempGProduct *= I[i][j] + 1;
      }
      G.push(Math.pow(tempGProduct, 1 / kPoint));
  }

  // GET psi
  for(let i = 0; i < arrLength; i++) {
      psi.push(Math.log(G[i]));
  }

  // GET tau
  const iMovingWindow = 2;
  for(let i = iMovingWindow; i < arrLength - 1; i++) {
      tau[i + 1] = -Math.log(Math.exp(-tau[i] + (Math.exp(-psi[i]) - Math.exp(-psi[i - iMovingWindow])) / iMovingWindow));
      if(tau[i + 1] < 0) {
          tau[i + 1] = 0;
      }
  }

  const dePsi = psi.slice().sort();
  const LdePsireSize = parseInt(dePsi.length / 100) < 1 ? 1 : parseInt(dePsi.length / 100);

  var tempMax = 0;
  var tempMin = 0;
  for(let i = 0; i < LdePsireSize; i++) {
      tempMin += dePsi[i];
      tempMax += dePsi[dePsi.length - i - 1];
  }
  tempMax /= LdePsireSize;
  tempMin /= LdePsireSize;

  for(let i = 0; i < arrLength; i++) {
      tau[i] = (tempMax + tempMin) / 2 - tau[i];
  }
  
  // GET dec
  for(let i = 0; i < parseInt(arrLength * frameSize / 2); i++) {
      const temp = parseInt(i / (frameSize / 2));
      dec.push(psi[temp] > tau[temp] ? 1 : 0);
  }
  
  var min = 0;
  var sum = 0;
  var avg = 0;
  for(let i = 0; i < dec.length; i++) {
    if(dec[i] === 0) {
      min = i;
      break;
    }
  }
  for(let i = min; i < dec.length; i++) {
    sum += emgData[i];
    if(dec[i] === 1) {
      avg = sum / (i - min);
      break;
    }
  }

  var emgSlice = [];
  var flag = false;
  var index = 0;
  for(let i = 0; i < dec.length; i++) {
      if(dec[i] === 1 && !flag) {
          flag = true;
          index = i;
      }
      else if(dec[i] === 0 && flag) {
          flag = false;
          // console.log(index, i);
          emgSlice.push([index, i]);
      }
  }
  if(flag) {
      emgSlice.push([index, dec.length]);
  }

  for(let i = 0; i < emgSlice.length - 1; i++) {
    if(emgSlice[i + 1][0] - emgSlice[i][1] < 256) {
      emgSlice[i][1] = emgSlice[i + 1][1];
      emgSlice.splice(i + 1, 1);
      i--;
    }
  }
  emgSlice = emgSlice.filter((slice) => slice[1] - slice[0] >= 128);

  // return [emgData, emgSlice, avg];
  return emgSlice;
}

function getFFT(array, start, FFTSize) {
  const cutArray = array.slice(start, start + FFTSize);
  const cutLength = cutArray.length;
  var yfft = fft(cutArray);
  yfft = yfft.slice(0, parseInt(cutLength / 2));
  yfft[0] = 0
  for(let i = 1; i < yfft.length; i++) {
      yfft[i] = Math.sqrt(Math.pow(yfft[i][0] / cutLength, 2) + Math.pow(yfft[i][1] / cutLength, 2)) * 2;
  }
  return yfft;
}

export default Analysis;