import React from "react";
import {
  CardText,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Container
} from "reactstrap";
import * as d3 from "d3";

import MidCard from "../component/MidCard";
import restapi from "../util/restapi";

export default class TrendFatigue extends React.Component {
  state = {
    loading: false,
    trend: []
  };
  term = "d";

  componentDidUpdate(prevProps) {
    if (
      this.props.user_id != null &&
      (prevProps.user_id !== this.props.user_id ||
        prevProps.type_sub !== this.props.type_sub ||
        prevProps.term !== this.props.term ||
        prevProps.offset !== this.props.offset)
    )
      this.fetch();
    else this.draw();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.props.user_id && this.fetch();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => this.draw();

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let trend = [];
      const body = {
        user_id: this.props.user_id,
        behavior: "fatigue",
        term: this.props.term,
        offset: this.props.offset,
        windowSize: this.props.windowSize,
        type: this.props.type
      };

      this.props.type_sub && (body.type_sub = this.props.type_sub);

      try {
        const res = await restapi.patchTrend(body);

        if (res && res.result) {
          if (this.props.term === "s") {
            res.detail.sort((a, b) => {
              if (a.time_st_utc > b.time_st_utc) return 1;
              if (a.time_st_utc < b.time_st_utc) return -1;
              return 0;
            });
          }

          trend = res.detail;
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({ trend, loading: false }, () => {
        this.draw();
      });
    });
  };

  draw = () => {
    const svg = d3.select("svg#trendFatigue");
    svg.selectAll("*").remove();

    if (this.props.user_id === null || this.state.loading === true) return;

    const [trend, term] = [this.state.trend, this.props.term];

    if (trend === null || !Array.isArray(trend)) return;

    let { width, height } = svg.node().getBoundingClientRect();
    const [offsetWidth, offsetHeight] = [40, 20];
    height -= offsetHeight * 2;
    width -= offsetWidth * 2;

    let fn;

    switch (term) {
      case "w":
        fn = d => `${d.yow}`;
        break;
      case "m":
        fn = d => `${d.y}/${d.m}`;
        break;
      case "s":
        fn = d => {
          const dt = new Date(d.time_st_utc);
          return `${dt.getMonth() + 1}/${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`;
        };
        break;
      default:
        fn = d => `${d.m}/${d.d}`;
        break;
    }
    trend[0] && console.log(new Date(trend[4].time_st_utc))
    const xScale = d3
      .scaleBand()
      .domain(trend.map(fn))
      .range([0, width])
      .padding(0.1);
    const yScale = d3
      .scaleLinear()
      .domain([-50, 50])
      .range([height, 0]);
    const g = svg
      .append("g")
      .attr("transform", `translate(${offsetWidth}, ${offsetHeight})`);
    const xAxis = d3.axisBottom(xScale).tickSize(-height);

    g.append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);
    g.append("g")
      .attr("class", "grid")
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5)
          .tickSize(-width)
      );

    const line = d3
      .line()
      .x(d => xScale(fn(d)) + xScale.bandwidth() / 2)
      .y(d => yScale(d.f));

    g.append("path")
      .datum(trend)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 0.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("class", "line")
      .attr("d", line);
    g.append("g")
      .selectAll("circle")
      .data(trend)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", d => xScale(fn(d)) + xScale.bandwidth() / 2)
      .attr("cy", d => yScale(d.f))
      .attr("r", 3)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 1)
      .exit();
    g.append("g")
      .selectAll("text")
      .data(trend)
      .enter()
      .append("text")
      .text(d => d.f.toFixed(2))
      .attr("x", d => xScale(fn(d)) + xScale.bandwidth() / 2)
      .attr("y", d => yScale(d.f))
      .exit();
  };

  render() {
    let body = null;

    if (this.props.user_id === null) body = <Label>select user</Label>;
    else {
      if (!this.state.loading) {
        body = (
          <svg
            className="graph"
            id="trendFatigue"
            key={new Date().getTime()}
            height={this.height}
            width="100%"
          />
        );
      }
    }

    return (
      <Container>
        <Label>Fatigue</Label>
        {body}
      </Container>
    );
  }
}
