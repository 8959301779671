import React, { Component } from "react";
import { TrendRehab, TrendFatigue } from "./index";
import {
  CardText,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Container
} from "reactstrap";
import MidCard from "../component/MidCard";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";

const arrTypeSub = [
  { value: "", text: "All" },
  { value: "rehab_q_set", text: "Q-set" },
  { value: "rehab_q_set_fes", text: "Q-set (with Electrical Stimulation)" },
  { value: "rehab_terminal_ext", text: "Short Arc Quad" },
  { value: "rehab_slr_open", text: "SLR (With Straight Knee)" },
  { value: "rehab_slr_close", text: "SLR (With Bent Knee)" },
  { value: "rehab_knee_squat", text: "Partial Squat with Chair" },
  { value: "rehab_heel_lift", text: "Heel Raise" },
  { value: "rehab_ankle_pump", text: "Ankle Pump" }
];

export default class TrendRehabAll extends Component {
  state = {
    type_sub: arrTypeSub[0].value
  };

  handleSelectTypeSub = event => {
    this.setState({ type_sub: event.target.value });
  };

  render = () => {
    const { user_id, term, offset } = this.props;
    const { type_sub } = this.state;

    return (
      <MidCard
        title="Rehab"
        icon={<AccessibilityNewIcon fontSize="large" color="primary" />}
      >
          <Container>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Training</InputGroupText>
              </InputGroupAddon>
              <Input
                value={type_sub}
                type="select"
                name="select"
                id="type_detail"
                onChange={this.handleSelectTypeSub}
              >
                {arrTypeSub.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </Input>
            </InputGroup>
          </Container>
        {term !== "s" && <>
          <TrendRehab
            user_id={user_id}
            type_sub={type_sub}
            windowSize={10}
            term={term}
            offset={offset}
          />
        </>}
        <TrendFatigue
          user_id={user_id}
          type="rehab"
          type_sub={type_sub}
          windowSize={5}
          term={term}
          offset={offset}
        />
      </MidCard>
    );
  };
}
