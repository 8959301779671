import React, { useState } from "react";
import { CardTitle, CardImg, Button } from "reactstrap";
import {
  Form,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from "reactstrap";

import Spinner from "../uicomponent/Spinner";
import springapi from "../util/springapi";
import User from "../v2/User";

function V2SignIn({ style, user, setState, expand, slide }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function v2SignIn() {
    const phone = document.getElementById("phone").value;
    const password = document.getElementById("v2Password").value;
    if(phone.trim() === "") {
      setError("Invalid phone number");
      document.getElementById("phone").select();
      return;
    }
    if(password === "") {
      setError("Invalid password");
      document.getElementById("v2Password").focus();
      return;
    }
    setLoading(true);
    setError(null);
    if(isNaN(phone.charAt(0))){
      if(phone === "admin") {
        let body = {
          key: phone,
          password
        };
        const res = await springapi.request("POST", "/v2/user/adminLogin", body);
        if(res && res.body.verified) {
          const id = res.body.id;
          User.signIn(id, res.body.sessionId);
          setState({ v2User: id, isAdmin: true });
          window.location = "/v2";
          return;
        }
      }
      else {
        let body = {
          id: phone,
          password
        };
        const res = await springapi.request("POST", "/v2/user/groupLogin", body);
        if(res && res.response.matches) {
          User.groupSignIn(res.response.groupId, res.response.token);
          window.location = "/v2";
          return;
        }
      }
    }
    else {
      let body = {
        phone,
        password
      }
      const res = await springapi.request("POST", "/v2/user/webLogin", body);
      if(res && res.body.matches) {
        const id = res.body.user.user_id;
        User.signIn(id, res.body.sessionId);
        setState({ v2User: id })
        window.location = "/v2";
        return;
      }
    }
    setError("Incorrect credentials");
    setLoading(false);
  };

  // function getDerivedStateFromProps(props, state) {
  //   if(state.expand !== props.expand){
  //     return {
  //       expand: props.expand
  //     }
  //   }
  //   return null;
  // }

  async function handleEnter(event) {
    if(!loading) {
      event.key === "Enter" && expand === "exoFit" && await v2SignIn();
    }
  };

  // function changeState(state) {
  //   this.setState({ error: null }, () => {
  //     super.changeState(state);
  //   });
  // };

  async function checkUser() {
    if(user) {
      window.location = "/v2";
    }
    else {
      try {
        await User.getId().then((v2User) => {
            if(v2User) {
              window.location = "/v2";
            }
          }
        );
      }
      catch(e) {
        console.log(e);
      }
    }
  }

  return (
    <div className="exoFit" style={style}>
      <img width="100%" src="/resource/exopill_logo.png" onClick={() => {checkUser(); setState({ expand: "exoFit" }); slide && slide();}}
        className={["img", expand !== "exoFit" && "border"].join(" ")}/>
      <div style={{ height: 10 }} />
      <Form className={["form", expand !== "exoFit" && "form-hidden"].join(" ")}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Phone</InputGroupText>
          </InputGroupAddon>
          <Input
            id="phone"
            disabled={loading}
            name="phoneNum"
            onKeyPress={() => document.getElementById("v2Password").select()}
            type="text"
            placeholder="01000000000"
          />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Password</InputGroupText>
          </InputGroupAddon>
          <Input
            id="v2Password"
            disabled={loading}
            name="password"
            onKeyPress={async (e) => await handleEnter(e)}
            type="password"
            placeholder="your password"
          />
        </InputGroup>
        <div style={{ display: "flex" }}>
          {error &&
            <div style={{ display: "flex", alignItems: "center", color: "red" }}>{error}</div>
          }
          <div style={{ flex: "1 0 0" }}/>
          <Button style={{ width: 60 }} disabled={loading} onClick={async () => await v2SignIn()}>
            {loading ?
              <Spinner visible={true}/>
            :
              "Login"
            }
          </Button>
        </div>
        {/* <img src="resource/kakao_login_medium_narrow.png" className="login" style={{ height: "55px" }} onClick={() => {window.location.href=`https://kauth.kakao.com/oauth/authorize?client_id=f64511a30075dffecc91a4b8effdd632&redirect_uri=${window.location.origin}/kakao&response_type=code`;}}/>
        <img src="resource/naver_login.png" className="login"/> */}
      </Form>
    </div>
  );
}

export default V2SignIn;