import { createContext, useContext } from "react";

export const GlobalContext = createContext({
    print: null,
    setPrint: () => {},
    mapOpened: false,
    setMapOpened: () => {},
    pyodide: null,
    setPyodide: () => {},
    paramsTemp: null,
    setParamsTemp: () => {}
});

export const useGlobal = () => useContext(GlobalContext);