import React, { useEffect, useState } from "react";

const TabSelector = (props) => {
    const { tabs, fitWidth, isLoading, preLoad, onTabChange } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const [style, setStyle] = useState({});

    useEffect(() => {
        const style = {
            borderWidth: "1px",
            borderStyle: "solid",
            borderTopColor: "gray",
            borderLeftColor: "gray",
            borderRightColor: "gray",
            marginRight: -1,
            marginBottom: -1
        }
        if(fitWidth) {
            style["width"] = 100 / tabs.length + "%";
            style.marginRight = 0;
        }
        setStyle(style);
    }, []);

    useEffect(() => {
        onTabChange && onTabChange(currentTab);
    }, [currentTab]);

    return (
        <div style={{ ...props.style, display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", borderBottom: "1px solid gray", zIndex: 2 }}>
                {tabs.map((tab, i) => {
                    return (
                        <button key={i} onClick={() => setCurrentTab(i)} style={i === currentTab ? { ...style, borderBottomColor: "white", boxShadow: "none", outline: "none", backgroundColor: "white", zIndex: 3 } : { ...style, borderBottomColor: "gray"}}>
                            <div style={{ height: 30, display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 20, paddingRight: 20 }}><div/>{tab.icon}<div>{tab.name}</div></div>
                        </button>
                    );
                })}
            </div>
            <div style={{ marginTop: -1, flexGrow: 1, zIndex: 1, border: "1px solid gray" }}>
                {preLoad && tabs.map((tab, i) => {
                    return (
                        <div key={i} style={{ display: i === currentTab && !isLoading ? "block" : "none" }}>
                            {tab.component}
                        </div>
                    )
                })}
                {!preLoad && tabs[currentTab].component}
            </div>
        </div>
    );
};

export default TabSelector;