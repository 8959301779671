import React from "react";
import d3js from "../util/d3js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default class BarGraph extends React.Component {
  state = {
    modal: false,
    opened: false
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleOpen = () => this.setState({ opened: !this.state.opened });

  render = () => {
    return (
      <>
        <BarGraphPrivate graphId={this.props.graphId} data={this.props.data} />
        <Button onClick={this.toggle}>Detail</Button>
        <Modal
          className="modal-full"
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={this.toggleOpen}
          onOpened={this.toggleOpen}
          centered
        >
          <ModalHeader toggle={this.toggle}>Detail view</ModalHeader>
          <ModalBody>
            <BarGraphPrivate
              graphId={`${this.props.graphId}detail`}
              data={this.state.opened && this.props.data}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };
}

class BarGraphPrivate extends React.Component {
  componentDidUpdate = (prevProps, prevState) => {
    prevProps.data !== this.props.data && this.draw();
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
    this.props.data && this.draw();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => this.draw();

  draw = () => {
    d3js.barGraph(`svg#${this.props.graphId}`, this.props.data);
  };

  render = () => {
    return <svg className="graph" id={this.props.graphId} width="100%" />;
  };
}
