import React, { Component } from "react";
import {
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Alert
} from "reactstrap";
import { Form, FormGroup, Label } from "reactstrap";
import MidCard from "./MidCard";
import restapi from "../util/restapi";
import auth from "../util/auth";
import util from "../util/util";

class AuthInfo extends Component {
  _isMount = false;

  state = {
    loading: false,
    isEdit: false
  };

  fetch = () => {
    if (!this._isMount) return;
    if (this.state.loading) return;

    this.setState({ loading: true }, async () => {
      let user;

      try {
        const { user_id } = await auth.getUserInfo();
        const result = await restapi.getUserDetail(user_id);

        if (result.result) {
          user = result.detail;
          user.birthdate = util.convDt2SimpleDate(user.birthdate);
        }
      } catch (error) {
        console.error(error);
      }

      this.setState({ loading: false, user });
    });
  };

  handleChange = e => {
    const { user } = this.state;

    user[e.target.name] = e.target.value.trim();
    this.setState({ user });
  };

  handleRadio = e => {
    const { user } = this.state;
    user.gender = e.target.name;

    this.setState({ user });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true, isEdit: false }, async () => {
      try {
        await restapi.putUserInfo(this.state.user);
      } catch (error) {
        console.error(error);
      }

      this.fetch();
    });
  };

  getComponentAuthInfo = () => {
    const { user, isEdit } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>email</InputGroupText>
          </InputGroupAddon>
          <Input value={user.email} name="email" type="text" disabled />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>family_name</InputGroupText>
          </InputGroupAddon>
          <Input
            value={
              user.family_name ? user.family_name : isEdit ? "" : "(empty)"
            }
            name="family_name"
            type="text"
            disabled={!isEdit}
            onChange={this.handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>given_name</InputGroupText>
          </InputGroupAddon>
          <Input
            value={user.given_name ? user.given_name : isEdit ? "" : "(empty)"}
            name="given_name"
            type="text"
            disabled={!isEdit}
            onChange={this.handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>middle_name</InputGroupText>
          </InputGroupAddon>
          <Input
            value={
              user.middle_name ? user.middle_name : isEdit ? "" : "(empty)"
            }
            name="middle_name"
            type="text"
            disabled={!isEdit}
            onChange={this.handleChange}
          />
        </InputGroup>
        <FormGroup>
          <Label>Gender</Label>
          <FormGroup check>
            <Label check>
              <Input
                disabled={!isEdit}
                type="radio"
                name="male"
                checked={user.gender === "male"}
                onChange={this.handleRadio}
              />{" "}
              male
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                disabled={!isEdit}
                type="radio"
                name="female"
                checked={user.gender === "female"}
                onChange={this.handleRadio}
              />{" "}
              female
            </Label>
          </FormGroup>
        </FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>symptoms</InputGroupText>
          </InputGroupAddon>
          <Input
            value={user.symptoms ? user.symptoms : isEdit ? "" : "(empty)"}
            name="symptoms"
            type="text"
            disabled={!isEdit}
            onChange={this.handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>part</InputGroupText>
          </InputGroupAddon>
          <Input
            value={user.part ? user.part : isEdit ? "" : "(empty)"}
            name="part"
            type="text"
            disabled={!isEdit}
            onChange={this.handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>birthdate</InputGroupText>
          </InputGroupAddon>
          <Input
            value={user.birthdate}
            name="birthdate"
            type="text"
            disabled={!isEdit}
            onChange={this.handleChange}
          />
        </InputGroup>
        <FormGroup check>
          <Label check>
            <Input
              name="master"
              type="checkbox"
              checked={user.group && user.group.includes("master")}
              disabled
            />{" "}
            Master
          </Label>
        </FormGroup>
        {isEdit && (
          <>
            <Button type="submit">Update</Button>
            <Button color="link" onClick={this.toggle}>
              Cancel
            </Button>
          </>
        )}
        {!isEdit && (
          <Button color="link" onClick={this.toggle}>
            Edit
          </Button>
        )}
      </Form>
    );
  };

  componentDidMount() {
    this._isMount = true;
    this.fetch();
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  handleAuthEdit = () => {
    this.fetch();
  };

  toggle = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  render() {
    let content;
    if (!this.state.loading) {
      const { user } = this.state;

      if (user) {
        content = this.getComponentAuthInfo(user);
      } else content = <Alert color="warning">no data</Alert>;
    }

    return (
      <MidCard title="Userinfo" loading={this.state.loading}>
        {content}
      </MidCard>
    );
  }
}

export default AuthInfo;
