import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NotFound from "../component/NotFound";
import AuthInfo from "../component/AuthInfo";
import auth from "../util/auth";

export default class User extends Component {
  state = {
    bLogin: null
  };

  fetch = async () => {
    try {
      const ui = await auth.getUserInfo();
      this.setState({ bLogin: true });
      console.log(ui);
    } catch (error) {
      this.setState({ bLogin: false });
      // console.log(error);
    }
  };

  componentDidMount = () => {
    this.fetch();
  };

  render = () => {
    const { bLogin } = this.state;
    console.log(bLogin);

    return (
      <>
        {bLogin == null && <p>wait...</p>}
        {bLogin === true && (
          <Switch>
            <Route path="/v1/my" component={AuthInfo} />
            <Route component={NotFound} />
          </Switch>
        )}
        {bLogin === false && <Redirect to="/" />}
      </>
    );
  };
}
