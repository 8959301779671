import React from "react";
import GraphColors from "../colors/graph";

const ModuleText = ({ moduleNum, style }) => {
  return (
    <div style={{ display: "flex", height: 20, ...style }}>
      <div style={{ marginRight: 5, fontSize: 20, marginTop: -7, color: GraphColors.line[moduleNum] }}>&#x25cf;</div>
      {`Module ${moduleNum}`}
    </div>
  );
}

export default ModuleText;