import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import { CardTitle, Button } from "reactstrap";
import { InputGroup, InputGroupText, InputGroupAddon, Input } from "reactstrap";
import { Alert } from "reactstrap";
import TopSpacer from "./../component/TopSpacer";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";
import MidCard from "../component/MidCard";
import util from "../util/util";

import config from "../config.json";

export default class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAutoStates = ["forgotPassword"];

    this.state = this.initState();
  }

  initState = () => {
    return {
      process: "submit",
      email: "",
      birthdate: "",
      code: "",
      pw1: "",
      pw2: "",
      error: null,
      modal: false
    };
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  changeProcess = (proc, err = null) => {
    this.setState({ process: proc, error: err });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };

  handleForgotPassword = async () => {
    const { email, birthdate } = this.state;
    const reqHeader = new Headers({
      "Content-Type": "application/json"
    });
    const req = new Request(
      `${config.baseUrl}/${config.stage}/forgotpassword`,
      {
        method: "PATCH",
        headers: reqHeader,
        body: JSON.stringify({
          email: email.trim(),
          birthdate: birthdate.trim()
        })
      }
    );
    const response = await fetch(req);

    if (response.ok) {
      const data = await response.json();

      if (data.result) {
        this.toggle();
        this.changeProcess("confirm");
      } else this.setState({ error: "Couldn't find your account" });
    }
  };

  handleConfirmPassword = async () => {
    const { email, pw1, pw2, code } = this.state;

    if (pw1 !== pw2) {
      this.setState({ error: "pw different" });
      return;
    }

    if (!util.validPw(pw1)) {
      this.setState({ error: "pw policy error" });
      return;
    }

    const reqHeader = new Headers({
      "Content-Type": "application/json"
    });
    const req = new Request(
      `${config.baseUrl}/${config.stage}/forgotpassword`,
      {
        method: "PUT",
        headers: reqHeader,
        body: JSON.stringify({ email: email, code: code, pw: pw1 })
      }
    );
    const response = await fetch(req);

    console.log("ok", response.ok);
    if (response.ok) {
      const res_data = await response.json();
      console.log("result", res_data);
      this.setState({
        error: res_data.result ? "success change" : "fail change"
      });
    }
  };

  showComponent(theme) {
    const { email, birthdate, error, code, pw1, pw2 } = this.state;
    const { process } = this.state;
    const btnSignin = (
      <Button
        color="link"
        onClick={() => {
          this.setState(this.initState(), () => {
            super.changeState("signIn");
          });
        }}
      >
        Sign in
      </Button>
    );
    const forms =
      process === "submit" ? (
        <>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Email</InputGroupText>
            </InputGroupAddon>
            <Input
              name="email"
              onChange={this.handleChange}
              type="text"
              value={email}
              placeholder="email@example.com"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Birthdate</InputGroupText>
            </InputGroupAddon>
            <Input
              name="birthdate"
              onChange={this.handleChange}
              type="text"
              value={birthdate}
              placeholder="1900-01-01"
            />
          </InputGroup>
          {error && <Alert color="warning">{error}</Alert>}
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            {btnSignin}
            <Button onClick={this.handleForgotPassword}>Request code</Button>
          </div>
        </>
      ) : (
        <>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>email</InputGroupText>
            </InputGroupAddon>
            <Input
              name="email"
              onChange={this.handleChange}
              type="text"
              value={email}
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>code</InputGroupText>
            </InputGroupAddon>
            <Input
              name="code"
              onChange={this.handleChange}
              type="text"
              value={code}
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>New Password</InputGroupText>
            </InputGroupAddon>
            <Input
              name="pw1"
              onChange={this.handleChange}
              type="password"
              value={pw1}
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>New Password Confirm</InputGroupText>
            </InputGroupAddon>
            <Input
              name="pw2"
              onChange={this.handleChange}
              type="password"
              value={pw2}
            />
          </InputGroup>
          {error && <Alert color="warning">{error}</Alert>}
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            {btnSignin}
            <Button onClick={this.handleConfirmPassword}>
              change password
            </Button>
          </div>
        </>
      );

    return (
      <MidCard style={{ border: "none" }}>
        <CardTitle>
          <h3>Reset Password</h3>
        </CardTitle>
        {forms}
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered>
          <ModalHeader toggle={this.toggle}>Success</ModalHeader>
          <ModalBody>
            Code for changing your password has been sent. Please check your
            email.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </MidCard>
    );
  }
}
