import * as d3 from "d3";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const NormalDistribution = (props) => {
    const exp = "1 / (Math.sqrt(2 * Math.PI) * sd) * Math.pow(Math.E, -Math.pow(i - m, 2) / (2 * Math.pow(sd, 2)))";
    const [nd, setNd] = useState({ x: [], y: [] });
    const [user, setUser] = useState({ x: 0, y: 0 });
    const [fill, setFill] = useState({ x: [], y: [] });
    const [order, setOrder] = useState([]);
    
    function getY(x) {
        let x1 = 0, x2 = 0;
        if(props.data.x.includes(x)) {
            return props.data.y[props.data.x.indexOf(x)];
        }
        for(let i = 0; i < props.data.x.length; i++) {
            if(props.data.x[i] < x) {
                x1 = props.data.x[i];
            }
            if(props.data.x[i] > x) {
                x2 = props.data.x[i];
                break;
            }
        }
        const y1 = props.data.y[props.data.x.indexOf(x1)];
        const y2 = props.data.y[props.data.x.indexOf(x2)];

        return (y2 - y1) / (x2 - x1) * x + (y1 - (y2 - y1) / (x2 - x1) * x1);
    }

    useEffect(() => {
        let fillX = [];
        let fillY = [];
        // for(let i = props.range.min; i <= props.range.max; i += props.range.tick / 200) {
        //     const value = eval(exp);
        //     x.push(i);
        //     y.push(value);
        //     if(i >= props.data.m && i <= props.data.m + props.data.sd || i <= props.data.m && i >= props.data.m + props.data.sd) {
        //         fillX.push(i);
        //         fillY.push(value);
        //     }
        // }
        // console.log(props.x[0]);
        setNd({ x: props.data.x, y: props.data.y });
        // let i = 2;
        // const sd = Math.sqrt(Math.pow(1 / d3.max(props.data.y), 2) / (2 * Math.PI));
        // const m = props.data.x[props.data.y.indexOf(d3.max(props.data.y))];
        // console.log(sd);
        // // console.log(1 / (Math.sqrt(2 * Math.PI) * sd) * Math.pow(Math.E, -Math.pow(i - m, 2) / (2 * Math.pow(sd, 2))));
        // console.log(eval(exp));
        
        // setUser({ x: props.data.userX, y: eval(exp) });
        setUser({ x: props.data.userX, y: getY(props.data.userX) })

        let x, y;
        if(props.data.userX < props.data.lines[0]) {
            x = props.data.lines[0];
            y = getY(x);
            for(let i = 0; i < props.data.x.length; i++) {
                if(props.data.x[i] < x) {
                    fillX.push(props.data.x[i]);
                    fillY.push(props.data.y[i]);
                }
                else {
                    fillX.push(x);
                    fillY.push(y);
                    break;
                }
            }
        }
        else if(props.data.userX >= props.data.lines[0] && props.data.userX < props.data.lines[1]) {
            fillX.push(props.data.lines[0]);
            fillY.push(getY(props.data.lines[0]));
            for(let i = 0; i < props.data.x.length; i++) {
                if(props.data.x[i] > props.data.lines[0] && props.data.x[i] < props.data.lines[1]) {
                    fillX.push(props.data.x[i]);
                    fillY.push(props.data.y[i]);
                }
                else if(props.data.x[i] >= props.data.lines[1]) {
                    fillX.push(props.data.lines[1]);
                    fillY.push(getY(props.data.lines[1]));
                    break;
                }
            }
        }
        else if(props.data.userX >= props.data.lines[1] && props.data.userX < props.data.lines[2]) {
            fillX.push(props.data.lines[1]);
            fillY.push(getY(props.data.lines[1]));
            for(let i = 0; i < props.data.x.length; i++) {
                if(props.data.x[i] > props.data.lines[1] && props.data.x[i] < props.data.lines[2]) {
                    fillX.push(props.data.x[i]);
                    fillY.push(props.data.y[i]);
                }
                else if(props.data.x[i] >= props.data.lines[2]) {
                    fillX.push(props.data.lines[2]);
                    fillY.push(getY(props.data.lines[2]));
                    break;
                }
            }
        }
        else {
            fillX.push(props.data.lines[2]);
            fillY.push(getY(props.data.lines[2]));
            for(let i = 0; i < props.data.x.length; i++) {
                if(props.data.x[i] > props.data.lines[2]) {
                    fillX.push(props.data.x[i]);
                    fillY.push(props.data.y[i]);
                }
            }
        }
        setFill({ x: fillX, y: fillY });
        if(props.title === "근지구력") {
            setOrder([100, 75, 50, 25]);
        }
        else {
            setOrder([25, 50, 75, 100]);
        }
    }, [props.data]);


    return (
        <Plot
            style={{ display: "inline-block" }}
            data={[
                {
                    x: fill.x,
                    y: fill.y,
                    mode: "lines",
                    name: "pdf",
                    fill: "tonexty",
                    hoverinfo: "none",
                    fillcolor: "#ffff0088",
                    opacity: 0.5
                },
                {
                    x: nd.x,
                    y: nd.y,
                    mode: "lines",
                    marker: {color: "black"},
                    name: "PDF"
                },
                {
                    x: [user.x],
                    y: [user.y],
                    mode: "markers",
                    marker: {color: "red", size: 10},
                    name: "사용자"
                }
            ]}
            layout={{
                width: 500, height: 450,
                showlegend: false,
                margin: {
                    t: 50
                },
                xaxis: {
                    // title: props.title,
                    range: props.title === "근지구력" ? [props.range.max, props.range.min] : [props.range.min, props.range.max],
                    fixedrange: true,
                    dtick: props.range.tick,
                    // automargin: true
                },
                yaxis: {
                    title: "확률밀도함수",
                    range: [0, d3.max(props.data.y) * 1.1],
                    fixedrange: true,
                    // scaleanchor: "x",
                    // scaleratio: 1,
                    // dtick: props.range[2],
                    // automargin: true
                },
                shapes: [
                    {
                        type: "line",
                        x0: props.data.lines[0], x1: props.data.lines[0], y0: 0, y1: 1,
                        line: {
                            color: "black",
                            width: 1,
                            dash: "dash"
                        }
                    },
                    {
                        type: "line",
                        x0: props.data.lines[1], x1: props.data.lines[1], y0: 0, y1: 1,
                        line: {
                            color: "black",
                            width: 1,
                            dash: "dash"
                        }
                    },
                    {
                        type: "line",
                        x0: props.data.lines[2], x1: props.data.lines[2], y0: 0, y1: 1,
                        line: {
                            color: "black",
                            width: 1,
                            dash: "dash"
                        }
                    },
                    {
                        type: "line",
                        x0: props.data.userX, x1: props.data.userX, y0: 0, y1: 1,
                        line: {
                            color: "red",
                            width: 1
                        }
                    }

                    // {
                    //     type: "circle",
                    //     xref: "x",
                    //     yref: "y",
                    //     x0: props.data.userDot[0] - props.offset,
                    //     x1: props.data.userDot[0] + props.offset,
                    //     y0: props.data.userDot[1] - props.offset,
                    //     y1: props.data.userDot[1] + props.offset,
                    //     opacity: 0.2,
                    //     fillcolor: "blue",
                    //     line: {
                    //         width: 0
                    //     }
                    // }
                ],
                annotations: [
                    {
                        x: 0,
                        y: 0,
                        xref: "paper",
                        xanchor: "right",
                        yshift: 10,
                        showarrow: false,
                        align: "left",
                        text: "하위"
                    },
                    {
                        x: (props.data.x[0] + props.data.lines[0]) / 2,
                        y: 0,
                        yshift: 10,
                        showarrow: false,
                        align: "left",
                        text: `~${order[0]}%`
                    },
                    {
                        x: (props.data.lines[0] + props.data.lines[1]) / 2,
                        y: 0,
                        yshift: 10,
                        showarrow: false,
                        align: "left",
                        text: `~${order[1]}%`
                    },
                    {
                        x: (props.data.lines[1] + props.data.lines[2]) / 2,
                        y: 0,
                        yshift: 10,
                        showarrow: false,
                        align: "left",
                        text: `~${order[2]}%`
                    },
                    {
                        x: (props.data.lines[2] + props.data.x.at(-1)) / 2,
                        y: 0,
                        yshift: 10,
                        showarrow: false,
                        align: "left",
                        text: `~${order[3]}%`
                    },
                    {
                        x: 0,
                        y: 0,
                        xref: "paper",
                        yref: "paper",
                        xanchor: "left",
                        yshift: -33,
                        showarrow: false,
                        align: "left",
                        text: "&#8592; 약"
                    },
                    {
                        x: 1,
                        y: 0,
                        xref: "paper",
                        yref: "paper",
                        xanchor: "right",
                        yshift: -33,
                        showarrow: false,
                        align: "right",
                        text: "강 &#8594;"
                    }
                ]
            }}
            config={{ responsive: true }}
        />
    );
};

export default NormalDistribution;