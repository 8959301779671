import React from "react";
import MidCard from "../component/MidCard";
import { CardText, CardSubtitle, Alert } from "reactstrap";
import AirlineSeatLegroomExtraIcon from "@material-ui/icons/AirlineSeatLegroomExtra";
import * as d3 from "d3";
import restapi from "../util/restapi";

export default class TrendLastRomSingle extends React.Component {
  state = { loading: false };

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let trend = [];
      const body = {
        user_id: this.props.user_id,
        behavior: "rom",
        term: "s",
        offset: 0,
        windowSize: 1
      };

      try {
        const res = await restapi.patchTrend(body);

        if (res && res.result) trend = res.detail;
      } catch (error) {
        console.log(error);
      }
      console.log(trend)
      this.setState({ trend, loading: false }, () => this.draw());
    });
  };

  draw = () => {
    const svg = d3.select("svg#trend");
    svg.selectAll("*").remove();
    if (this.props.user_id === null || this.state.loading === true){ console.log("1"); return;}

    const { trend } = this.state;

    if (trend === null || !Array.isArray(trend) || trend.length !== 1){ console.log("2"); return;}

    const margin = 2;
    const {
      width: _width,
      height: _height
    } = svg.node().getBoundingClientRect();
    let [width, height] = [_width - margin * 2, _height - margin * 2];

    if (width < 10 || height < 10) return;

    if (height * 2 > width) height = width / 2;
    else width = height * 2;

    const D2R = d => (d * Math.PI) / 180;
    const arcTween = newAngle => {
      return function(d) {
        var interpolate = d3.interpolate(d.endAngle, newAngle);

        return function(t) {
          d.endAngle = interpolate(t);

          return arc(d);
        };
      };
    };
    const radius = height - margin * 2;
    const line = 20;
    const [angleSt, angleEd] = [
      D2R(270 + trend[0].rom_min),
      D2R(270 + trend[0].rom_max)
    ];
    
    const arc = d3
      .arc()
      .innerRadius(radius - line)
      .outerRadius(radius)
      .startAngle(angleSt);

    const bottom = _height - (_height - height) / 2 - margin;
    const arcRom = svg
      .append("path")
      .datum({ endAngle: angleSt })
      .attr("d", arc)
      .attr("fill", "red")
      .attr("transform", `translate(${_width / 2},${bottom})`); // move to center
    const left = (_width - width) / 2;
    const right = _width - left;
    svg
      .append("line")
      .attr("x1", left)
      .attr("y1", bottom)
      .attr("x2", right)
      .attr("y2", bottom)
      .attr("stroke-width", 1)
      .attr("stroke", "black");
    console.log(svg)

    arcRom
      .transition()
      .duration(500)
      .attrTween("d", arcTween(angleEd, arc));
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user_id !== null && prevProps.user_id !== this.props.user_id)
      this.fetch();
    else this.draw();
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
    this.props.user_id && this.fetch();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => this.draw();

  render() {
    let body = null;

    if (this.props.user_id === null) body = <CardText>select user</CardText>;
    else {
      if (!this.state.loading) {
        const { trend } = this.state;

        if (trend == null) {
          body = <Alert color="warning">no data</Alert>;
        } else {
          if (trend.length === 0) {
            body = <Alert color="warning">no data</Alert>;
          } else {
            let data = {};
            data = trend[0];

            body = (
              <>
                <CardText>
                  <svg
                    // className="graph"
                    id="trend"
                    key={new Date().getTime()}
                    height={this.height}
                    width="100%"
                  />
                </CardText>
                <CardSubtitle>Start</CardSubtitle>
                <CardText>{new Date(data.time_st).toLocaleString()}</CardText>
                <CardSubtitle>End</CardSubtitle>
                <CardText>{new Date(data.time_ed).toLocaleString()}</CardText>
                <CardSubtitle>Min</CardSubtitle>
                <CardText>{data.rom_min}</CardText>
                <CardSubtitle>Max</CardSubtitle>
                <CardText>{data.rom_max}</CardText>
              </>
            );
          }
        }
      }
    }

    return (
      <MidCard
        icon={<AirlineSeatLegroomExtraIcon fontSize="large" color="primary" />}
        title="Rom detail last day"
        loading={this.state.loading}
      >
        {body}
      </MidCard>
    );
  }
}
