import React, { useEffect, useState } from "react";
import springapi from "../util/springapi";
import {
  ListGroup,
  ListGroupItem
} from "reactstrap";
import Spinner from "../uicomponent/Spinner";

const PrescriptionList = (props) => {
  const {patientId, doctorId, update, setUpdate, exerciseList} = props;
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prescription, setPrescription] = useState({
    id: null
  });
  const category = {
    count: {text: "횟수", unit: "회", paddingLeft: 0, paddingRight: 30},
    set: {text: "세트 수", unit: "세트", paddingLeft: 23, paddingRight: 7},
    // duration: {text: "수행 시간", unit: "초", paddingLeft: 14, paddingRight: 0},
    increaseCount: {text: "", unit: "회", paddingLeft: 0, paddingRight: 30},
    increaseSet: {text: "", unit: "세트", paddingLeft: 23, paddingRight: 7},
    // increaseDuration: {text: "", unit: "초", paddingLeft: 14, paddingRight: 0}
  }

  // useEffect(() => {
  //   springapi.request("GET", "/dtx/get/exercise").then((res) => {
  //     if(res.response) {
  //       setExerciseList(res.response);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if(patientId !== null && doctorId !== null) {
      setIsLoading(true);
      setPrescription({
        id: null
      });
      springapi.request("GET", `/dtx/get/prescription?patientId=${patientId}`).then((res) => {
        if(res.response) {
          setList(res.response);
        }
        setIsLoading(false);
      });
    }
    else if(patientId === null) {
      setList([]);
    }
  }, [patientId]);

  useEffect(() => {
    if(update) {
      setIsLoading(true);
      springapi.request("GET", `/dtx/get/prescription?patientId=${patientId}`).then((res) => {
        if(res.response) {
          setList(res.response);
        }
        setIsLoading(false);
      });
      setUpdate(false);
    }
  }, [update])

  return (
    <div style={{ display: "flex", flexFlow: "column", height: "calc(100% - 401.5px)", backgroundColor: "white", border: "1px solid #CED4DA", borderRadius: 5, padding: "10px 15px 15px 15px" }}>
      <div style={{ display: "flex", height: 33.5, flexShrink: 0, alignItems: "center" }}><b>처방 목록</b><Spinner visible={isLoading} style={{ marginLeft: 5 }}/></div>
      <div style={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        <ListGroup style={{ flexShrink: 0, width: 320, height: "100%", overflowY: "auto", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: "1px solid" }}>
          {list.map((item, i) => (
            <ListGroupItem
              key={item.id}
              active={item.id === prescription.id}
              tag="button"
              data-key={item.id}
              onClick={() => setPrescription(item)}
              action
              style={{ borderRadius: 0, border: "1px solid", borderLeft: "0", borderRight: "0", padding: 10, marginTop: i === 0 ? -1 : 0 }}
            >
              <div style={{ marginBottom: 4 }}>처방 이름: {item.name}</div>
              <div style={{ marginBottom: 4 }}>{`처방 코드: ${item.code}`}</div>
              <div style={{ marginBottom: 4 }}>{`처방 일시: ${new Date(item.dateTime).toLocaleString()}`}</div>
              <div>{"처방 기간: " + new Date(item.startDate).toLocaleDateString() + " ~ " + new Date(item.endDate).toLocaleDateString()}</div>
            </ListGroupItem>
          ))}
        </ListGroup>
        <ul style={{ flexGrow: 1, height: "100%", border: "1px solid", borderTopRightRadius: 5, borderBottomRightRadius: 5, overflowY: "auto", overflowX: "hidden", marginLeft: -1 }}>
          <div style={{ padding: 10, borderBottom: "2px solid" }}>
            <div>치료 목적: {prescription.purpose}</div>
            <div>진단 증상: {prescription.symptoms}</div>
          </div>
          {prescription.id && prescription.sub.map((sub, i) => {
            return (
              <li key={i} style={{ padding: 10, border: "1px solid", margin: -1 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 12 }}><b>{i + 1}차</b></div>
                  {new Date(sub.startDate).toLocaleDateString()}<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>{new Date(sub.endDate).toLocaleDateString()}
                </div>
                {sub.exercise.map((exercise, j) => {
                  return (
                    <div key={j} style={{ marginTop: 4 }}>
                      {/* <div>{exerciseList.find((v) => v.exerciseId === exercise.exerciseId).nameKor}</div> */}
                      <div style={{ marginLeft: 5 }}>
                        <div>
                          {j + 1}일차
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          {exercise.map((name, k) => {
                            return (
                              <div key={k}>{name}</div>
                            );
                          })}
                        </div>
                        {/* <div style={{ paddingRight: 5 }}>-</div>
                        {exercise.isDefaultLevel && <div>기본 난이도</div>}
                        {!exercise.isDefaultLevel &&
                          <div style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                            <div style={{ display: "table-row" }}>
                              {["count", "set"].map((key, i) => (
                                <div key={key} style={{ display: "table-cell", paddingLeft: category[key].paddingLeft, paddingRight: category[key].paddingRight }}>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ flexGrow: 1 }}>{category[key].text}:</div>
                                    <div>{exercise[key]}{category[key].unit}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div style={{ display: "table-row" }}>
                              {["increaseCount", "increaseSet"].map((key, i) => (
                                <div key={key} style={{ display: "table-cell", paddingLeft: category[key].paddingLeft, paddingRight: category[key].paddingRight }}>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ flexGrow: 1 }}>증가폭:</div>
                                    <div>{exercise[key]}{category[key].unit}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        } */}
                      </div>
                    </div>
                  )
                })}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
};

export default PrescriptionList;