import authUtil from "./auth";

const _async = async (method, path, body, useAccessToken = false) => {
  const TAG = method;

  let result;
  const { idToken, accessToken } = await authUtil.getUserInfo();
  const reqHeader = new Headers({
    "Content-Type": "application/json",
    exoAuth: idToken
  });
  useAccessToken && reqHeader.append("exoAccess", accessToken);
  const opt = {
    method: method,
    headers: reqHeader
  };
  body !== null && (opt.body = JSON.stringify(body));

  const req = new Request(path, opt);

  try {
    const res = await fetch(req);

    if (res.ok) result = await res.json();
    else console.warn(TAG, path, body, "response not ok");
  } catch (error) {
    console.warn(TAG, path, body, error);
  }

  return result;
};

const patch = async (path, body, useAccessToken = false) => {
  return await _async("PATCH", path, body, useAccessToken);
};

const put = async (path, body, useAccessToken = false) => {
  return await _async("PUT", path, body, useAccessToken);
};

const post = async (path, body, useAccessToken = false) => {
  return await _async("POST", path, body, useAccessToken);
};

const get = async (path, useAccessToken = false) => {
  const TAG = "GET";

  let result;
  const { idToken, accessToken } = await authUtil.getUserInfo();
  const reqHeader = new Headers({
    "Content-Type": "application/json",
    exoAuth: idToken
  });
  useAccessToken && (reqHeader.exoAccess = accessToken);
  const opt = {
    method: "GET",
    headers: reqHeader
  };

  const req = new Request(path, opt);

  try {
    const res = await fetch(req);

    if (res.ok) result = await res.json();
    else console.warn(TAG, path, "response not ok");
  } catch (error) {
    console.warn(TAG, path, error);
  }

  return result;
};

const del = async path => {
  const TAG = "DELETE";

  let result;
  const { idToken } = await authUtil.getUserInfo();
  const reqHeader = new Headers({
    "Content-Type": "application/json",
    exoAuth: idToken
  });
  const opt = {
    method: "DELETE",
    headers: reqHeader
  };

  const req = new Request(path, opt);

  try {
    const res = await fetch(req);

    if (res.ok) result = await res.json();
    else console.warn(TAG, path, "response not ok");
  } catch (error) {
    console.warn(TAG, path, error);
  }

  return result;
};

export default { patch, get, post, put, del };
