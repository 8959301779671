import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  CardTitle,
  Input,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import util from "../util/util";
import GroupIcon from "@material-ui/icons/Group";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LocationIcon from "@material-ui/icons/LocationOn";
import MapIcon from "@material-ui/icons/Map";
import mobiscroll from "@mobiscroll/react-lite";
import "@mobiscroll/react-lite/dist/css/mobiscroll.min.css";
import springapi from "../util/springapi";
import Spinner from "../uicomponent/Spinner";

const MASTER_GROUP_ID = 9998;

mobiscroll.settings = {
  theme: "ios"
}

export default class V2GroupUserList extends React.Component {
  state = {
    id: null,
    loading: false,
    data: [],
    filter: "",
    view: "search"
  };

  initFlag = false;

  fetch = () => {
    this.setState({ loading: true }, async () => {
      let data;

      try {
        // const { user_id } = this.props.admin ? {} : await auth.getUserInfo();
        const groupId = parseInt(localStorage.getItem("id"));
        const token = localStorage.getItem("sessionId");
        const res = await springapi.request("POST", "/v2/user/getGroupUserList", { groupId, token });
        if(res) {
          console.log(res);
          data = res.response;
          // console.log(data);
        }
      } catch (error) {
        console.error(error);
      }

      this.setState({ loading: false, data });
    });
  };

  handleClickUser = event => {
    const user_id = event.currentTarget.dataset.key;

    this.setState({ id: Number(user_id) }, () => {
      this.props.onChangeUser && this.props.onChangeUser(this.state.id);
    });
  };

  handleChangeFilter = event => {
    this.setState({ filter: event.target.value });
  };

  handleClear = () => {
    this.setState({ filter: "" });
  };

  componentDidMount = () => {
    this.fetch();
  };

  changeView = (event) => {
    this.setState({ view: event.currentTarget.value })
  }

  changeGroupFilter = (event) => {
    this.setState({ group_filter: event.currentTarget.dataset.key })
  }

  clearGroupFilter = () => {
    this.setState({ group_filter: "" })
  }

  changeParentFilter = (event) => {
    this.setState({ parent_filter: event.currentTarget.dataset.key })
  }

  clearParentFilter = () => {
    this.setState({ parent_filter: "" })
  }

  setMapOpened = (bool) => {
    this.setState({ mapOpened: bool });
  }

  render = () => {
    const { filter, data, view } = this.state;
    const search = 
      data &&
      data
        .filter(
          item =>
            (item.memo && item.memo.includes(filter))
            // (item.family_name && item.family_name.includes(filter)) ||
            // (item.given_name && item.given_name.includes(filter)) ||
            // (item.middle_name && item.middle_name.includes(filter))
        )
        .map(item => (
          <ListGroupItem
            key={item.userId}
            active={this.state.id === item.userId}
            tag="button"
            data-key={item.userId}
            onClick={this.handleClickUser}
            action
          >
            <Container>
              <Row>
                <Col xs="12">{item.memo}</Col>
              </Row>
              <Row>
                <Col xs="6">{util.convDt2SimpleDate(item.birthdate)}</Col>
                <Col xs="6">{item.gender}</Col>
                {/* <Col xs="4">{item.weight && item.weight + "kg"}</Col> */}
              </Row>
            </Container>
          </ListGroupItem>
        ));

    var content;
    switch(view) {
      case("search"):
        content = search;
        break;
      default:
        break;
    }

    const disableInput = !data || data.length === 0;

    return (
      <div style={{ border: "1px solid gray", display: "flex", flexDirection:"column", margin: "3px 0 0 3px", width: 280, height: "calc(100% - 70px)" }}>
        <div style={{ height: 34, display: "flex", flexShrink: 0, alignItems: "center", padding: "2px 10px 2px 10px", marginBottom: -1 }}>
          <GroupIcon style={{ marginRight: 2 }} color="primary"/>
          <div style={{ marginRight: 5 }}>User List</div>
          <Spinner visible={this.state.loading}/>
          {/* <div style={{ flexGrow: 1 }}/> */}
        </div>
        
        {this.state.view === "search" &&
          <>
            <InputGroup style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Input
                value={this.state.filter}
                placeholder="Search"
                onChange={this.handleChangeFilter}
                disabled={disableInput}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  onClick={this.handleClear}
                  disabled={!this.state.filter}
                >
                  <HighlightOffIcon style={{ height: 18, marginTop: -4, width: 16 }} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <ListGroup style={{ flexGrow: 1, overflow: "auto", margin: 10, backgroundColor: "white", borderRadius: 5 }}>{content}</ListGroup>
          </>
        }
      </div>
    );
  };
}