import React, { Component } from "react";
import V2ExerciseList from "./V2ExerciseList";
import V2MqList from "./V2MqList";
import V2Calendar from "./V2Calendar";
import V2EmgView from "./V2EmgView copy";
import MqView from "../component/MqView";
import V2Fes from "./V2Fes";

import Typography from "@material-ui/core/Typography";
// import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import TopSpacer from "../component/TopSpacer";
import User from "./User";
import springapi from "../util/springapi";
import V2UserList from "./V2UserList";
import TabSelector from "../uicomponent/TabSelector";
import IconExercise from "@material-ui/icons/FitnessCenterRounded";
import IconMq from "@material-ui/icons/AirlineSeatLegroomExtraRounded";
import IconFes from "@material-ui/icons/FlashOnRounded";
import MonthlyExercise from "./MonthlyExercise";
import MonthlyEs from "./MonthlyEs";
import V2GroupUserList from "./V2GroupUserList";

const drawerWidth = 350;
const style = {
  LeftRight: { marginLeft: "20px", marginRight: "20px" }
};
class PrintBody extends Component {
  render = () => <div>{this.props.children}</div>;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default class V2HomeTemp extends Component {
  state = {
    loading: false,
    user_id: null,
    isAdmin: false,
    isGroupAdmin: false,
    date: new Date(),
    behavior_type: "",
    behavior_id: null,
    selectedTab: 0,
    term: "d",
    offset: 0,
    exerciseData: [],
    mqData: [],
    dateString: "",
    weight: 0,
    mode: "daily",
    monthDate: new Date(),
    monthlyExercise: [],
    monthlyEs: []
  };

  componentDidMount = async () => {
    const id = await User.getId();
    if(id === null) {
      window.location = "/";
      return;
    }
    console.log("asdf")
    const isAdmin = await User.isAdmin(id);
    // const res = await springapi.request("GET", "/dtx/get/lastPrescription?patientId=1867");
    // const test = new Date(res.response.dateTime);
    // // const test = new Date("2022-05-09T03:06:20.000+00:00");
    // console.log(typeof(res.response.dateTime))
    // console.log(test);
    const date = new Date();
    if(isAdmin) {
      this.setState({ user_id: null, date, isAdmin });
      return;
    }
    const isGroupAdmin = await User.isGroupAdmin();
    if(isGroupAdmin) {
      this.setState({ user_id: null, date, isGroupAdmin });
      return;
    }
    this.fetch(id, date);
    // const dateString = "" + date.getFullYear() + (date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + (date.getDate() >= 10 ? date.getDate() : ("0" + date.getDate()));
    // const exercise = await springapi.getExercise(id, dateString);
    // const mq = await springapi.getMq(id, dateString);
    // let exerciseData = [], mqData = [];
    // if(exercise.response) {
    //   exerciseData = exercise.response;
    // }
    // if(mq.response) {
    //   mqData = mq.response;
    // }
    // // const wb = xlsx.read(webTest, {type: "binary"});
    // // const wsname = wb.SheetNames[0];
    // // const ws = wb.Sheets[wsname];
    // // /* Convert array of arrays */
    // // const data = xlsx.utils.sheet_to_csv(ws, {header:1});
    // // /* Update state */
    // // console.log("Data>>>"+data);
    // this.setState({ user_id: id, date, behavior_id: null, exerciseData, mqData, dateString });
  }

  handleUserChange = event => {
    const uid = Number(event);
    if(!Number.isNaN(uid)) {
      this.fetch(uid, this.state.date);
      this.setMonthlyData(uid, this.state.monthDate);
    }
  };

  handleDateChange = async (value) => {
    await this.fetch(this.state.user_id, value);
    // const dateString = "" + value.getFullYear() + (value.getMonth() + 1 >= 10 ? value.getMonth() + 1 : ("0" + (value.getMonth() + 1))) + (value.getDate() >= 10 ? value.getDate() : ("0" + value.getDate()));
    // const exercise = await springapi.getExercise(this.state.user_id, dateString);
    // const mq = await springapi.getMq(this.state.user_id, dateString);
    // let exerciseData = [], mqData = [];
    // if(exercise.response) {
    //   exerciseData = exercise.response;
    //   console.log(exerciseData);
    // }
    // if(mq.response) {
    //   mqData = mq.response;
    // }
    // this.setState({ date: value, behavior_id: null, exerciseData, mqData, dateString });
  };

  fetch = async(id, date) => {
    const dateString = "" + date.getFullYear() + (date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + (date.getDate() >= 10 ? date.getDate() : ("0" + date.getDate()));
    const exercise = await springapi.getExercise(id, dateString);
    const mq = await springapi.getMq(id, dateString);
    let weight;
    if(id === this.state.user_id) {
      weight = this.state.weight;
    }
    else {
      weight = await springapi.request("GET", `/v2/user/getWeight?id=${id}`);
    }
    let exerciseData = [], mqData = [];
    if(exercise.response) {
      exerciseData = exercise.response;
    }
    if(mq.response) {
      mqData = mq.response;
    }
    this.setState({ user_id: id, date, exerciseData, mqData, dateString, weight });
  }

  handleExercise = behavior_id => {
    this.setState({ behavior_type: "exercise", behavior_id });
  };

  handleMq = behavior_id => {
    console.log(behavior_id, this.state.mqData);
    this.setState({ behavior_type: "mq", behavior_id });
  };

  handleSelectChange = (event, index) => {
    this.setState({ selectedTab: index });
  };

  handlePrev = () => {
    this.setState({ offset: this.state.offset + 5 });
  };

  handleNext = () => {
    this.setState({ offset: Math.max(this.state.offset - 5, 0) });
  };

  handleTerm = event => {
    this.setState({ term: event.target.dataset.term, offset: 0 });
  };

  a11yProps = index => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
  };

  handleModeChange = (mode) => {
    this.setState({ mode });
  }

  handleMonthChange = (date) => {
    this.setState({ monthDate: date });
    this.state.user_id && this.setMonthlyData(this.state.user_id, date);
  }

  setMonthlyData = async (id, date) => {
    const data = await springapi.request("GET", `/v2/log/get/dataByMonth?id=${id}&year=${date.getFullYear()}&month=${date.getMonth() + 1}`);
    if(data.response) {
      const { exercise, es } = data.response;
      this.setState({ monthlyExercise: exercise, monthlyEs: es });
    }
  }

  render = () => {
    if (this.state.loading === true) return "loading...";

    const {
      user_id,
      isAdmin,
      isGroupAdmin,
      date,
      selectedTab,
      term,
      offset,
      exerciseData,
      mqData,
      dateString,
      weight,
      monthDate,
      monthlyExercise,
      monthlyEs
    } = this.state;
    console.log(isAdmin);

    return (
      <div style={{ display: "flex", height: "100%" }}>
        {(isAdmin) && (
          <div style={{ height: "100%", position: "fixed", top: 64, left: 0 }}>
            <V2UserList onChangeUser={this.handleUserChange}/>
          </div>
        )}
        {(isGroupAdmin) && (
          <div style={{ height: "100%", position: "fixed", top: 64, left: 0 }}>
            <V2GroupUserList onChangeUser={this.handleUserChange}/>
          </div>
        )}
        <div style={{ flexGrow: 1, padding: 3, marginLeft: (isAdmin || isGroupAdmin) ? 283 : 0 }}>
          <PrintBody ref={el => this.props.onRef(el)}>
            <TopSpacer />
            {/* <Paper>
              <Tabs
                value={selectedTab}
                onChange={this.handleSelectChange}
                indicatorColor="primary"
                variant="fullWidth"
              >
                <Tab label="Detail Info" {...this.a11yProps(0)} />
                <Tab label="Trend Check" {...this.a11yProps(1)} />
              </Tabs>
            </Paper> */}
            {/* <TabPanel value={selectedTab} index={0}> */}
              <div style={{ display: "flex", width: "100%" }}>
                <V2Calendar
                  user_id={user_id}
                  onModeChange={this.handleModeChange}
                  onDateChange={this.handleDateChange}
                  onMonthChange={this.handleMonthChange}
                />
                {/* <V2ExerciseList
                  user_id={user_id}
                  isAdmin={isAdmin}
                  date={date}
                  data={exerciseData}
                  currentType={this.state.behavior_type}
                  onChangeBehavior={this.handleExercise}
                /> */}
                {this.state.mode === "daily" &&
                  <TabSelector preLoad style={{ width: "60%", height: 550, minWidth: 750 }} tabs={[
                    {
                      icon: <IconExercise style={{ marginRight: 2 }} color="primary"/>,
                      name: "Exercise",
                      component: <V2ExerciseList
                        user_id={user_id}
                        isAdmin={isAdmin}
                        date={date}
                        data={exerciseData}
                        currentType={this.state.behavior_type}
                        onChangeBehavior={this.handleExercise}
                        behavior_id={this.state.behavior_id}
                      />
                    },
                    {
                      icon: <IconMq style={{ marginRight: 2 }} color="primary"/>,
                      name: "MQ",
                      component: <V2MqList
                        user_id={user_id}
                        isAdmin={isAdmin}
                        date={date}
                        data={mqData}
                        currentType={this.state.behavior_type}
                        onChangeBehavior={this.handleMq}
                        behavior_id={this.state.behavior_id}
                      />
                    },
                    {
                      icon: <IconFes style={{ marginLeft: -2, marginRight: -2 }} color="primary"/>,
                      name: "Bioelectric",
                      component: <V2Fes
                        user_id={user_id}
                        date={dateString}
                      />
                    }
                  ]}/>
                }
                {this.state.mode === "monthly" &&
                  <TabSelector preLoad style={{ width: "60%", height: 550, minWidth: 750 }} tabs={[
                    {
                      icon: <IconExercise style={{ marginRight: 2 }} color="primary"/>,
                      name: "Exercise",
                      component: <MonthlyExercise dataList={monthlyExercise}/>
                    },
                    {
                      icon: <IconFes style={{ marginLeft: -2, marginRight: -2 }} color="primary"/>,
                      name: "Bioelectric",
                      component: <MonthlyEs dataList={monthlyEs}/>
                    }
                  ]}/>
                }
              </div>
              
              {this.state.behavior_type === "exercise" && this.state.behavior_id && (
                <>
                  <V2EmgView behavior_id={this.state.behavior_id} data={exerciseData} userId={this.state.user_id} today={this.state.date}/>
                  {/* <V2EmgView behavior_id={this.state.behavior_id} data={exerciseData}></V2EmgView> */}
                  {/* <RawView behavior_id={this.state.behavior_id}></RawView> */}
                  {/* <RmsView behavior_id={this.state.behavior_id}></RmsView>
                  <NormView behavior_id={this.state.behavior_id}></NormView> */}
                </>
              )}
              {this.state.behavior_type === "mq" && this.state.behavior_id && (
                <>
                  <MqView behavior_id={this.state.behavior_id} data={mqData} weight={this.state.weight}></MqView>
                  {/* <RawView behavior_id={this.state.behavior_id}></RawView> */}
                  {/* <RmsView behavior_id={this.state.behavior_id}></RmsView>
                  <NormView behavior_id={this.state.behavior_id}></NormView> */}
                </>
              )}
              {/* {this.state.user_id == 738 &&
              <MqView/>
              } */}
            {/* </TabPanel> */}
            {/* <TabPanel value={selectedTab} index={1}>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button style={style.LeftRight} onClick={this.handlePrev}>
                  {"<"}
                </Button>
                <Button data-term="s" onClick={this.handleTerm}>
                  single
                </Button>
                <Button data-term="d" onClick={this.handleTerm}>
                  day
                </Button>
                <Button data-term="w" onClick={this.handleTerm}>
                  week
                </Button>
                <Button data-term="m" onClick={this.handleTerm}>
                  month
                </Button>
                <Button style={style.LeftRight} onClick={this.handleNext}>
                  {">"}
                </Button>
              </div>
              {term !== "s" &&
                <TrendRom
                  user_id={user_id}
                  windowSize={5}
                  term={term}
                  offset={offset}
                />
              }
              <TrendRehabAll user_id={user_id} term={term} offset={offset} />
              <Treatment user_id={user_id} />
            </TabPanel> */}
          </PrintBody>
        </div>
      </div>
    );
  };
}
