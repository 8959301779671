import * as d3 from "d3";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Plot from "react-plotly.js";
import GraphColors from "../colors/graph";
import util from "../util/util";

const MonthlyExercise = ({dataList}) => {
  const [sort, setSort] = useState("count");

  dataList.sort((a, b) => {
    if(a[sort] > b[sort]) {
      return -1;
    }
    if(a[sort] === b[sort]) {
      return 0;
    }
    return 1;
  });
  
  let pie;
  if(dataList.length > 10) {
    pie = dataList.slice(0,9).map((d) => {
      return {
        name: d.name,
        value: d[sort]
      }
    });
    let sum = 0;
    dataList.slice(9).forEach((d) => sum += d[sort]);
    pie.push({
      name: "기타",
      value: sum
    });
  }
  else {
    pie = dataList.map((d) => {
      return {
        name: d.name,
        value: d[sort]
      }
    });
  }

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ width: 250, flexShrink: 0 }}>
        <div style={{ display: "flex", width: "100%", height: 100, justifyContent: "center", alignItems: "center" }}>
          <button style={{ width: 100, height: 30, border: "1px solid" }} disabled={sort === "count"} onClick={() => {
            setSort("count");
          }}>횟수</button>
          <button style={{ width: 100, height: 30, marginLeft: -1, border: "1px solid" }} disabled={sort === "totalTime"} onClick={() => {
            setSort("totalTime");
          }}>전체 시간</button>
        </div>
        <Plot
          data={[
            {
              values: pie.map((p) => p.value),
              labels: pie.map((p) => p.name),
              type: "pie",
              // automargin: true,
              showlegend: false,
              sort: false,
              marker: {
                colors: GraphColors.pie.slice(0, pie.length)
              },
              text: pie.map((p) => {
                let valueString;
                if(sort === "count") {
                  valueString = p.value + "회";
                }
                else {
                  valueString = util.s2hms(p.value);
                }
                return `${p.name}<br>${valueString}`;
              }),
              hoverinfo: "text+percent",
              textinfo: "percent",
              textposition: "inside"
            }
          ]}
          layout={{
            margin: {
              t: 10,
              l: 10,
              r: 10,
              b: 100
            }
          }}
          useResizeHandler={true}
          // onError={(err) => console.log(err)}
          config={{ responsive: true, displayModeBar: false }}
          style={{ width: "100%", height: 400 }}
        />
      </div>
      <div style={{ width: "100%", height: 515, marginBottom: -1, borderLeft: "1px solid", overflowX: "hidden", overflowY: "auto" }}>
          {/* <div style={{ display: "flex", padding: 10, borderBottom: "1px solid" }}>
            <div>Type</div>
            <div>Total Count</div>
            <div>Total Time</div>
          </div> */}
          {dataList.length === 0 && 
            <div style={{ padding: 10 }}>No Data</div>
          }
          {dataList.map((d, i) => {
            // const hours = parseInt(d.totalTime / 3600), minutes = parseInt((d.totalTime % 3600) / 60), seconds = d.totalTime % 60;
            // const totalTimeString = hours + "시간 " + String(minutes).padStart(2, "0") + "분 " + String(seconds).padStart(2, "0") + "초";
            return (
              <div key={i} style={{ display: "flex", borderBottom: "1px solid", padding: "10px 10px 10px 0" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 52, height: 82, flexShrink: 0 }}>
                  <div style={{ width: 20, height: 10, backgroundColor: i > 9 ? GraphColors.pie[9] : GraphColors.pie[i], borderRadius: 5 }}/>
                </div>
                <div style={{ width: "100%" }}>
                  <div>{d.name}</div>
                  <div style={{ display: "table", width: "100%", marginTop: 10 }}>
                    <div style={{ display: "table-cell", width: "40%" }}>횟수: {d.count}회</div>
                    <div style={{ display: "table-cell", width: "60%" }}>전체 운동 시간: {util.s2hms(d.totalTime)}</div>
                  </div>
                  <div style={{ display: "table", width: "100%", marginTop: 10 }}>
                    <div style={{ display: "table-cell", width: "40%" }}>평균 진행도: {_.round(d.averageProgress / 100 * d.sets, 1)} / {d.sets}세트</div>
                    <div style={{ display: "table-cell", width: "60%" }}>평균 운동 시간: {util.s2hms(_.round(d.averageTime))}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MonthlyExercise;