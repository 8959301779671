// // import { CompositeLayer } from '@deck.gl/core';
// // import { IconLayer } from '@deck.gl/layers';
// import { IconLayer, CompositeLayer } from "deck.gl";
// import Supercluster from 'supercluster';

// function getIconName(size) {
//   if (size === 0) {
//     return '';
//   }
//   if (size < 10) {
//     return `marker-${size}`;
//   }
//   if (size < 100) {
//     return `marker-${Math.floor(size / 10)}0`;
//   }
//   return 'marker-100';
// }

// function getIconSize(size) {
//   return Math.min(100, size) / 100 + 1;
// }

// export default class IconClusterLayer extends CompositeLayer {
//   shouldUpdateState({changeFlags}) {
//     return changeFlags.somethingChanged;
//   }

//   updateState({props, oldProps, changeFlags}) {
//     const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

//     if (rebuildIndex) {
//       const index = new Supercluster({maxZoom: 16, radius: props.sizeScale * Math.sqrt(2)});
//       index.load(
//         props.data.map(d => ({
//           geometry: {coordinates: props.getPosition(d)},
//           properties: d
//         }))
//       );
//       this.setState({index});
//     }

//     const z = Math.floor(this.context.viewport.zoom);
//     if (rebuildIndex || z !== this.state.z) {
//       this.setState({
//         data: this.state.index.getClusters([-180, -85, 180, 85], z),
//         z
//       });
//     }
//   }

//   getPickingInfo({info, mode}) {
//     const pickedObject = info.object && info.object.properties;
//     if (pickedObject) {
//       if (pickedObject.cluster && mode !== 'hover') {
//         info.objects = this.state.index
//           .getLeaves(pickedObject.cluster_id, 25)
//           .map(f => f.properties);
//       }
//       info.object = pickedObject;
//     }
//     return info;
//   }

//   renderLayers() {
//     const {data} = this.state;
//     const {iconAtlas, iconMapping, sizeScale} = this.props;

//     return new IconLayer(
//       this.getSubLayerProps({
//         id: 'icon',
//         data,
//         iconAtlas,
//         iconMapping,
//         sizeScale,
//         getPosition: d => d.geometry.coordinates,
//         getIcon: d => getIconName(d.properties.cluster ? d.properties.point_count : 1),
//         getSize: d => getIconSize(d.properties.cluster ? d.properties.point_count : 1)
//       })
//     );
//   }
// }

import { CompositeLayer, IconLayer } from 'deck.gl';
import Supercluster from 'supercluster';

function getIconName(size) {
  if (size === 0) {
    return '';
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 100) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return 'marker-100';
}

function getIconSize(size) {
  if (size < 10) {
    return 2;
  }
  return Math.min(100, size) / 40 + 2;
}

export default class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({changeFlags}) {
    return changeFlags.somethingChanged;
  }

  updateState({props, oldProps, changeFlags}) {
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({maxZoom: 18, radius: props.sizeScale});
      index.load(
        props.data.map(d => ({
          geometry: {
            coordinates: props.getPosition(d)
          },
          properties: props.getInfo(d)
        }))
      );
      this.setState({index});
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  getPickingInfo({info, mode}) {
    if (info.object && info.object.properties) {
      if (info.object.properties.cluster && mode !== 'hover') {
        info.objects = this.state.index
          .getLeaves(info.object.properties.cluster_id, 25)
          .map(f => f.properties);
      }
    }
    return info;
  }

  renderLayers() {
    const {data} = this.state;
    const {iconAtlas, iconMapping, sizeScale} = this.props;
    return new IconLayer(
      this.getSubLayerProps({
        id: 'icon',
        data,
        iconAtlas,
        iconMapping,
        sizeScale,
        sizeMinPixels: 40,
        sizeMaxPixels: 90,
        getPosition: d => d.geometry.coordinates,
        getIcon: d => getIconName(d.properties.cluster ? d.properties.point_count : 1),
        getSize: d => getIconSize(d.properties.cluster ? d.properties.point_count : 1)
      })
    );
  }
}