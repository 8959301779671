import springapi from "../util/springapi";

var User = (() => {
    var getId = async() => {
        const id = localStorage.getItem("id");
        const sessionId = localStorage.getItem("sessionId");
        if(id === null) {
            return null;
        }
        try {
            const isGroup = localStorage.getItem("isGroup");
            if(!isGroup || isGroup === "false") {
                const res = await springapi.request("GET", `/v2/user/checkSession?id=${id}&sessionId=${sessionId}`);
                if(res) {
                    if(res.body.session) {
                        return id;
                    }
                    localStorage.removeItem("id");
                    localStorage.removeItem("sessionId");
                    localStorage.removeItem("isGroup");
                }
            }
            else if(isGroup === "true") {
                const res = await springapi.request("POST", "/v2/user/groupLoginByToken", { groupId: id, token: sessionId });
                if(res) {
                    if(res.response) {
                        return id;
                    }
                    localStorage.removeItem("id");
                    localStorage.removeItem("sessionId");
                    localStorage.removeItem("isGroup");
                }
            }
        }
        catch(e) {
            console.error(e);
        }
        return null;
    };

    var isAdmin = async id => {
        if(id) {
            try {
                const res = await springapi.request("GET", `/v2/user/checkAdmin?id=${id}`);
                console.log(res);
                if(res) {
                    return res.body.verified;
                }
            }
            catch(e) {
                console.error(e);
            }
        }
        return null;
    }

    var isGroupAdmin = async() => {
        const groupId = localStorage.getItem("id");
        const token = localStorage.getItem("sessionId");
        const isGroup = localStorage.getItem("isGroup");
        if(isGroup === "true") {
            const res = await springapi.request("POST", "/v2/user/groupLoginByToken", { groupId, token });
                if(res && res.response) {
                    return true;
                }
        }
        return false;
    }

    var signIn = (id, sessionId) => {
        localStorage.setItem("id", id);
        localStorage.setItem("sessionId", sessionId);
        localStorage.setItem("isGroup", false);
    };

    const groupSignIn = (groupId, token) => {
        localStorage.setItem("id", groupId);
        localStorage.setItem("sessionId", token);
        localStorage.setItem("isGroup", true);
    }

    var signOut = async() => {
        const id = localStorage.getItem("id");
        const sessionId = localStorage.getItem("sessionId");
        try {
            const isGroup = localStorage.getItem("isGroup");
            if(!isGroup || isGroup === "false") {
                const res = await springapi.request("GET", `/v2/user/expireSession?id=${id}&sessionId=${sessionId}`);
            }
        }
        catch(e) {
            console.error(e);
        }
        localStorage.removeItem("id");
        localStorage.removeItem("sessionId");
        localStorage.removeItem("isGroup");
    };

    return {
        getId,
        isAdmin,
        isGroupAdmin,
        signIn,
        groupSignIn,
        signOut
    };
})();

export default User;