import React, { useEffect, useLayoutEffect, useState } from "react";
import Spinner from "../uicomponent/Spinner";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import springapi from "../util/springapi";
import _ from "lodash";

const ExerciseLog = (props) => {
  const {patientId, exerciseList} = props;
  const [map, setMap] = useState({});
  const [evaluationLog, setEvaluationLog] = useState(null);
  const [exerciseLogList, setExerciseLogList] = useState([]);
  const [nutritionLog, setNutritionLog] = useState({
    water: 0,
    medicine: false,
    meal: []
  });
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [year, setYear] = useState(currentDate.getFullYear());
  const [durationSum, setDurationSum] = useState(0);
  const direction = ["왼쪽", "오른쪽", "양쪽"];
  const mealType = {
    0: "아침",
    1: "점심",
    2: "저녁"
  };

  useEffect(() => {
    setEvaluationLog(null);
    setExerciseLogList([]);
    setNutritionLog({
      water: 0,
      medicine: false,
      meal: []
    });
    setFeedback(null);
  }, [patientId]);

  useLayoutEffect(() => {
    setMap({});
    if(patientId !== null) {
      setIsLoading(true);
      springapi.request("GET", `/dtx/get/generalLogByMonth?patientId=${patientId}&year=${year}&month=${month}`).then((res) => {
        if(res.response) {
          const map = {};
          res.response.forEach((v) => {
            map[new Date(v.date).getDate()] = v.log;
          });
          setMap(map);
        }
        setIsLoading(false);
      });
    }
  }, [patientId, month, year]);

  useEffect(() => {
    if(currentDate.getMonth() + 1 === month && currentDate.getFullYear() === year) {
      if(Object.keys(map).length !== 0 && map[currentDate.getDate()]) {
        setEvaluationLog(map[currentDate.getDate()].evaluation)
        setExerciseLogList(map[currentDate.getDate()].exercise);
        setNutritionLog(map[currentDate.getDate()].nutrition ?? {
          water: 0,
          medicine: false,
          meal: []
        });
        setFeedback(map[currentDate.getDate()].feedback ?? null);
      }
      else {
        setEvaluationLog(null);
        setExerciseLogList([]);
        setNutritionLog({
          water: 0,
          medicine: false,
          meal: []
        });
        setFeedback(null);
      }
    }
  }, [map]);

  useLayoutEffect(() => {
    setDurationSum(_.sumBy(exerciseLogList, (log) => (new Date(log.endTime) - new Date(log.startTime)) / 1000));
  }, [exerciseLogList]);

  const tileContent = ({ date, view }) => {
    if(view !== "month") {
      return null;
    }
    var count = 0;
    var color = "blue";
    if(date.getMonth() + 1 === month) {
      count = map[date.getDate()] ? map[date.getDate()].exercise.length : 0;
      // if(date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
      //   color = "white";
      // }
    }
    return (
      <div style={{ color, opacity: count === 0 ? 0 : 1 }}>
        {count > 9 ? "9회+" : `${count}회`}
      </div>
    );
  }

  function handleOnClickDay(value) {
    setCurrentDate(value);
    if(value.getDate() === currentDate.getDate() && value.getMonth() === currentDate.getMonth() && value.getFullYear() === currentDate.getFullYear()) {
      return;
    }
    if(map[value.getDate()]) {
      setEvaluationLog(map[value.getDate()].evaluation);
      setExerciseLogList(map[value.getDate()].exercise);
      setNutritionLog(map[value.getDate()].nutrition ?? {
        water: 0,
        medicine: false,
        meal: []
      });
      setFeedback(map[value.getDate()].feedback ?? null);
    }
    else {
      setEvaluationLog(null);
      setExerciseLogList([]);
      setNutritionLog({
        water: 0,
        medicine: false,
        meal: []
      });
      setFeedback(null);
    }
  }

  function handleOnActiveStartDateChange({activeStartDate, view}) {
    // if(view === "month") {
      setMonth(activeStartDate.getMonth() + 1);
      setYear(activeStartDate.getFullYear());
    // }
  }

  return (
    <div style={{ display: "flex", flexFlow: "column", height: "100%", backgroundColor: "white", border: "1px solid #CED4DA", borderRadius: 5, padding: "10px 15px 15px 15px" }}>
      <div style={{ display: "flex", height: 33.5, alignItems: "center" }}><b>로그</b><Spinner visible={isLoading} style={{ marginLeft: 5 }}/></div>
      <div style={{ display: "flex", height: "calc(100% - 33.5px)" }}>
        <div style={{ position: "relative", display: "flex", flexDirection: "column", flexShrink: 0, width: 350, overflow: "hidden", border: "1px solid", borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>
          <Calendar
            className="dtxCalendar"
            tileClassName={(t) => {
              const data = map[t.date.getDate()];
              if(data && t.date.getMonth() === t.activeStartDate.getMonth()) {
                if(data.evaluation && (data.evaluation.vas >= 7 || data.evaluation.borg >= 3) || data.feedback) {
                  return "dtx_tile abnormal_day";
                }
              }
              return "dtx_tile";
            }}
            value={currentDate}
            formatDay={(locale, date) => date.toLocaleString("en", {day: "numeric"})}
            tileContent={tileContent}
            onChange={handleOnClickDay}
            onActiveStartDateChange={handleOnActiveStartDateChange}
            calendarType="US"
          />
          <span style={{ color: "blue", width: "100%", textAlign: "left", paddingLeft: 5 }}>* 파란 텍스트는 해당 날짜에 기록된 운동 횟수</span>
          <span style={{ color: "red", width: "100%", textAlign: "left", paddingLeft: 5, borderBottom: "1px solid black" }}>* 붉은 배경색은 해당 날짜에 특이사항이 기록된 경우</span>
          <div style={{ display: "flex", flexDirection: "column", flex: "1 0 0", marginBottom: -1, overflowY: "auto", overflowX: "hidden" }}>
            <div style={{ marginTop: 10, borderBottom: "1px solid black" }}>
              <b style={{ marginLeft: 5 }}>운동 평가</b>
              <div style={{ padding: 10 }}>
                <div><b>기분:</b> {evaluationLog?.mood}</div>
                <div>솔루션: {evaluationLog?.moodSolution}</div>
              </div>
              <div style={{ padding: 10 }}>
                <div><b>VAS:</b> {evaluationLog?.vas}</div>
                <div>솔루션: {evaluationLog?.vasSolution}</div>
              </div>
              <div style={{ padding: 10 }}>
                <div><b>Borg Scale:</b> {evaluationLog && evaluationLog.borg ? evaluationLog.borg + 1 : null}</div>
              </div>
            </div>
            {feedback &&
              <div style={{ marginTop: 10, borderBottom: "1px solid black" }}>
                <b style={{ marginLeft: 5 }}>운동 중단 이유</b>
                <div style={{ padding: 10, wordWrap: "break-word" }}>
                  <div>{feedback?.feedback}</div>
                </div>
              </div>
            }
            <div style={{ flex: "1 0 0" }}/>
            <div>
              <b style={{ marginLeft: 5 }}>영양 정보</b>
              <table style={{ width: "calc(100% + 2px)", marginLeft: -1, marginRight: -1, textAlign: "center" }}>
                <tbody>
                  <tr style={{ borderBottom: "2px solid black" }}>
                    <th>물 섭취량</th>
                    <td>{nutritionLog.water}mL</td>
                    <th>약 복용 여부</th>
                    <td>{nutritionLog.medicine ? "O" : "X"}</td>
                  </tr>
                  <tr>
                    <th>식사 시간</th>
                    <th>기록 여부</th>
                    <th>식사량</th>
                    <th>단백질 포함</th>
                  </tr>
                  {Object.keys(mealType).map((mealTypeId) => {
                    const mealLog = nutritionLog.meal.find((v) => v.mealTypeId === parseInt(mealTypeId));
                    return (
                      <tr key={mealTypeId}>
                        <td>{mealType[mealTypeId]}</td>
                        <td>{mealLog === undefined ? "X" : "O"}</td>
                        <td>{mealLog === undefined ? "-" : (mealLog.smallMeal ? "적음" : "많음")}</td>
                        <td>{mealLog === undefined ? "-" : (mealLog.hasProtein ? "O" : "X")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flex: "1 0 0", flexDirection: "column", height: "100%", border: "1px solid", borderTopRightRadius: 5, borderBottomRightRadius: 5, marginLeft: -1 }}>
          <div style={{ padding: 10, borderBottom: "1px solid black" }}><b>총 운동 시간:</b> {parseInt(durationSum / 60) + "분 " + ("0" + durationSum % 60).slice(-2) + "초"}</div>
          <ul style={{ flex: "1 0 0", overflowY: "auto", overflowX: "hidden", marginBottom: 0 }}>
            {exerciseList.length > 0 && exerciseLogList.map((log, i) => {
              const duration = (new Date(log.endTime) - new Date(log.startTime)) / 1000;
              const count = log.maxCount * log.currentSet + log.currentCount;
              const totalCount = log.maxCount * log.maxSet;
              const isSuccess = log.successRate === 1.0;
              const ex = exerciseList.find((v) => v.exerciseId === log.exerciseId);
              return (
                <li key={i} style={{ padding: 10, border: "1px solid", margin: -1 }}>
                  <div>{`${ex.nameKor || ex.nameEng} - ${direction[log.directionId]}`}</div>
                  <div style={{ display: "flex", flexFlow: "row", marginTop: 4 }}>
                    <div>
                      <div>{`시작 일시: ${new Date(log.startTime).toLocaleString()}`}</div>
                      <div>{`종료 일시: ${new Date(log.endTime).toLocaleString()}`}</div>
                    </div>
                    <div style={{ flexGrow: 1 }}/>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{parseInt(duration / 60) + "분 " + ("0" + duration % 60).slice(-2) + "초"}</div>
                  </div>
                  <div style={{ display: "flex", marginTop: 4 }}>
                    {`운동 진행도: ${count}/${totalCount}회 / 성공 여부: ${isSuccess ? "완료" : "미완료"}`}
                    {isSuccess && log.isFake && <div style={{ color: "red" }}>{"(거짓)"}</div>}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ExerciseLog;