const convDt2SimpleDate = dt => {
  const date = new Date(dt);
  return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date
    .getUTCDate()
    .toString()
    .padStart(2, "0")}`;
};

const convDt2SimpleTime = dt => {
  const date = new Date(dt);
  return `${date
    .getUTCHours()
    .toString()
    .padStart(2, "0")}:${date
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}:${date
    .getUTCSeconds()
    .toString()
    .padStart(2, "0")}`;
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const validEmail = email => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

const validPw = pw => {
  return /(?=.*\d)(?=.*[a-z])[a-zA-Z\d^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`=+]{8,50}/.test(
    pw
  );
};

const validBirthdate = date => {
  return /\d{4}-\d{2}-\d{2}/.test(date);
};

const s2hms = (time) => {
  const hours = parseInt(time / 3600), minutes = parseInt((time % 3600) / 60), seconds = time % 60;
  if(hours === 0) {
    return minutes + "분 " + String(seconds).padStart(2, "0") + "초";
  }
  return hours + "시간 " + String(minutes).padStart(2, "0") + "분 " + String(seconds).padStart(2, "0") + "초";
}

export default {
  convDt2SimpleDate,
  convDt2SimpleTime,
  getRandomColor,
  validBirthdate,
  validEmail,
  validPw,
  s2hms
};
