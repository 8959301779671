import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useGlobal } from "../v2/context/global";
import CloseIcon from "@material-ui/icons/Close";

const PrintModal = () => {
  const { print, setPrint } = useGlobal();
  const ref = useRef(null);

  // useEffect(() => {
  //   console.log(print);
  // }, [print])
  
  if(!print) {
    return <div/>;
  }
  return (
    <>
      <div style={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0, zIndex: 1202, backgroundColor: "rgba(0, 0, 0, 0.25)" }} onClick={() => setPrint(null)}/>
      <div style={{ position: "fixed", top: "10%", left: "50%", transform: "translate(490px, -30px)", zIndex: 1203, display: "flex", alignItems: "center" }}>
        <ReactToPrint trigger={() => <button>Print</button>} content={() => ref.current}/>
        <CloseIcon style={{ cursor: "pointer", width: 30, color: "white" }} onClick={() => setPrint(null)}/>
      </div>
      <div style={{ position: "fixed", width: 1120, height: "80%", top: "10%", left: "50%", transform: "translate(-560px, 0)", backgroundColor: "white", zIndex: 1203, overflowY: "auto" }}>
        <div ref={ref} style={{ padding: 40 }}>{print}</div>
      </div>
    </>
  )
};

export default PrintModal;