import * as d3 from "d3";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const Mesh = (props) => {
    const exp = "1 / (Math.sqrt(2 * Math.PI) * sd) * Math.pow(Math.E, -Math.pow(i - m, 2) / (2 * Math.pow(sd, 2)))";
    const gf = "1 / Math.sqrt(2 * Math.PI) * Math.pow(Math.E, -0.5 * Math.pow(r, 2))"
    const mvnpdf = "Math.pow(Math.E, -1 / (2 * (1 - Math.pow(p, 2))) * (Math.pow((xValue - m1) / sd1, 2) - 2 * p * (xValue - m1) * (yValue - m2) / (sd1 * sd2) + Math.pow((yValue - m2) / sd2, 2))) / (2 * Math.PI * sd1 * sd2 * Math.sqrt(1 - Math.pow(p, 2)))";
    const [nd, setNd] = useState({x: [], y: [], z: []});
    const [user, setUser] = useState({x: 0, y: 0, z: 0});
    const [camera, setCamera] = useState({x: 0, y: 0});
    const [area, setArea] = useState([]);

    function getZ(x, y) {
        let x1 = x, x2 = x, y1 = y, y2 = y;
        if(props.data.x.includes(x) && props.data.y.includes(y)) {
            return props.data.z[props.data.y.indexOf(y)][props.data.x.indexOf(x)];
        }
        if(!props.data.x.includes(x)) {
            for(let i = 0; i < props.data.x.length; i++) {
                if(props.data.x[i] < x) {
                    x1 = props.data.x[i];
                }
                if(props.data.x[i] > x) {
                    x2 = props.data.x[i];
                    break;
                }
            }
        }
        if(!props.data.y.includes(y)) {
            // console.log(y);
            for(let i = 0; i < props.data.y.length; i++) {
                if(props.data.y[i] < y) {
                    y1 = props.data.y[i];
                }
                if(props.data.y[i] > y) {
                    y2 = props.data.y[i];
                    break;
                }
            }
        }
        // if(props.data.y.indexOf(y2) === -1) {
        //     y1 = props.data.y[props.data.y.length - 2];
        //     y2 = props.data.y[props.data.y.length - 1];
        // }
        // console.log(x1, y1, x2, y2);
        // console.log(props.data.z[props.data.y.indexOf(y1)]);
        // console.log(props.data.y.indexOf(y2));
        const z1 = props.data.z[y1 < props.data.y[0] ? 0 : props.data.y.indexOf(y1)][x1 < props.data.x[0] ? 0 : props.data.x.indexOf(x1)];
        const z2 = props.data.z[y2 < props.data.y[0] ? 0 : props.data.y.indexOf(y2)][x2 < props.data.x[0] ? 0 : props.data.x.indexOf(x2)];

        if(x1 === x2) {
            return (z2 - z1) / (y2 - y1) * y + (z1 - (z2 - z1) / (y2 - y1) * y1);
        }
        return (z2 - z1) / (x2 - x1) * x + (z1 - (z2 - z1) / (x2 - x1) * x1);
    }

    // useEffect(() => {
    //     let temp = [d3.min(props.data.x)].concat(props.data.lines.strength).concat([d3.max(props.data.x)]);
    //     let strength = [];
    //     for(let i = 0; i < temp.length - 1; i++) {
    //         strength.push({from: temp[i], to: temp[i + 1]});
    //     }
    //     temp = [d3.min(props.data.y)].concat(props.data.lines.endurance).concat([d3.max(props.data.y)]);
    //     let endurance = [];
    //     for(let i = 0; i < temp.length - 1; i++) {
    //         endurance.push({from: temp[i], to: temp[i + 1]});
    //     }
    //     setArea({strength, endurance});
    // }, []);

    useEffect(() => {
        let x, y;
        const userStrength = Math.min(props.data.userStrength, d3.max(props.data.y));
        setNd({ x: props.data.x, y: props.data.y, z: props.data.z });
        setUser({ x: props.data.userStrengthBW, y: userStrength, z: getZ(props.data.userStrengthBW, userStrength) })
        if(props.data.userStrengthBW >= 44.28477) {
            x = 1;
        }
        else {
            x = -1;
        }
        if(userStrength >= user.area === "forearm" ? 27.7096 / 70 : 0.277096) {
            y = 1;
        }
        else {
            y = -1;
        }
        setCamera({x, y});
        const strengthMin = d3.min(props.data.x), strengthMax = d3.max(props.data.x);
        const enduranceMin = d3.min(props.data.y), enduranceMax = d3.max(props.data.y);
        let plot = [];
        let temp = [strengthMin].concat(props.data.lines.strengthBW).concat([strengthMax]);
        let strengthFrom, strengthTo, enduranceFrom, enduranceTo;
        for(let i = 1; i < temp.length; i++) {
            if(props.data.userStrengthBW <= temp[i]) {
                temp[i - 1] !== strengthMin && plot.push({
                    x: Array(props.data.y.length).fill(temp[i - 1]),
                    y: props.data.y,
                    z: props.data.y.map(d => getZ(temp[i - 1], d)),
                    type: "scatter3d",
                    mode: "lines",
                    marker: {
                        color: "blue",
                        size: 16
                    },
                    name: "strengthFrom",
                    hoverinfo: "none"
                });
                temp[i] !== strengthMax && plot.push({
                    x: Array(props.data.y.length).fill(temp[i]),
                    y: props.data.y,
                    z: props.data.y.map(d => getZ(temp[i], d)),
                    type: "scatter3d",
                    mode: "lines",
                    marker: {
                        color: "blue",
                        size: 16
                    },
                    name: "strengthTo",
                    hoverinfo: "none"
                });
                strengthFrom = temp[i - 1];
                strengthTo = temp[i];
                // i * 25
                break;
            }
        }
        temp = [enduranceMin].concat(props.data.lines.strength).concat([enduranceMax]);
        for(let i = 1; i < temp.length; i++) {
            if(userStrength <= temp[i]) {
                temp[i - 1] !== enduranceMin && plot.push({
                    x: props.data.x,
                    y: Array(props.data.x.length).fill(temp[i - 1]),
                    z: props.data.x.map(d => getZ(d, temp[i - 1])),
                    type: "scatter3d",
                    mode: "lines",
                    marker: {
                        color: "blue",
                        size: 16
                    },
                    name: "enduranceFrom",
                    hoverinfo: "none"
                });
                temp[i] !== enduranceMax && plot.push({
                    x: props.data.x,
                    y: Array(props.data.x.length).fill(temp[i]),
                    z: props.data.x.map(d => getZ(d, temp[i])),
                    type: "scatter3d",
                    mode: "lines",
                    marker: {
                        color: "blue",
                        size: 16
                    },
                    name: "enduranceTo",
                    hoverinfo: "none"
                });
                enduranceFrom = temp[i - 1];
                enduranceTo = temp[i];
                // i * 25
                break;
            }
        }
        let z = [];
        let strengthIndexFrom = null, strengthIndexTo = null;
        for(let i = 0; i < props.data.x.length; i++) {
            if(props.data.x[i] >= strengthFrom && props.data.x[i] <= strengthTo) {
                strengthIndexFrom === null && (strengthIndexFrom = i);
                strengthIndexTo = i;
            }
        }
        x = props.data.x.slice(strengthIndexFrom, strengthIndexTo + 1);
        let enduranceIndexFrom = null, enduranceIndexTo = null;
        for(let i = 0; i < props.data.y.length; i++) {
            if(props.data.y[i] >= enduranceFrom && props.data.y[i] <= enduranceTo) {
                enduranceIndexFrom === null && (enduranceIndexFrom = i);
                enduranceIndexTo = i;
            }
        }
        y = props.data.y.slice(enduranceIndexFrom, enduranceIndexTo + 1);
        console.log(enduranceIndexFrom, enduranceIndexTo);
        for(let i = enduranceIndexFrom; i <= enduranceIndexTo; i++) {
            z.push(props.data.z[i].slice(strengthIndexFrom, strengthIndexTo + 1));
        }
        if(props.data.x[strengthIndexFrom] !== strengthFrom) {
            x.unshift(strengthFrom);
            z.map((e, i) => e.unshift(getZ(strengthFrom, y[i])));
        }
        if(props.data.x[strengthIndexTo] !== strengthTo) {
            x.push(strengthTo);
            z.map((e, i) => e.push(getZ(strengthTo, y[i])));
        }
        if(props.data.y[enduranceIndexFrom] !== enduranceFrom) {
            y.unshift(enduranceFrom);
            z.unshift(x.map(e => getZ(e, enduranceFrom)));
        }
        if(props.data.y[enduranceIndexTo] !== enduranceTo) {
            y.push(enduranceTo);
            z.push(x.map(e => getZ(e, enduranceTo)));
        }
        plot.push({
            type: "surface",
            showscale: false,
            x, y, z,
            opacity: 0.9,
            colorscale: [
                [0, "#ffff0088"],
                [1, "#ffff0088"]
            ],
            hoverinfo: "none"
        })
        
        setArea(plot);
        
        // setUser({ x: xValue, y: yValue, z: eval(mvnpdf) })
        // let i = props.data.userX;
        // setUser({ x: props.data.userX, y: eval(exp) });
        // console.log(x, y, z);
    }, [props.data]);

    return (
        <Plot
            style={{ display: "inline-block" }}
            data={[
                {
                    x: nd.x,
                    y: nd.y,
                    z: nd.z,
                    type: "surface",
                    line: {
                        color: "black"
                    },
                    opacity: 0.9,
                    hoverinfo: "x+y+z",
                    contours: {
                        x: {
                            show: true
                        },
                        y: {
                            show: true
                        }
                    },
                    colorscale: [
                        [0, d3.rgb(127, 159, 255)],
                        // [0.5, rgb(191, 191, 191)],
                        [1, d3.rgb(159, 255, 159)]
                    ]
                    // hidesurface: true
                },
                {
                    x: [user.x],
                    y: [user.y],
                    z: [user.z],
                    type: "scatter3d",
                    mode: "markers",
                    // text: [""],
                    marker: {
                        color: "red",
                        size: 8
                    },
                    name: "user",
                    hoverinfo: "none"
                    // textposition: "center right",
                    
                },
                // {
                //     type: "scatter3d",
                //     mode: "text",
                //     x: [30], y: [3], z: [0],
                //     text: "asdf",
                //     showlegend: false,
                //     inherit: false,
                // }
            ].concat(area)}
            layout={{
                width: 800, height: 750,
                showlegend: false,
                // title: "Muscle Quality",
                annotations: [
                    {
                        x: 1, y: 1,
                        xref: "paper",
                        yref: "paper",
                        // yanchor: "top",
                        showarrow: false,
                        align: "left",
                        text: "MQ: " + parseFloat(user.x.toFixed(1)) + "<br>" +
                            "근력: " + parseFloat(user.y.toFixed(1)) + (user.y >= props.data.userStrength ? "" : " 이상") + "<br>" +
                            "확률밀도함수: " + parseFloat(user.z.toFixed(4)),
                        bordercolor: "black",
                        borderwidth: 1,
                        borderpad: 4,
                        bgcolor: "white",
                        opacity: 0.8,
                    }
                ],
                // margin: {
                //     // t: 10,
                //     // b: 50,
                //     l: 20,
                //     r: 20
                // },
                scene: {
                    xaxis: {
                        title: "MQ(%BW)",
                        dtick: 10,
                        range: [5, 75]
                    },
                    yaxis: {
                        title: "근력(kgf)",
                        dtick: user.area === "forearm" ? 0.1 : 0.05,
                        range: user.area === "forearm" ? [0, 45 / 70] : [0, 0.45]
                    },
                    zaxis: {
                        title: "확률밀도함수",
                        range: [0, 0.005]
                    },
                    
                    camera: {
                        eye: {
                            x: camera.x,
                            y: camera.y
                        },
                        center: {
                            z: -0.1
                        }
                    },
                    aspectratio: {
                        x: 0.8,
                        y: 0.8,
                        z: 0.8
                    },
                    dragmode: false
                }
                
                // xaxis: {30.81554, 3.757901
                //     title: "Muscle Strength",
                //     // range: [props.range.min, props.range.max],
                //     // fixedrange: true,
                //     // dtick: props.range.tick,
                //     // automargin: true
                // },
                // yaxis: {
                //     title: "Muscle Endurance",
                //     // // range: [0, 0.025],
                //     // fixedrange: true,
                //     // // scaleanchor: "x",
                //     // // scaleratio: 1,
                //     // // dtick: props.range[2],
                //     // automargin: true
                // },
                // zaxis: {
                //     title: "PDF",
                // }
                // shapes: [
                //     {
                //         type: "line",
                //         x0: props.range[0], x1: props.range[1], y0: props.range[0], y1: props.range[1],
                //         line: {
                //             color: "black",
                //             width: 1,
                //             dash: "dash"
                //         },
                //         layer: "below"
                //     },
                //     {
                //         type: "circle",
                //         xref: "x",
                //         yref: "y",
                //         x0: props.data.userDot[0] - props.offset,
                //         x1: props.data.userDot[0] + props.offset,
                //         y0: props.data.userDot[1] - props.offset,
                //         y1: props.data.userDot[1] + props.offset,
                //         opacity: 0.2,
                //         fillcolor: "blue",
                //         line: {
                //             width: 0
                //         }
                //     }
                // ]
            }}
            config={{ responsive: true }}
        />
    );
};

export default Mesh;