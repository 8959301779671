import React, { useEffect, useState } from "react";
import { AuthPiece, SignIn } from "aws-amplify-react";
import { CardTitle, CardImg, Button } from "reactstrap";
import {
  Form,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Alert,
  Container,
  Row,
  Col
} from "reactstrap";

import MidCard from "../component/MidCard";
import Spinner from "../uicomponent/Spinner";
import TopSpacer from "./../component/TopSpacer";
import { Redirect } from "react-router";
import restapi from "../util/restapi";
import { Auth } from "aws-amplify";

export default class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    super.errorMessage = this.handleError;
  }

  state = {
    loading: false,
    error: null,
    expand: ""
  };

  mounted = false;

  componentDidMount = () => {
    this.mounted = true;
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  v1SignIn = async e => {
    this.setState({ loading: true, error: null });
    this.props.setState({ signInLoading: true });
    await super.signIn(e);
    const v1User = await Auth.currentAuthenticatedUser();
    v1User && (window.location = "/v1");
    this.setState({ loading: false });
    this.props.setState({ signInLoading: false });
  };


  static getDerivedStateFromProps(props, state) {
    if(state.expand !== props.expand){
      return {
        expand: props.expand
      }
    }
    return null;
  }

  handleEnter = event => {
    event.key === "Enter" && this.state.expand === "exoRehab" && this.v1SignIn(event);
  };

  handleError = error => {
    this.setState({ error });
  };

  changeState = state => {
    this.mounted && this.setState({ error: null }, () => {
      super.changeState(state);
    });
  };

  checkUser = () => {
    // if(this.props.user) {
    //   window.location = "/v1";
    // }
  }

  showComponent(theme) {
    const { loading } = this.state;
    const size = { size: 4, offset: 4 };

    return (
        <div>
          {/* <img width="100%" src="/resource/exorehab_logo.png" onClick={() => {this.checkUser(); this.setState({ expand: "exoRehab" }); this.props.setState({ expand: "exoRehab" }); this.props.slide && this.props.slide("exoRehab");}}
            className={["img", this.state.expand !== "exoRehab" && "border"].join(" ")}/> */}
          <div style={{ height: 10 }} />
          <Form className={["form", this.state.expand !== "exoRehab" && "form-hidden"].join(" ")}>
            <InputGroup>
              <InputGroupAddon  addonType="prepend">
                <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Email</InputGroupText>
              </InputGroupAddon>
              <Input
                disabled={loading}
                name="username"
                onChange={this.handleInputChange}
                onKeyPress={this.handleEnter}
                type="text"
                placeholder="email@example.com"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Password</InputGroupText>
              </InputGroupAddon>
              <Input
                disabled={loading}
                name="password"
                onChange={this.handleInputChange}
                onKeyPress={this.handleEnter}
                type="password"
                placeholder="your password"
              />
            </InputGroup>
            {this.state.error && (
              <Alert color="warning" style={{ color: "red" }}>
                The information you entered is invalid
              </Alert>
            )}
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div>
                <Button
                  color="link"
                  onClick={() => this.changeState("forgotPassword")}
                >
                  Reset Password
                </Button>
                <Button color="link" onClick={() => this.changeState("signUp")}>
                  Create account
                </Button>
              </div>
              <div>
                <Button disabled={loading} onClick={this.v1SignIn}>
                  Login
                  <Spinner visible={this.state.loading} />
                </Button>
              </div>
            </div>
          </Form>
          {/* <div className={["exoFit", this.state.expand !== "" && (this.state.expand === "exoFit" ? "exoFit-expand" : "exoFit-fold")].join(" ")}>
            <img width="100%" src="/resource/exorehab_logo.png" onClick={() => this.setState({ expand: "exoFit" })}
              className={["img", this.state.expand !== "exoFit" && "border"].join(" ")}/>
            <div style={{ height: 10 }} />
            <Form className={["form", this.state.expand !== "exoFit" && "form-hidden"].join(" ")}>
              <InputGroup>
                <InputGroupAddon  addonType="prepend">
                  <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Phone</InputGroupText>
                </InputGroupAddon>
                <Input
                  id="phone"
                  disabled={loading}
                  name="phoneNum"
                  onKeyPress={this.handleEnter}
                  type="text"
                  placeholder="01000000000"
                />
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={{ width: "82px", justifyContent: "center" }}>Password</InputGroupText>
                </InputGroupAddon>
                <Input
                  id="v2Password"
                  disabled={loading}
                  name="password"
                  onKeyPress={this.handleEnter}
                  type="password"
                  placeholder="your password"
                />
              </InputGroup>
              <div style={{ textAlign: "right" }}>
                  <Button disabled={loading} onClick={this.v1SignIn}>
                    Login
                    <Spinner visible={this.state.loading} />
                  </Button>
                </div>
            </Form>
          </div> */}
        </div>
    );
  }
}
